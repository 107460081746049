import React, { useState, useRef, useEffect } from "react";
import Notification from "../Notification/Notification";
import essService from "../../services/ess.service";
import Spinner from "react-bootstrap/Spinner";
import licenceValidator from "../../permission/validate";
import GradientBorderButton from "../Widgets/GradientBorderButton";

function SkillHighlighter() {
  const [error, setError] = useState();
  const cvRef = useRef();
  const skillRef = useRef();
  const [keywords, setKeywords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [convertedHtml, setConvertedHtml] = useState("");
  const [searchCount, setSearchCount] = useState(0);
  const [currentMatchIndex, setCurrentMatchIndex] = useState(0);
  const [occurenceCounter, setOccurenceCounter] = useState({});
  const scrollRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const scrollToMatch = () => {
    if (scrollRef.current) {
      const highlightedElements =
        scrollRef.current.getElementsByClassName("highlighted");
      if (highlightedElements.length > 0) {
        highlightedElements[currentMatchIndex].scrollIntoView({
          behavior: "smooth",
        });
      }
    }
  };

  useEffect(() => {
    scrollToMatch();
  }, [currentMatchIndex]);

  const goToNextMatch = () => {
    setCurrentMatchIndex((prevIndex) => (prevIndex + 1) % searchCount);
  };

  const goToPreviousMatch = () => {
    if (searchCount > 0)
      setCurrentMatchIndex(
        (prevIndex) => (prevIndex - 1 + searchCount) % searchCount
      );
  };

  const clearHighlights = (html) => {
    setKeywords([]);
    setOccurenceCounter({});
    skillRef.current.value = "";
    const parser = new DOMParser();
    const parsedHTML = parser.parseFromString(html, "text/html");

    const highlightedSpans = parsedHTML.querySelectorAll("span.highlighted");
    highlightedSpans.forEach((span, index) => {
      span.style.backgroundColor = "#fff"; // Change the background color
      span.className = `highlighted-${index}`;
    });
    // Convert the modified HTML back to a string
    const modifiedHTML = parsedHTML.documentElement.innerHTML;
    setConvertedHtml(modifiedHTML);
  };

  const highlightWords = async (cleanedHtml) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
    let occurences = {};
    let maxResults = 0;
    // const cleanedHtml = clearHighlights(html);
    setCurrentMatchIndex(0);
    if (keywords.length > 0) {
      await keywords.forEach((keyword) => {
        if (keyword.trim().length > 0) {
          let keywordIndex = keywords.indexOf(keyword);
          let color = lightColorShades[keywordIndex % lightColorShades.length];
          const regex = new RegExp(`\\b${keyword.trim()}\\b`, "gi");
          let matches = cleanedHtml.match(regex);
          if (matches) {
            maxResults += matches.length;
            occurences = { ...occurences, [keyword.trim()]: matches.length };
          } else {
            occurences = { ...occurences, [keyword.trim()]: 0 };
          }
          cleanedHtml = cleanedHtml.replace(
            regex,
            `<span class="highlighted" style="background: ${color}"}>${keyword.trim()}</span>`
          );
        }
      });
    }
    setSearchCount(maxResults);
    setOccurenceCounter(occurences);
    setConvertedHtml(cleanedHtml);
  };
  useEffect(() => {
    let user = localStorage.getItem("user");
    let validator = new licenceValidator(user, "assist");
    setIsVisible(validator.isReadable("skill_highlighter"));
  }, []);
  const handleSearchChange = () => {
    if (convertedHtml.length > 1) highlightWords(convertedHtml);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("cv", cvRef.current.files[0]);
      formData.append("skillsArray", skillRef.current.value.split(","));
      const result = await essService.skillHighlight(formData);
      highlightWords(result);
      // setIsResume(true);
    } catch (error) {
      // console.log(error);
    }
    setLoading(false);
  };

  const lightColorShades = [
    "#FBC531",
    "#9B59B6",
    "#FF6B6B",
    "#2ECC71",
    "#FFA801",
    "#00B894",
    "#FFC312",
    "#FF4D4D",
    "#8E44AD",
    "#FF9F43",
    "#54a0ff",
    "#CDDC39",
    "#FF85A1",
    "#FF3838",
    "#FFB8B8",
  ];
  return (
    <div className="relevent-candidate p-4">
      <Notification error={error} />
      <form id="form" encType="multipart/form-data">
        <div className="row">
          <div className="col">
            <div className="form-outline">
              <label className="form-label" htmlFor="customFile">
                Upload job description / Resume
              </label>
              <input
                ref={cvRef}
                accept=".pdf"
                type="file"
                className="form-control"
                id="file"
                required
              />
            </div>
          </div>
        </div>
        <div className="row pt-1">
          <div className="col">
            <div className="form-outline">
              <label className="form-label pt-3" htmlFor="form8Example1">
                Enter skills (comma separated)
              </label>
              <div className="input-group mb-3">
                <input
                  type="text"
                  ref={skillRef}
                  className="form-control"
                  onChange={() =>
                    setKeywords(skillRef.current.value.split(","))
                  }
                  placeholder="java,python..."
                />
                {convertedHtml.length > 1 ? (
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    id="button-addon2"
                    onClick={handleSearchChange}
                  >
                    search
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {isVisible ? (
            <div className="d-flex justify-content-center w-100 mt-3">
            <GradientBorderButton clickHandler={handleSubmit} text={"Submit"} />
            </div>
        ) : (
          <></>
        )}
      </form>
      {convertedHtml.length > 1 ? (
        <div className="d-flex gap-2 flex-column">
          <div className="d-flex justify-content-between">
            <div className="counts">
              {Object.entries(occurenceCounter).map(([key, value]) => (
                <p className="me-2" key={key}>
                  {key}: {value}
                </p>
              ))}
            </div>

            <div
              className="clear-btn"
              onClick={() => clearHighlights(convertedHtml)}
              role="button"
            >
              clear
            </div>
          </div>
        </div>
      ) : null}
      {loading ? (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      ) : convertedHtml.length > 1 ? (
        <div
          className="viewer mt-2"
          id="skill-highlighter-view"
          ref={scrollRef}
          style={{
            overflow: "auto",
            maxHeight: "70vh",
            boxShadow: "1px 1px 9px -3px gray",
            borderRadius: "10px",
          }}
          dangerouslySetInnerHTML={{
            __html: convertedHtml.length > 1 ? convertedHtml : "<p><p/>",
          }}
        ></div>
      ) : null}
    </div>
  );
}

export default SkillHighlighter;
