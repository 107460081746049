import React, { useEffect, useState, useRef } from "react";
import Notification from "../../components/Notification/Notification";
import { Link, useNavigate } from "react-router-dom";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import PhotoSharingMethods from "../../services/photoSharing.service";
import { FiUpload } from "react-icons/fi";
import { CgAdd } from "react-icons/cg";
import { GrFormClose } from "react-icons/gr";
import image from "../../assets/new.png";
import GradientBorderButton from "../../components/Widgets/GradientBorderButton";
import { setMemories, addMemories } from "../../features/photoSharingSlice";
import { useSelector, useDispatch } from "react-redux";
import Papa from "papaparse";
import ImageUtil from "../../utilities/ImageUtil";
import { useDOMStatesContext } from "../../context/DOMStatesContext";
import Popup from "../../components/Popup/Popup";
import {
  AiFillCheckCircle,
  AiFillCloseCircle,
  AiFillHeart,
  AiOutlineDelete,
} from "react-icons/ai";
import { IoMdTrash } from "react-icons/io";
import { ImDownload3 } from "react-icons/im";
import photoSharingService from "../../services/photoSharing.service";
import { AiOutlineClose } from "react-icons/ai";
import { MdPhotoLibrary } from "react-icons/md";
import licenceValidator from "../../permission/validate";
import Spinner from "react-bootstrap/Spinner";
import { FaGreaterThan, FaLessThan, FaSearch } from "react-icons/fa";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import imageCompression from "browser-image-compression";
import { BsPencil } from "react-icons/bs";
import { BiDownload, BiLoaderCircle } from "react-icons/bi";
import minioService from "../../services/minio.service";
import { Splide, SplideTrack, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import { IoClose } from "react-icons/io5";

const PhotoSharing = () => {
  const memories = useSelector((state) => state.photoSharing.memories);
  const user = useSelector((state) => state.user.user);
  const [showPopup, setShowPopup] = useState(false);

  const [selectedRenameFolder, setSelectedRenameFolder] = useState();
  const showDeleteButton = window.location.pathname !== "/memories";
  const [hideRightArrow, sethideRideArrow] = useState(false);
  const [hideLeftArrow, sethideLeftArrow] = useState(true);

  const [loading, setLoading] = useState(false);
  const [uploaded, setuploaded] = useState(false);
  const navigate = useNavigate();
  const {
    toggleMemoryImage,
    showMemoryImage,
    toggleMemoryUploadForm,
    toggleMemoryAlbumForm,
    showMemoryUploadForm,
    showMemoryAlbumForm,
  } = useDOMStatesContext();
  const searchTxtRef = useRef();
  const inputRef = useRef();
  const [selectedImage, setSelectedImage] = useState("");
  const [isDeletable, setDeletable] = useState(false);
  const [imageFile, setImageFile] = useState();
  const [albumName, setAlbumName] = useState("");
  const [likeCount, setLikeCount] = useState(0);
  const [isLiked, setIsLiked] = useState(false);
  const [breadCrumbList, setBreadCrumbList] = useState(["/memories"]);
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const [successNotific, setSuccessNotific] = useState("");
  const [errorNotific, setErrorNotific] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [buttonPosition, setButtonPosition] = useState({ top: 0, left: 0 });

  let [imageArray, setImageArray] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedFolder, setSelectedFolder] = useState("");
  const [folderId, setFolderId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [pageNumbers, setPageNumbers] = useState([]);
  const visiblePages = 5;
  const [filteredImageList, setFilteredImageList] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  let folderNameRef = useRef();
  const [loadingNextPage, setLoadingNextPage] = useState(false);
  const [showRenamePopup, setShowRenamePopup] = useState(false);
  const [showCounter, setShowCounter] = useState(false);
  const [userUploadCounter, setUserUploadCounter] = useState(0);
  const [showUploadCounter, setShowUploadCounter] = useState(false);
  const [uploadedSuccessCounter, setUploadedSuccessCounter] = useState(0);
  const [uploadedFailedCounter, setUploadedFailedCounter] = useState(0);
  const [memoryImages, setMemoryImages] = useState([]);
  let splideInstance;
  useEffect(() => {
    let user = localStorage.getItem("user");
    let validator = new licenceValidator(user, "memories");
    setIsVisible(validator.isReadable("photo_upload"));
    setDeletable(validator.isDelete("photo_upload"));
  }, []);

  const getAllMemories = async () => {
    const result = await PhotoSharingMethods.getAllEvents(
      window.location.pathname.replace("/memories", ""),
      1
    );

    if (result.totalResults < 20) {
      setPageNumbers([1]);
      let tempTotalPages = Math.max(1, Math.ceil(result.totalResults / 20));
      setTotalPages(tempTotalPages);
    } else {
      let tempTotalPages = Math.max(1, Math.ceil(result.totalResults / 20));
      setTotalPages(tempTotalPages);
      if (tempTotalPages <= 1) {
        sethideRideArrow(true);
      } else {
        sethideRideArrow(false);
      }
      if (tempTotalPages > 5) {
        tempTotalPages = 5;
      }
      let tempPageArr = [];
      for (let i = 1; i <= tempTotalPages; i++) {
        tempPageArr.push(i);
      }
      setPageNumbers(tempPageArr);
    }
    setTotalResults(result.totalResults);

    setCurrentPage(1);
    sethideLeftArrow(true);

    dispatch(
      setMemories({ key: window.location.pathname, result: result.results })
    );
    const arr = result.results.filter(
      (item) => item.type === "file" && item.status
    );
    setMemoryImages(arr);
  };

  const getLastPage = async (noOfImages) => {
    let pageNo = Math.ceil((noOfImages + totalResults) / 20);

    const result = await PhotoSharingMethods.getAllEvents(
      window.location.pathname.replace("/memories", ""),
      pageNo,
      searchTxtRef?.current?.value
    );

    let tempTotalPages = Math.max(1, Math.ceil(result.totalResults / 20));
    setTotalResults(result.totalResults);
    setTotalPages(tempTotalPages);
    if (tempTotalPages <= 1) {
      sethideRideArrow(true);
    } else {
      sethideLeftArrow(false);
      sethideRideArrow(false);
    }
    setCurrentPage(tempTotalPages);
    let tempStart = 1;
    let tempEnd = Math.min(5, tempTotalPages);
    if (tempTotalPages > 3) {
      tempStart = tempTotalPages - 3;
      tempEnd = tempTotalPages;
      setStartPage(tempStart);
      setEndPage(tempEnd);
    }
    let tempPageNumbers = Array.from(
      { length: tempEnd - tempStart + 1 },
      (_, index) => tempStart + index
    );
    if (tempPageNumbers[0] !== 0) {
      setPageNumbers(tempPageNumbers);
    }

    dispatch(
      setMemories({ key: window.location.pathname, result: result.results })
    );
    const arr = result.results.filter(
      (item) => item.type === "file" && item.status
    );
    setMemoryImages(arr);
  };
  const getCurrentPage = async () => {
    const result = await PhotoSharingMethods.getAllEvents(
      window.location.pathname.replace("/memories", ""),
      currentPage,
      searchTxtRef?.current?.value
    );
    setTotalResults(result.totalResults);
    dispatch(
      setMemories({ key: window.location.pathname, result: result.results })
    );
    const arr = result.results.filter(
      (item) => item.type === "file" && item.status
    );
    setMemoryImages(arr);
  };

  const filterAlbums = () => {
    setLoading(true); // Set loading state to true when filtering starts
    setTimeout(async () => {
      // Your existing logic here
      if (!memories) {
        setLoading(false); // Set loading state to false when filtering finishes
        return;
      }

      try {
        const result = await PhotoSharingMethods.getAllEvents(
          window.location.pathname.replace("/memories", ""),
          1,
          searchTxtRef?.current?.value
        );

        if (searchTxtRef?.current?.value) {
          // Update state based on result
          if (result.totalResults < 20) {
            setPageNumbers([1]);
            let tempTotalPages = Math.max(
              1,
              Math.ceil(result.totalResults / 20)
            );
            setTotalPages(tempTotalPages);
          } else {
            let tempTotalPages = Math.max(
              1,
              Math.ceil(result.totalResults / 20)
            );
            setTotalResults(result.totalResults);
            setTotalPages(tempTotalPages);
            if (tempTotalPages <= 1) {
              sethideRideArrow(true);
            } else {
              sethideRideArrow(false);
            }
            if (tempTotalPages > 5) {
              tempTotalPages = 5;
            }
            let tempPageArr = [];
            for (let i = 1; i <= tempTotalPages; i++) {
              tempPageArr.push(i);
            }
            setPageNumbers(tempPageArr);
          }
          setCurrentPage(1);
          sethideLeftArrow(true);

          dispatch(
            setMemories({
              key: window.location.pathname,
              result: result.results,
            })
          );
          const arr = result.results.filter(
            (item) => item.type === "file" && item.status
          );
          setMemoryImages(arr);
        } else {
          await getAllMemories();
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false); // Set loading state to false after processing
      }
    }, 300); // Adjust timeout duration as needed
  };

  useEffect(() => {
    const resultArray = window.location.pathname
      .split(/(\/[^/]+)/)
      .filter(Boolean);
    setBreadCrumbList(resultArray);
    setSelectedFolder(resultArray[resultArray.length - 1]);
    if (resultArray.length > 1) {
      async function setFolder() {
        const result = await PhotoSharingMethods.getAllEvents(
          resultArray[resultArray.length - 2].replace("/memories", ""),
          0
        );
        const result1 = await PhotoSharingMethods.getAllEvents(
          resultArray[resultArray.length - 2].replace("/memories", ""),
          result.totalPages
        );

        const folder = result1.results?.filter(
          (item) => item.name === resultArray[resultArray.length - 1].slice(1)
        );
        if (folder) {
          setFolderId(folder[0].id);
        } else {
          console.log("Folder not found");
        }
      }
      setFolder();
    }
    async function getMemories() {
      await getAllMemories();
    }
    getMemories();
  }, [window.location.pathname]);
  const getSubFoldersMemories = async (folderName) => {
    setSelectedFolder(folderName);

    const extractedFolderName = folderName.slice(1);

    const folder = memories[window.location.pathname]?.filter(
      (item) => item.name === extractedFolderName
    );

    if (folder) {
      setFolderId(folder[0].id);
    } else {
      console.log("Folder not found");
    }
    if (searchTxtRef.current) searchTxtRef.current.value = "";
    if (folderName !== "") {
      setBreadCrumbList((prev) => {
        return [...prev, folderName];
      });
    }
    const result = await PhotoSharingMethods.getAllEvents(
      window.location.pathname.replace("/memories", "") + folderName,
      1
    );
    setTotalResults(result.totalResults);

    let tempTotalPages = Math.max(1, Math.ceil(result.totalResults / 20));
    let tempPageArr = [];
    for (let i = 1; i <= tempTotalPages; i++) {
      tempPageArr.push(i);
    }
    setPageNumbers(tempPageArr);
    dispatch(
      addMemories({ key: window.location.pathname, result: result.results })
    );
    const arr = result.results.filter(
      (item) => item.type === "file" && item.status
    );
    setMemoryImages(arr);
  };

  const paginate = async (currPage) => {
    const result = await PhotoSharingMethods.getAllEvents(
      window.location.pathname.replace("/memories", ""),
      currPage,
      searchTxtRef?.current?.value
    );
    setLoadingNextPage(true);
    setTimeout(() => {
      setLoadingNextPage(false);
    }, 200);
    dispatch(
      addMemories({ key: window.location.pathname, result: result.results })
    );
    const arr = result.results.filter(
      (item) => item.type === "file" && item.status
    );
    setMemoryImages(arr);
    setCurrentPage(currPage);
    setTotalResults(result.totalResults);

    if (currPage == totalPages) {
      sethideRideArrow(true);
    } else {
      sethideRideArrow(false);
    }
    if (currPage == 1) {
      sethideLeftArrow(true);
    } else {
      sethideLeftArrow(false);
    }
  };
  useEffect(() => {
    let newMemories = memories[window.location.pathname];

    if (newMemories) {
      newMemories = newMemories.filter((memory) => memory.type == "file");

      if (newMemories.length) {
        setFilteredImageList(newMemories);
      } else {
        setFilteredImageList([]);
      }
    }
  }, [memories]);

  const adjustPages = () => {
    let tempStart = 1;
    let tempEnd = Math.min(5, totalPages);
    if (currentPage > 3) {
      tempStart = Math.max(1, currentPage - Math.floor(visiblePages / 2));
      tempEnd = Math.min(tempStart + visiblePages - 1, totalPages);
      setStartPage(tempStart);
      setEndPage(tempEnd);
    }
    let tempPageNumbers = Array.from(
      { length: tempEnd - tempStart + 1 },
      (_, index) => tempStart + index
    );
    if (tempPageNumbers[0] !== 0) {
      setPageNumbers(tempPageNumbers);
    }
  };
  useEffect(() => {
    adjustPages();
  }, [currentPage]);
  const handlePrevPage = async () => {
    if (currentPage - 1 == totalPages) {
      sethideRideArrow(true);
    } else {
      sethideRideArrow(false);
    }
    if (currentPage - 1 == 1) {
      sethideLeftArrow(true);
    } else {
      sethideLeftArrow(false);
    }
    setLoadingNextPage(true);
    setTimeout(() => {
      setLoadingNextPage(false);
    }, 200);
    if (currentPage > pageNumbers[0]) {
      const result = await PhotoSharingMethods.getAllEvents(
        window.location.pathname.replace("/memories", ""),
        currentPage - 1,
        searchTxtRef?.current?.value
      );

      dispatch(
        addMemories({ key: window.location.pathname, result: result.results })
      );
      const arr = result.results.filter(
        (item) => item.type === "file" && item.status
      );
      setMemoryImages(arr);
      setCurrentPage(currentPage - 1);
    } else if (currentPage > 1) {
      let tempPageArr = [];

      for (let page = pageNumbers[0] - 5; page <= pageNumbers[0] - 1; page++) {
        tempPageArr.push(page);
      }
      const result = await PhotoSharingMethods.getAllEvents(
        window.location.pathname.replace("/memories", ""),
        tempPageArr[tempPageArr.length - 1],
        searchTxtRef?.current?.value
      );

      dispatch(
        addMemories({ key: window.location.pathname, result: result.results })
      );
      const arr = result.results.filter(
        (item) => item.type === "file" && item.status
      );
      setMemoryImages(arr);
      setCurrentPage(currentPage - 1);
      setPageNumbers(tempPageArr);
    }
  };
  const handleRenameFolder = async (e) => {
    if (folderNameRef.current.value) {
      const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
      };
      let newName = folderNameRef.current.value
        .split(/[\s_]+/)
        .map(capitalizeFirstLetter)
        .join("_");
      let data = selectedRenameFolder;
      try {
        let result = await PhotoSharingMethods.renameAlbum({
          newName,
          id: data.id,
        });
        setErrorNotific("");
        setSuccessNotific("Folder rename successfully.\n Name: " + newName);

        setShowAlert(true);
        setTimeout(() => {
          setShowRenamePopup(false);
          setSelectedRenameFolder();
          setShowAlert(false);
        }, 1000);
        await getCurrentPage();
      } catch (err) {
        setSuccessNotific("");
        setErrorNotific("Album with this name already exist");

        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 2000);
      }
    } else {
      setErrorNotific("Folder name is empty!");
      setSuccessNotific("");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 2000);
    }
  };

  const handleNextPage = async () => {
    setLoadingNextPage(true);
    setTimeout(() => {
      setLoadingNextPage(false);
    }, 2000);
    if (currentPage + 1 == totalPages) {
      sethideRideArrow(true);
    } else {
      sethideRideArrow(false);
    }
    if (currentPage + 1 == 1) {
      sethideLeftArrow(true);
    } else {
      sethideLeftArrow(false);
    }
    if (currentPage < pageNumbers[pageNumbers.length - 1]) {
      const result = await PhotoSharingMethods.getAllEvents(
        window.location.pathname.replace("/memories", ""),
        currentPage + 1,
        searchTxtRef?.current?.value
      );

      dispatch(
        addMemories({ key: window.location.pathname, result: result.results })
      );
      const arr = result.results.filter(
        (item) => item.type === "file" && item.status
      );
      setMemoryImages(arr);
      setCurrentPage(currentPage + 1);
    } else if (totalPages > pageNumbers[4]) {
      let tempPageArr = [];
      const endPage = Math.min(totalPages, pageNumbers[4] + 4);
      for (let page = pageNumbers[4] + 1; page <= endPage; page++) {
        tempPageArr.push(page);
      }
      const result = await PhotoSharingMethods.getAllEvents(
        window.location.pathname.replace("/memories", ""),
        tempPageArr[0],
        searchTxtRef?.current?.value
      );

      dispatch(
        addMemories({ key: window.location.pathname, result: result.results })
      );
      const arr = result.results.filter(
        (item) => item.type === "file" && item.status
      );
      setMemoryImages(arr);
      setCurrentPage(currentPage + 1);
      setPageNumbers(tempPageArr);
    }
  };

  const handleBreadCrumbClick = (breadCrumb) => {
    // delete all the breadCrumbs present after the given args.
    let updatedBreadCrumbList = [];
    for (let i = 0; i < breadCrumbList.length; i++) {
      if (breadCrumb !== breadCrumbList[i])
        updatedBreadCrumbList.push(breadCrumbList[i]);
      else {
        updatedBreadCrumbList.push(breadCrumbList[i]);
        break;
      }
    }

    navigate(updatedBreadCrumbList.join(""));
    setBreadCrumbList(updatedBreadCrumbList);

    // redirect user to given breadCrumb location.
  };

  const handleNextImage = () => {
    if (memoryImages && currentIndex < memoryImages.length - 1) {
      const nextIndex = currentIndex + 1;
      setCurrentIndex(nextIndex);
      const nextImage = memoryImages[nextIndex];
      setSelectedImage({
        ...nextImage,
        imgUrl: nextImage.filePath,
      });
      if (nextImage.likeBy.includes(user.id)) {
        setIsLiked(true);
      } else {
        setIsLiked(false);
      }
      setLikeCount(nextImage.likeBy.length);
    }
  };

  const handlePreviousImage = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
      const currentImage = memoryImages[currentIndex - 1];
      setSelectedImage({
        ...currentImage,
        imgUrl: currentImage.filePath,
      });
      if (currentImage.likeBy.includes(user.id)) {
        setIsLiked(true);
      } else {
        setIsLiked(false);
      }
      setLikeCount(currentImage.likeBy.length);
    }
  };
  const handleImagePopup = (imgData, index) => {
    const imgUrl = imgData.filePath;

    setSelectedImage({ ...imgData, imgUrl });
    if (imgData.likeBy.includes(user.id)) {
      setIsLiked(true);
    } else {
      setIsLiked(false);
    }
    setLikeCount(imgData.likeBy.length);
    const currentIndex = (currentPage - 1) * 20 + index;

    setCurrentIndex(memories[window.location.pathname].indexOf(imgData));
    toggleMemoryImage();
  };

  useEffect(() => {
    if (
      memories[window.location.pathname] &&
      memories[window.location.pathname].length > 0
    ) {
      const currentImage = memories[window.location.pathname][currentIndex];

      if (selectedImage) {
      }
    }
  }, [currentIndex, memories]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "ArrowLeft") {
        handlePreviousImage();
      } else if (e.key === "ArrowRight") {
        handleNextImage();
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  });
  const handleUploadReportDownload = () => {
    const tempUsers = imageArray;
    tempUsers.map((item) => (item.license = "object"));
    const jsonUsers = JSON.stringify(tempUsers);
    console.log(tempUsers);
    const csv = Papa.unparse(jsonUsers);
    const blob = new Blob([csv], { type: "text/csv" });

    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "usersUploadStatusReport.csv";

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };
  const handleUpload = async () => {
    setUserUploadCounter(0);

    toggleMemoryUploadForm();

    if (imageFile.length > 400) {
      setErrorNotific("Images should be less than 400!");
      setSuccessNotific("");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 2000);
      setLoading(false);
      return;
    }

    const options = {
      maxSizeMB: 1,
    };
    let images = [];
    for (let image of imageFile) {
      images.push({ name: image.name });
    }

    setImageArray(images);

    setShowCounter(true);
    setShowPopup(true);
    setShowUploadCounter(true);
    for (let image of imageFile) {
      try {
        const formData = new FormData();

        // formData.append(`mems`, image);
        let name = image.name.replace(/s+|\(|\)/gi, "_");

        const compressedFile = await imageCompression(image, options);
        // formData.append(`thumbnail`, compressedFile);
        const result_ = await minioService.UploadFile(
          compressedFile,
          image,
          `memories${window.location.pathname
            .replace("/memories", "")
            .toLowerCase()}`
        );
        console.log(compressedFile.size / 1024 / 1024);
        formData.append(
          "path",
          window.location.pathname.replace("/memories", "") + "/"
        );
        formData.append("name", name);
        formData.append("folderName", `memories`);
        formData.append(
          "thumbnailfilePath",
          `http://bucket.akinolabs.com:443/${
            process.env.REACT_APP_BUCKET_NAME
          }/memories${window.location.pathname
            .replace("/memories", "")
            .toLowerCase()}/thumbnails/thumbnail-` +
            compressedFile.name.replace(/(\(|\))/gi, "_").replace(/ /g, "_")
        );
        formData.append(
          "filePath",
          `http://bucket.akinolabs.com:443/${
            process.env.REACT_APP_BUCKET_NAME
          }/memories${window.location.pathname
            .replace("/memories", "")
            .toLowerCase()}/` +
            image.name.replace(/(\(|\))/gi, "_").replace(/ /g, "_")
        );
        console.log(
          `http://bucket.akinolabs.com:443/${
            process.env.REACT_APP_BUCKET_NAME
          }/memories${window.location.pathname
            .replace("/memories", "")
            .toLowerCase()}/` +
            image.name.replace(/(\(|\))/gi, "_").replace(/ /g, "_")
        );

        const result = await PhotoSharingMethods.uploadImage(formData);
        image.isUploaded = true;
        setUserUploadCounter((prev) => {
          return prev + 1;
        });
        setImageArray((previousArray, imageFile) => {
          // Assuming 'imageFile' is an array of image objects
          previousArray.forEach((item, i) => {
            let image1 = previousArray.find(
              (element) => element.name === image.name
            );
            if (image1) {
              image1.status = "Success";
            }
          });

          return previousArray; // Return the updated array
        });
        setUploadedSuccessCounter((prev) => {
          return prev + 1;
        });
        console.log(result);

        setSuccessNotific("Image Uploaded Successfully!");
        setErrorNotific("");
      } catch (error) {
        setImageArray((previousArray, imageFile) => {
          // Assuming 'imageFile' is an array of image objects
          previousArray.forEach((item, i) => {
            let image1 = previousArray.find(
              (element) => element.name === image.name
            );
            if (image1) {
              image1.status = "Failed";
            }
          });

          return previousArray; // Return the updated array
        });
        setUserUploadCounter((prev) => {
          return prev + 1;
        });
        console.log("Error uploading image:", error);
        setUploadedFailedCounter((prev) => {
          return prev + 1;
        });
        image.isUploaded = false;
        setErrorNotific("Error uploading image!");
        setSuccessNotific("");
        continue;
      }
    }
    let successImageslength = 0;

    images.forEach((image) => {
      if (image.status == "Success") {
        successImageslength++;
      }
    });

    await getLastPage(successImageslength);

    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
      setErrorNotific("");
      setSuccessNotific("");
    }, [2000]);
    const fileInput = document.getElementById("customFile");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleImageDownload = async () => {
    const response = await fetch(selectedImage.imgUrl);
    response.arrayBuffer().then(function (buffer) {
      const url = window.URL.createObjectURL(new Blob([buffer]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "download.jpg"); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  const handleLike = async () => {
    if (isLiked) {
      if (likeCount > 0) setLikeCount(likeCount - 1);
      setIsLiked(false);
    } else {
      setLikeCount(likeCount + 1);
      setIsLiked(true);
    }
    const formData = new FormData();

    formData.append("memoryId", selectedImage.id);
    formData.append("userId", user.id);
    const result = await photoSharingService.manageLike(formData);
    setSelectedImage({
      ...result,
      imgUrl: result.filePath,
    });

    await getAllMemories();
  };

  const handleMemoryDelete = async () => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this memory?"
    );

    if (confirmed) {
      toggleMemoryImage();

      const result = await photoSharingService.deleteMemory(selectedImage.id);
      const updatedMemories = memories[window.location.pathname].filter(
        (item) => item.id != selectedImage.id
      );

      await getLastPage(-1);
    }
  };

  const handleDeleteAlbum = async () => {
    const confirmation = window.confirm(
      "Are you sure you want to delete the album?"
    );

    if (confirmation) {
      const result1 = await PhotoSharingMethods.getAllEvents1(
        window.location.pathname.replace("/memories", "")
      );
      try {
        const result = await photoSharingService.deleteMemory(folderId);
        for (let item of result1.results) {
          let formData1 = new FormData();
          formData1.append("memoryId", item.id);
          const result = await photoSharingService.deleteMemory(item.id);
        }
        const updatedMemories = memories[window.location.pathname].filter(
          (item) => item.id !== folderId
        );
        dispatch(
          addMemories({
            key: window.location.pathname,
            result: updatedMemories,
          })
        );

        navigate("/memories", { replace: true, state: { forceRefresh: true } });
        setErrorNotific("");
        setSuccessNotific(
          "Album Deleted Successfully! Refresh the page to see updated albums."
        );
      } catch (error) {
        console.log(error);

        setErrorNotific("There was a problem deleting the album!");
        setSuccessNotific("");
      }
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 4000);
    }
  };
  const handleAlbumCreate = async () => {
    const capitalizeFirstLetter = (str) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const newName = inputRef.current.value
      .split(" ")
      .map(capitalizeFirstLetter)
      .join("_");
    toggleMemoryAlbumForm();
    const formData = new FormData();
    formData.append("albumName", newName);
    let tempPath = window.location.pathname.replace("/memories", "");
    const path = tempPath.length === 0 ? `/${tempPath}` : `${tempPath}/`;
    formData.append("path", path);
    inputRef.current.value = "";
    setAlbumName("");

    try {
      const result = await photoSharingService.createAlbum(formData);

      await paginate(1);

      setErrorNotific("");
      setSuccessNotific("Album created successfully.\n Name: " + newName);
    } catch (e) {
      setSuccessNotific("");
      setErrorNotific("Album with this name already exist");
    }
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, [4000]);
  };
  let popups = `${showMemoryImage}${showMemoryUploadForm}${showMemoryAlbumForm}${showPopup}${showRenamePopup}`;
  // useEffect(()=>{
  //   let dashboardElement = document.querySelector(".dashboard");
  //   const body = document.body;

  //   if (dashboardElement && popups.includes("true")) {
  //     // Remove the 'transform' property
  //     dashboardElement.style.transform = 'none';
  //     dashboardElement.style.position = "static"
  //
  //
  //     // Remove the 'transform-origin' property
  //     // dashboardElement.style.transformOrigin = '';
  //   } else if(!popups.includes("true") && dashboardElement){
  //     dashboardElement.style.transform = 'scale(0.8)';
  //
  //     body.style.overflow = 'auto';
  //     body.style.overflow = 'auto';
  //   }
  // },[ (popups)])
  async function handleRenameFolderPopup(item) {
    setSelectedRenameFolder(item);
    setShowRenamePopup(true);
  }

  const handleMoved = (newIndex) => {
    const memoryPath = memoryImages[newIndex];
    const movedIndex = memoryImages.findIndex(
      (memory) => memory.id === memoryPath.id
    );
    setCurrentIndex(movedIndex);
    const newImage = memoryImages[movedIndex];
    setSelectedImage({
      ...newImage,
      imgUrl: newImage.filePath,
    });
    if (newImage.likeBy.includes(user.id)) {
      setIsLiked(true);
    } else {
      setIsLiked(false);
    }
    setLikeCount(newImage.likeBy.length);
  };

  useEffect(() => {
    if (splideInstance) {
      splideInstance.go(currentIndex);
    }
  }, [currentIndex]);

  return (
    <div className="memories-container">
      {/* {showMemoryImage ? (
        <React.Fragment>
          <div className="img-popup">
            <div className="popup-navbar align-items-center">
              <div className="col-xl-4"></div>
              <div className="mt-0 col-xl-4  d-flex justify-content-center ">
                <button
                  onClick={handlePreviousImage}
                  style={{ fontSize: "25px" }}
                  className={currentPage === 1 ? "active" : ""}
                >
                  {" "}
                  &lt;
                </button>
                <div className="" style={{ width: "20px" }}></div>
                <button
                  type="button"
                  onClick={handleNextImage}
                  style={{ fontSize: "25px" }}
                >
                  {" "}
                  &gt;
                </button>
              </div>

              <div className="d-flex align-items-center navbar-end col-xl-4 justify-content-end ">
                <ImDownload3
                  className="icon mx-2"
                  onClick={handleImageDownload}
                />
                <AiFillHeart
                  className={isLiked ? "icon ms-3 red-icon" : "icon ms-3"}
                  onClick={handleLike}
                />
                <p className="fs-4 me-3 mt-3">{likeCount ? likeCount : null}</p>
                {isDeletable ? (
                  <IoMdTrash
                    className="icon mx-2"
                    onClick={handleMemoryDelete}
                  />
                ) : (
                  <></>
                )}
                <div className="close-btn mx-3" onClick={toggleMemoryImage}>
                  <GrFormClose className="icon" />
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-center" style={{height:"100vh"}}>
              <img src={selectedImage.imgUrl} alt="" />
            </div>
          </div>
        </React.Fragment>
      ) : null} */}
      {showPopup ? (
        <div className="user-reg-popup-container">
          <div className="user-reg-popup w-75">
            <div className="user-reg-popup-header">
              <span className="fs-6 fw-bold">Details</span>
              {showCounter ? (
                <div className="upload-counter my-2 fw-bold">
                  <span className="mx-2">
                    Total Records: {userUploadCounter} / {imageFile.length}
                  </span>
                  <span className="text-success mx-2">
                    Success: {uploadedSuccessCounter}
                  </span>
                  <span className="text-danger mx-2">
                    Failed: {uploadedFailedCounter}
                  </span>
                  <span
                    className="ms-4"
                    role="button"
                    data-toggle="tooltip"
                    title="download"
                    onClick={handleUploadReportDownload}
                  >
                    <BiDownload
                      style={{
                        width: "22px",
                        height: "22px",
                        paddingBottom: "3px",
                      }}
                    />
                  </span>
                </div>
              ) : null}

              <button
                className="close-button"
                onClick={() => {
                  setShowPopup(!showPopup);
                  setUserUploadCounter(0);
                  setUploadedFailedCounter(0);
                  setUploadedSuccessCounter(0);
                  setShowCounter(false);

                  setImageArray([]);
                  setImageFile();
                }}
              >
                &#10005;
              </button>
            </div>

            <hr style={{ borderTop: "2px dashed black" }} />
            <div className="table-responsive" style={{ maxHeight: "400px" }}>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col" style={{ color: "#175572" }}>
                      Name
                    </th>

                    <th scope="col" style={{ textAlign: "center" }}>
                      Upload Status
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {imageArray?.length > 0
                    ? imageArray?.map((item, index) => {
                        return (
                          <tr key={index}>
                            {/* <td style={{ color: "#175572" }}>{item.email}</td> */}
                            <td>{item.name}</td>
                            <td style={{ textAlign: "center" }}>
                              {item.status === "Failed" ? (
                                <AiFillCloseCircle
                                  style={{
                                    fill: "red",
                                    width: "20px",
                                    height: "20px",
                                  }}
                                  data-toggle="tooltip"
                                  title="upload failed"
                                />
                              ) : item.status === undefined ? (
                                <>
                                  {" "}
                                  <BiLoaderCircle />
                                </>
                              ) : item.status === "Success" ? (
                                <AiFillCheckCircle
                                  data-toggle="tooltip"
                                  title="upload success"
                                  style={{
                                    fill: "green",
                                    width: "20px",
                                    height: "20px",
                                  }}
                                />
                              ) : null}
                            </td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-center pt-2">
              {/* <button onClick={() => setShowPopup(false)}>Edit</button> */}
            </div>
          </div>
        </div>
      ) : null}
      <div className="memories-header">
        {showAlert ? (
          <div className="alert-container" style={{ zIndex: "10000" }}>
            <div className="upper-section">
              <p>ALOIS</p>
              <span
                className="delete-btn"
                onClick={() => setShowAlert(!showAlert)}
              >
                <AiOutlineClose
                  className="icon"
                  style={{ width: "15px", height: "15px" }}
                />
              </span>
            </div>
            <hr />
            <div className="lower-section">
              <p className="text-danger">{errorNotific}</p>
              <p className="text-success">
                {successNotific.split("\n").map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </p>
            </div>
          </div>
        ) : null}

        <span className="arbitrary"></span>
        {loading ? (
          <div style={{ marginTop: "-120px", marginRight: "40px" }}></div>
        ) : null}

        {uploaded ? (
          <div className="user-reg-popup-container">
            <div className="user-reg-popup w-50" style={{ maxHeight: "500px" }}>
              <div className="user-reg-popup-header">
                <span className="fs-6 fw-bold">Image uploaded</span>
                <span>
                  <AiFillCloseCircle
                    className="icon"
                    onClick={() => {
                      setuploaded(false);
                    }}
                  />
                </span>
              </div>

              <hr style={{ borderTop: "2px dashed black" }} />
              <div className="table-responsive" style={{ maxHeight: "400px" }}>
                <table className="table">
                  <thead>
                    <tr>
                      {/* <th scope="col" style={{ color: "#175572" }}>
                         Email
                       </th> */}
                      <th scope="col">Name</th>
                    </tr>
                  </thead>

                  <tbody>
                    {imageArray?.length > 0
                      ? imageArray?.map((item, index) => {
                          return (
                            <tr key={index}>
                              {/* <td style={{ color: "#175572" }}>{item.email}</td> */}
                              <td>{item.name}</td>
                              <td style={{ textAlign: "center" }}>
                                {item.isUploaded === false ? (
                                  <AiFillCloseCircle
                                    style={{
                                      fill: "red",
                                      width: "20px",
                                      height: "20px",
                                    }}
                                    data-toggle="tooltip"
                                    title="upload failed"
                                  />
                                ) : item.isUploaded === undefined ? (
                                  "-"
                                ) : item.isUploaded === true ? (
                                  <AiFillCheckCircle
                                    data-toggle="tooltip"
                                    title="upload success"
                                    style={{
                                      fill: "green",
                                      width: "20px",
                                      height: "20px",
                                    }}
                                  />
                                ) : null}
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      {showRenamePopup ? (
        <div className="popup-container">
          <div className="popup">
            <div className="popup-header">
              <span className="fs-6 fw-bold">Rename Folder</span>
              <AiFillCloseCircle
                className="icon"
                onClick={() => {
                  setShowRenamePopup(false);
                }}
              />
              {/* <button className="close-button">
                  &#10005;
                </button> */}
            </div>
            <hr style={{ borderTop: "2px dashed black" }} />
            <div className="popup-content mb-1">
              <div className="row mt-3 pb-3">
                <div className="col">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="chooseImage">
                      Enter new name
                    </label>
                    <input
                      type="text"
                      defaultValue={selectedRenameFolder.name}
                      className="form-control"
                      required
                      ref={folderNameRef}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-around my-1">
                <GradientBorderButton
                  text="Submit"
                  clickHandler={handleRenameFolder}
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {!popups.includes("true") ? (
        <div className="indicator-container">
          <div className="d-flex text">
            <MdPhotoLibrary className="icon mx-2" /> Memories
          </div>
          {isVisible && !loading ? (
            <div
              className="d-flex align-items-center gap-3"
              style={{ right: "35%" }}
            >
              <GradientBorderButton
                text={" New Album "}
                icon={<CgAdd />}
                clickHandler={() => {
                  toggleMemoryAlbumForm();
                }}
                // iconAdditionalClass={"icon-styles"}
              />
              {isDeletable && showDeleteButton && (
                <GradientBorderButton
                  text={"Delete"}
                  icon={<AiOutlineDelete />}
                  clickHandler={handleDeleteAlbum}
                  additionalClass={"album-delete-btn mx-2 higher-z-index"}
                />
              )}
              <GradientBorderButton
                text={"Upload"}
                icon={<FiUpload />}
                clickHandler={() => {
                  toggleMemoryUploadForm();
                }}
                additionalClass={"higher-z-index"}
              />
            </div>
          ) : null}
        </div>
      ) : (
        <></>
      )}
      {!popups.includes("true") ? (
        <div
          className={`photoSharing d-flex ${
            showMemoryImage ? "position-absolute top-0" : ""
          }`}
        >
          <div className="gradient-card mx-auto">
            <div className="card-background mt-3">
              <div className="row justify-content-between">
                <div
                  className="col-xl-6 d-flex flex-wrap"
                  style={{ maxWidth: "80%", width: "80%" }}
                >
                  {breadCrumbList && breadCrumbList.length > 0
                    ? breadCrumbList.map((item, index) => {
                        let name = item.slice(1);
                        let shortalbumname;
                        if (name.length > 9) {
                          shortalbumname = name.slice(0, 6) + "...";
                        } else {
                          shortalbumname = name;
                        }
                        function capitalizeFirstLetter(str) {
                          return str.charAt(0).toUpperCase() + str.slice(1);
                        }
                        if (shortalbumname) {
                          shortalbumname =
                            capitalizeFirstLetter(shortalbumname);
                        }
                        return (
                          <div
                            key={index}
                            style={
                              window.location.pathname
                                .split("/")
                                .pop()
                                .toLowerCase() !==
                              item.replace("/", "").toLowerCase()
                                ? { opacity: "0.4" }
                                : {}
                            }
                            onClick={() => handleBreadCrumbClick(item)}
                          >
                            <button className="event-btn my-4">
                              {shortalbumname}
                            </button>
                          </div>
                        );
                      })
                    : null}
                </div>
                <div
                  className="col-xl-6  d-flex justify-content-end  align-items-center"
                  style={{ width: "fit-content" }}
                >
                  {/* <div
                  className="col-xl-1 align-items-center"
                  style={{ height: "50px" }}
                ></div> */}

                  <div
                    className="col-xl-12  row mt-2 mx-2 align-items-center justify-content-"
                    style={{ height: "50px", width: "fit-content" }}
                  >
                    <div
                      className="col-xl-6 p-0 mb-5 align-items-center"
                      style={{
                        width: "fit-content",
                        height: "fit-content",
                        marginTop: "-10px",
                      }}
                    >
                      <input
                        ref={searchTxtRef}
                        placeholder="Search Albums"
                        className="search_albums  mb-3"
                        onChange={filterAlbums}
                      />
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
              {!loading ? (
                <div className="folder-container ms-3">
                  {memories[window.location.pathname] &&
                  memories[window.location.pathname].length
                    ? memories[window.location.pathname].map((item, index) => {
                        if (item.type === "folder" && item.status) {
                          let shortName;
                          if (item.name.length > 9) {
                            shortName = item.name.slice(0, 6) + "...";
                          } else {
                            shortName = item.name;
                          }

                          function capitalizeFirstLetter(str) {
                            return str.charAt(0).toUpperCase() + str.slice(1);
                          }
                          if (shortName) {
                            shortName = capitalizeFirstLetter(shortName);
                          }

                          const spaceIndex = item.name.indexOf("_");
                          let firstPart, secondPart;
                          if (spaceIndex !== -1) {
                            firstPart = item.name.slice(0, spaceIndex);
                            secondPart = item.name.slice(spaceIndex + 1);
                          }

                          if (firstPart && firstPart.length > 10) {
                            firstPart = firstPart.slice(0, 9) + "...";
                          }

                          if (secondPart && secondPart.length > 10) {
                            secondPart = secondPart.slice(0, 9) + "...";
                          }
                          if (firstPart) {
                            firstPart = capitalizeFirstLetter(firstPart);
                          }
                          return (
                            <div
                              className="card-pos px-2 my-2 memory-folder"
                              title={item.name}
                            >
                              <Link
                                to={window.location.pathname + "/" + item.name}
                                className="utility-link"
                                onClick={() => {
                                  getSubFoldersMemories("/" + item.name);
                                }}
                                key={index}
                              >
                                <span className="utility-img ">
                                  <img
                                    className="ms-1"
                                    src={image}
                                    alt="folder"
                                    width="90px"
                                    height="90px"
                                  />
                                </span>
                              </Link>
                              {secondPart ? (
                                <span className="utility-title gx-0 text-center ms-0 d-flex mx-1 justify-content-center">
                                  <Link
                                    to={
                                      window.location.pathname + "/" + item.name
                                    }
                                    className="utility-link px-0 gx-0"
                                    onClick={() =>
                                      getSubFoldersMemories("/" + item.name)
                                    }
                                    key={index}
                                  >
                                    <h6 className="utility-title gx-0 text-center ms-0 px-0 ">
                                      {firstPart}
                                    </h6>
                                    <h6 className="utility-title gx-0 text-center ms-0 ">
                                      {secondPart}
                                    </h6>
                                  </Link>
                                  {isVisible ? (
                                    <BsPencil
                                      className="ms-0 ps-0"
                                      onClick={() => {
                                        handleRenameFolderPopup(item);
                                      }}
                                    />
                                  ) : (
                                    <></>
                                  )}
                                </span>
                              ) : (
                                <span className="utility-title gx-0 text-center ms-0 d-flex mx-1 justify-content-center">
                                  <Link
                                    to={
                                      window.location.pathname + "/" + item.name
                                    }
                                    className="utility-link"
                                    onClick={() =>
                                      getSubFoldersMemories("/" + item.name)
                                    }
                                    key={index}
                                  >
                                    {" "}
                                    <h6 className="utility-title gx-0 text-center  mx-1 ms-2">
                                      {shortName}
                                    </h6>
                                  </Link>
                                  {isVisible ? (
                                    <BsPencil
                                      title="Rename folder"
                                      onClick={() => {
                                        handleRenameFolderPopup(item);
                                      }}
                                    />
                                  ) : (
                                    <></>
                                  )}
                                </span>
                              )}
                            </div>
                          );
                        }
                        return null;
                      })
                    : "No memories"}
                  {loadingNextPage ? (
                    <div
                      class="spinner-border d-flex justify-content-center mx-auto"
                      role="status"
                    >
                      <span class="sr-only"></span>
                    </div>
                  ) : (
                    filteredImageList &&
                    filteredImageList.map((item, index) =>
                      item.status ? (
                        <div
                          className="card-pos px-2 my-2 memory-folder"
                          key={index}
                        >
                          <span
                            className="preview-img"
                            onClick={() => handleImagePopup(item, index)}
                          >
                            <LazyLoadImage
                              src={item.thumbnailfilePath}
                              className="thumbnail-img"
                              alt={item.thumbnailfilePath}
                              effect="blur"
                            />
                          </span>
                        </div>
                      ) : null
                    )
                  )}
                </div>
              ) : (
                <div
                  class="spinner-border d-flex justify-content-center mx-auto"
                  role="status"
                >
                  <span class="sr-only"></span>
                </div>
              )}

              <Tooltip />
              {!loading ? (
                <div className="pagination my-4">
                  {!hideLeftArrow ? (
                    <button
                      onClick={handlePrevPage}
                      disabled={currentPage === 1}
                      className={currentPage === 1 ? "active" : ""}
                    >
                      <FaLessThan
                        className="arrow"
                        style={{
                          marginBottom: "4px",
                          marginRight: "2px",
                          padding: "1px",
                        }}
                      />
                    </button>
                  ) : (
                    <></>
                  )}
                  {pageNumbers.map((page) => (
                    <button
                      key={page}
                      disabled={currentPage === page}
                      className={currentPage === page ? "active" : ""}
                      onClick={() => {
                        paginate(page);
                      }}
                    >
                      {page}
                    </button>
                  ))}
                  {!hideRightArrow ? (
                    <button
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages}
                      className={currentPage === totalPages ? "active" : ""}
                    >
                      <FaGreaterThan
                        className="arrow"
                        style={{
                          marginBottom: "4px",
                          marginLeft: "2px",
                          padding: "1px",
                        }}
                      />
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {showMemoryUploadForm ? (
        <Popup
          clickHandler={() => {
            toggleMemoryUploadForm();
          }}
          submitHandler={handleUpload}
          header={"Upload New Image"}
          label={"Upload Image"}
          btnText={"Upload"}
          cd
          setImageFile={setImageFile}
          inputType={"file"}
        />
      ) : null}

      {showMemoryAlbumForm ? (
        <Popup
          clickHandler={() => {
            toggleMemoryAlbumForm();
          }}
          inputRef={inputRef}
          submitHandler={handleAlbumCreate}
          header={"Create New Album"}
          label={"Folder Name"}
          btnText={"Create"}
          inputType={"text"}
          setAlbumName={setAlbumName}
          albumName={albumName}
        />
      ) : null}

      {showMemoryImage && (
        <div className="preview-popup-container">
          <div className="popup">
            <div className="popup-header">
              <IoClose
                onClick={toggleMemoryImage}
                style={{ fontSize: "32px", color: "#175572" }}
              />
            </div>
            <div className="popup-content preview-icons">
              <div className="d-flex justify-content-center align-items-center gap-5 top-container">
                <IoIosArrowBack
                  className="arrow"
                  style={{
                    marginBottom: "2px",
                    marginLeft: "2px",
                    padding: "1px",
                  }}
                  onClick={handlePreviousImage}
                />
                <div className="image-container">
                  <img
                    src={selectedImage.imgUrl}
                    alt={`${selectedImage.type}`}
                    className="preview-image"
                  />
                </div>
                <IoIosArrowForward
                  className="arrow"
                  style={{
                    marginBottom: "4px",
                    marginLeft: "2px",
                    padding: "1px",
                  }}
                  onClick={handleNextImage}
                />
              </div>

              <div className="status-btns gap-3">
                <GradientBorderButton
                  text={selectedImage.likeBy.length + " Favourite"}
                  icon={<AiFillHeart />}
                  clickHandler={handleLike}
                  iconAdditionalClass={`icon-width`}
                  innerDivClass={`${
                    selectedImage.likeBy.includes(user.id) ? "active" : ""
                  }`}
                />
                <GradientBorderButton
                  text="Download"
                  icon={<ImDownload3 />}
                  clickHandler={handleImageDownload}
                  iconAdditionalClass="icon-width"
                />
                <GradientBorderButton
                  text="Delete"
                  icon={<AiOutlineDelete />}
                  clickHandler={handleMemoryDelete}
                  iconAdditionalClass="icon-width"
                />
              </div>

              <Splide
                hasTrack={false}
                options={{
                  type: "slide",
                  arrows: false,
                  rewind: true,
                  width: "auto",
                  fixedWidth: 100,
                  fixedHeight: 100,
                  marginTop: 20,
                  gap: 25,
                  pagination: false,
                  focus: "center",
                  breakpoints: {
                    600: {
                      fixedWidth: 60,
                      fixedHeight: 44,
                    },
                  },
                  isNavigation: true,
                  updateOnMove: true,
                  start: currentIndex,
                }}
                onMoved={(splide, newIndex) => handleMoved(newIndex)}
                ref={(splide) => {
                  if (splide) {
                    splideInstance = splide.splide;
                  }
                }}
              >
                <SplideTrack>
                  {memoryImages.map((memory, index) =>
                    memory.type === "file" && memory.status ? (
                      <SplideSlide key={index}>
                        <img
                          src={memory.filePath}
                          alt={`memory_${memory.type}`}
                          className="memory-image"
                          style={{
                            height: "98.5%",
                            width: "98.5%",
                            objectFit: "contain",
                          }}
                        />
                      </SplideSlide>
                    ) : null
                  )}
                </SplideTrack>
              </Splide>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PhotoSharing;
