import React, { useEffect, useRef, useState } from "react";
import { MdSnippetFolder } from "react-icons/md";
import GradientBorderButton from "../../components/Widgets/GradientBorderButton";
import Switch from "../../components/Widgets/Switch";
import {
  FaTrashAlt,
  FaSearch,
  FaAngleDoubleLeft,
  FaAngleLeft,
  FaAngleRight,
  FaAngleDoubleRight,
} from "react-icons/fa";
import { IoMdAddCircle } from "react-icons/io";
import masterDBService from "../../services/masterDB.service";
import { AiOutlineClose } from "react-icons/ai";

function Departments() {
  const visiblePages = 5;
  const departmentsPerPage = 10;
  const [department, setDepartment] = useState("");
  const [geography, setGeography] = useState("");
  const [loading, setLoading] = useState(false);
  const [assignedDepartment, setAssignedDepartment] = useState("");
  const [searchInputFocused, setSearchInputFocused] = useState(false);
  const [showCreatePopup, setShowCreatePopup] = useState(false);
  const [showAssignPopup, setShowAssignPopup] = useState(false);
  const [allDepartments, setAllDepartments] = useState([]);
  const [selectedDepartmentForStatus, setSelectedDepartment] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const filterGeoRef = useRef();
  const filterDepRef = useRef();

  const [departments, setDepartments] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [geographyList, setGeographyList] = useState([]);
  const [successNotific, setSuccessNotific] = useState("");
  const [errorNotific, setErrorNotific] = useState("");
  const oldDepRef = useRef();
  const updatedDepRef = useRef();
  const handleToggle = async (data) => {
    try {
      const updatedDepartment = {
        department: data.department,
        status: !data.status,
      };
      const response = await masterDBService.updateDepartment(
        data.id,
        updatedDepartment
      );
      // Update the UI by fetching all shift timings again
      filterDepartment(1);
      handleStatusPopup();
    } catch (error) {
      setShowAlert(true);
      setErrorNotific("Error updating department status");
      setSuccessNotific("");
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };

  const handleStatusPopup = async (data) => {
    setSelectedDepartment(data);
  };
  const getAllGeographies = async () => {
    try {
      const result = await masterDBService.getAllGeographies();

      setGeographyList(result.results);
    } catch (error) {
      console.error("Error fetching shift timings", error);
    }
  };

  const handleCreatePopup = () => {
    setShowCreatePopup(!showCreatePopup);
  };

  const handleAssignPopup = () => {
    setShowAssignPopup(!showAssignPopup);
    setAssignedDepartment("");
  };
  const handleUpdatePopup = () => {
    setShowEditPopup(!showEditPopup);
  };

  useEffect(() => {
    filterDepartment(1);
    getAllGeographies();
    async function getAllDepartments() {
      const result = await masterDBService.getAllDepartments();
      setAllDepartments(result.results);
    }
    getAllDepartments();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let response = await masterDBService.getAllDepartments();
      setDepartments(response.results);

      if (
        response.results.find(
          (item) => item.department.toLowerCase() == department.toLowerCase()
        )
      ) {
        setShowAlert(true);
        setErrorNotific("Department already exists");
        setSuccessNotific("");
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
        return;
      }
      const data = {
        department: department,
        geography: JSON.stringify([geography]),
        status: true,
      };
      const result = await masterDBService.createDepartment(data);
      await filterDepartment(1);
      setShowCreatePopup(false);
      setShowAlert(true);
      setErrorNotific("");
      setSuccessNotific("Department created successfully");
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    } catch (error) {
      setShowAlert(true);
      setErrorNotific("Error creating department");
      setSuccessNotific("");
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };
  const AssignGeography = async (e) => {
    e.preventDefault();
    if (assignedDepartment) {
      try {
        const selectedGeos = geographyList
          .filter((data, index) => {
            const checkbox = document.getElementById(`geo-${index}`);
            if (checkbox?.checked) {
              return checkbox.value;
            }
          })
          .map((data) => data.id);
        let selectedDepartment = assignedDepartment;
        const existingDepartment = allDepartments.find(
          (item) => item.department === selectedDepartment
        );
        if (existingDepartment) {
        } else {
          setShowAlert(true);
          setErrorNotific("Error assigning geographies");
          setSuccessNotific("");
          setTimeout(() => {
            setShowAlert(false);
          }, 3000);
          // If geography already exists, return null
          return null;
        }

        const data = {
          geography: JSON.stringify([...selectedGeos]),
        };
        await masterDBService.updateDepartment(existingDepartment.id, data);
        setShowAssignPopup(false);
        await filterDepartment(1);
        setAssignedDepartment("");
        setShowCreatePopup(false);
        setShowAlert(true);
        setErrorNotific("");
        setSuccessNotific("Geographies assigned successfully");
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
        return;
      } catch (error) {
        setShowAlert(true);
        setErrorNotific("Error assigned geographies");
        setSuccessNotific("");
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      }
    } else {
      setShowAlert(true);
      setErrorNotific("Select a department to assign geographies!");
      setSuccessNotific("");
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };

  const updateDepartment = async () => {
    if (!oldDepRef.current.value) {
      setShowAlert(true);
      setErrorNotific("Old department name can't be empty");
      setSuccessNotific("");
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
      return;
    }
    if (!updatedDepRef.current.value) {
      setShowAlert(true);
      setErrorNotific("New department name can't be empty");
      setSuccessNotific("");
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
      return;
    }
    const existingDepartment = allDepartments.find(
      (item) => item.department === oldDepRef.current.value
    );

    const updatedDepartment = {
      department: updatedDepRef.current.value,
    };

    const response = await masterDBService.updateDepartment(
      existingDepartment.id,
      updatedDepartment
    );

    setShowAlert(true);
    setErrorNotific("");
    setSuccessNotific("Department updated successfully!");
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);

    // Update the UI by fetching all shift timings again
    await filterDepartment(1);
    setShowEditPopup(false);
  };
  const handleDelete = async (depId) => {
    try {
      console.log(depId);
      const shouldDelete = window.confirm(
        "Are you sure you want to delete this department?"
      );

      if (shouldDelete) {
        const response = await masterDBService.deleteDepartment(depId);
        setShowAlert(true);
        setErrorNotific("");
        setSuccessNotific("Department deleted successfully");
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
        await filterDepartment(1);
      }
    } catch (error) {
      setShowAlert(true);
      setErrorNotific("Error deleting Department");
      setSuccessNotific("");
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };
  const filterDepartment = async (page = 1) => {
    const result = await masterDBService.getAllDepartments();
    setLoading(true);
    setAllDepartments(result.results);
    setCurrentPage(page);
    setTimeout(() => {
      let filteredResult = result.results;

      if (filterGeoRef.current?.value.trim() !== "") {
        filteredResult = filteredResult.filter((item) => {
          return item.geography.find(
            (data) => data.geography == filterGeoRef.current?.value
          );
        });
      }

      if (filterDepRef.current?.value.trim() !== "") {
        filteredResult = filteredResult.filter((item) =>
          item.department
            .toLowerCase()
            .includes(filterDepRef.current.value.toLowerCase())
        );
      }
      setLoading(false);
      const totalRecords = filteredResult.length;
      const totalPages = Math.ceil(totalRecords / departmentsPerPage);

      // Adjust page if necessary
      page = Math.min(page, totalPages); // Ensure page is within range
      page = Math.max(page, 1); // Ensure page is not less than 1

      // Calculate page range to display
      // Maximum number of pages to display
      let startPage = Math.max(1, page - Math.floor(visiblePages / 2));
      let endPage = Math.min(totalPages, startPage + visiblePages - 1);

      // Adjust startPage and endPage if necessary
      if (endPage - startPage + 1 < visiblePages) {
        startPage = Math.max(1, endPage - visiblePages + 1);
      }

      // Paginate the filtered result for the current page
      const startIdx = (page - 1) * departmentsPerPage;
      const endIdx = Math.min(startIdx + departmentsPerPage, totalRecords);

      // Paginate the filtered result for the current page
      const paginatedResult = filteredResult.slice(startIdx, endIdx);

      // Update state with paginated result and pagination parameters
      setDepartments(paginatedResult);
      const tempTotalPages = Math.max(
        1,
        Math.ceil(filteredResult.length / departmentsPerPage)
      );
      setTotalPages(tempTotalPages);

      const tempPageArr = [];
      for (let i = 1; i <= tempTotalPages; i++) {
        tempPageArr.push(i);
      }
      tempPageArr.length <= 5 && setPageNumbers(tempPageArr);
      setDepartments(paginatedResult);
    }, 2000);
  };

  const handleClear = async () => {
    filterGeoRef.current.value = "";
    filterDepRef.current.value = "";
    filterDepartment(1);
  };
  const paginate = (currPage, data) => {
    // Scroll to the top of the table whenever page number is changed

    const start = (currPage - 1) * departmentsPerPage;
    const end = start + departmentsPerPage;
    setDepartments(data.slice(start, end));
  };
  const handleDualPrevPage = () => {
    const setPageNumber = currentPage - 5;
    setPageNumber <= 0 && (setPageNumber = 1);

    setCurrentPage(setPageNumber);
    adjustPages(setPageNumber);

    filterDepartment(setPageNumber);
  };
  const handlePrevPage = () => {
    const setPageNumber = currentPage - 1;
    setCurrentPage(setPageNumber);
    adjustPages(setPageNumber);

    filterDepartment(setPageNumber);
  };
  const handleNextPage = () => {
    const setPageNumber = currentPage + 1;
    setCurrentPage(setPageNumber);
    adjustPages(setPageNumber);

    filterDepartment(setPageNumber);
  };
  const handleDualNextPage = () => {
    const setPageNumber = currentPage + 5;
    setPageNumber > totalPages && (setPageNumber = totalPages);

    setCurrentPage(setPageNumber);
    adjustPages(setPageNumber);

    filterDepartment(setPageNumber);
  };
  const adjustPages = (currentPage, tempTotalPages) => {
    let finalTotalPages = tempTotalPages || totalPages;
    let tempStart = 1;
    let tempEnd = Math.min(5, finalTotalPages);

    if (finalTotalPages > 5) {
      if (currentPage > 3 && currentPage < finalTotalPages - 1) {
        tempStart = Math.max(1, currentPage - Math.floor(visiblePages / 2));
        tempEnd = Math.min(tempStart + visiblePages - 1, finalTotalPages);
      } else if (currentPage >= finalTotalPages - 1) {
        // If it is the last or last second page, then show the last 5 page numbers
        tempStart = Math.max(1, finalTotalPages - visiblePages + 1);
        tempEnd = finalTotalPages;
      }
    } else {
      tempStart = 1;
      tempEnd = finalTotalPages;
    }

    let tempPageNumbers = Array.from(
      { length: tempEnd - tempStart + 1 },
      (_, index) => tempStart + index
    );

    if (tempPageNumbers[0] !== 0) {
      setPageNumbers(tempPageNumbers);
    }

    setCurrentPage(currentPage);
  };
  return (
    <div className="d-flex departments-container flex-column">
      <div className="indicator-container pt-2 pb-4 ">
        <div className="d-flex text">
          <MdSnippetFolder className="icon mx-2" /> Departments
        </div>
      </div>
      {showAlert ? (
        <div className="alert-container" style={{ zIndex: "10000000" }}>
          <div className="upper-section">
            <p style={{ marginBottom: "0px", marginTop: "5px" }}>ALOIS</p>
            <span
              className="delete-btn"
              onClick={() => setShowAlert(!showAlert)}
            >
              <AiOutlineClose className="icon" style={{ fill: "black" }} />
            </span>
          </div>
          <hr
            style={{
              display: "block !important",
              height: "1px !important",
              border: "0 !important",
              borderTop: "1px solid gray !important",
            }}
          />
          <div className="lower-section py-2">
            <p className="text-danger">{errorNotific}</p>
            <p className="text-success">{successNotific}</p>
          </div>
        </div>
      ) : null}
      {selectedDepartmentForStatus && (
        <div className="departments-popup-container">
          <div className="departments-popup">
            <div className="departments-popup-header">
              <span className="fs-6 fw-bold">Change Department Status</span>
              <button
                className="close-button"
                onClick={() => {
                  handleStatusPopup();
                }}
              >
                &#10005;
              </button>
            </div>

            <hr style={{ borderTop: "2px dashed black" }} />
            <p>Are you sure you want to proceed?</p>
            <div className="d-flex justify-content-center pt-2 gap-3">
              <GradientBorderButton
                text="Confirm"
                clickHandler={() => {
                  handleToggle(selectedDepartmentForStatus);
                }}
              />
              <GradientBorderButton
                text="Cancel"
                clickHandler={() => {
                  handleStatusPopup();
                }}
              />
            </div>
          </div>
        </div>
      )}
      {showCreatePopup && (
        <div className="departments-popup-container">
          <div className="departments-popup">
            <div className="departments-popup-header">
              <span className="fs-6 fw-bold">Create Department</span>
              <button className="close-button" onClick={handleCreatePopup}>
                &#10005;
              </button>
            </div>

            <hr style={{ borderTop: "2px dashed black" }} />
            <div className="form-container">
              <form>
                <div className="date-container ">
                  <div className="date-container ">
                    <label className="form-label d-block">Department</label>
                    <input
                      className="form-control"
                      type="text"
                      id="deparment"
                      onChange={(e) => {
                        setDepartment(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="date-container ">
                  <label className="form-label d-block">Geography</label>
                  <select
                    name="timing"
                    id="timing"
                    className="form-select dropdown-styles"
                    onChange={(e) => {
                      setGeography(e.target.value);
                    }}
                    placeholder="Geography"
                  >
                    <option key="key" value="">
                      Select a geography
                    </option>
                    {geographyList.map((data, index) => (
                      <option key={index} value={data.id}>
                        {data.geography}
                      </option>
                    ))}
                  </select>
                </div>
              </form>
            </div>
            <div className="d-flex justify-content-center pt-2">
              <GradientBorderButton
                text="Confirm"
                clickHandler={handleSubmit}
              />
            </div>
          </div>
        </div>
      )}
      {showAssignPopup && (
        <div className="departments-popup-container">
          <div className="departments-popup">
            <div className="departments-popup-header">
              <span className="fs-6 fw-bold">Assign Geography</span>
              <button className="close-button" onClick={handleAssignPopup}>
                &#10005;
              </button>
            </div>
            <hr style={{ borderTop: "2px dashed black" }} />
            <div className="form-container">
              <form>
                <div className="date-container ">
                  <label className="form-label d-block">Department</label>
                  <select
                    name="timing"
                    id="departments"
                    className="form-select dropdown-styles"
                    onChange={(e) => {
                      setAssignedDepartment(e.target.value);
                    }}
                    placeholder="Departments"
                  >
                    <option key="key" value="">
                      Select a department
                    </option>
                    {allDepartments.map((data, index) => (
                      <option key={index} value={data.department}>
                        {data.department}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="date-container">
                  {assignedDepartment ? (
                    <label className="form-label mt-1">Geography</label>
                  ) : (
                    <></>
                  )}

                  <div>
                    {assignedDepartment &&
                      geographyList.map((data, index) => {
                        return (
                          <div key={index}>
                            <input
                              type="checkbox"
                              id={`geo-${index}`}
                              name={`timing-${index}`}
                              value={data.id}
                              defaultChecked={allDepartments.some((item) => {
                                
                                const isChecked =
                                  item.department === assignedDepartment &&
                                  item.geography.some(
                                    (geo) => geo.id === data.id
                                  );
                                
                                console.log(
                                  `Department: ${item.department}, Assigned Department: ${assignedDepartment}, Data ID: ${data.id}, Is Checked: ${isChecked}`
                                );

                                return isChecked;
                              })}
                              className="form-checkbox checkbox-styles mr-2"
                            />
                            <label htmlFor={`timing${index}`}>
                              {data.geography}
                            </label>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </form>
            </div>
            <div className="d-flex justify-content-center pt-2">
              <GradientBorderButton
                text="Confirm"
                clickHandler={AssignGeography}
              />
            </div>
          </div>
        </div>
      )}
      {showEditPopup && (
        <div className="departments-popup-container">
          <div className="departments-popup">
            <div className="departments-popup-header">
              <span className="fs-6 fw-bold">Update Department</span>
              <button className="close-button" onClick={handleUpdatePopup}>
                &#10005;
              </button>
            </div>

            <hr style={{ borderTop: "2px dashed black" }} />
            <div className="form-container">
              <form>
                <div className="date-container ">
                  <label className="form-label d-block">Department</label>
                  <select
                    name="timing"
                    id="timing"
                    ref={oldDepRef}
                    className="form-select dropdown-styles"
                    placeholder="Departments"
                  >
                    <option key="key" value="">
                      Select a department
                    </option>
                    {allDepartments.map((data, index) => (
                      <option key={index} value={data.department}>
                        {data.department}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="date-container">
                  <label className="form-label d-block">
                    Updated Department
                  </label>
                  <input ref={updatedDepRef} type="text" />
                </div>
              </form>
            </div>
            <div className="d-flex justify-content-center pt-2">
              <GradientBorderButton
                text="Confirm"
                clickHandler={updateDepartment}
              />
            </div>
          </div>
        </div>
      )}

      <div className="departments-outer mx-auto">
        <div className="departments-inner mt-3">
          <div className="d-flex justify-content-between align-items-center">
            <div className="heading"></div>
            <div className="d-flex align-items-center gap-3">
              <div className="input-group d-flex align-items-center">
                <button
                  className={`btn btn-outline-secondary search-btn ${
                    searchInputFocused && "search-input-focused"
                  }`}
                  type="button"
                  id="button-addon1"
                  // onClick={() => userRef.current.focus()}
                >
                  <FaSearch className="mb-1" />
                </button>
                <input
                  type="text"
                  className="form-control search-input-user"
                  placeholder="Search Deparment"
                  id="filter-input"
                  aria-label="Example text with button addon"
                  aria-describedby="button-addon1"
                  ref={filterDepRef}
                  onChange={() => filterDepartment(1)}
                  onFocus={() => setSearchInputFocused(true)}
                  onBlur={() => setSearchInputFocused(false)}
                />
              </div>
              <div
                style={{ width: "fit-content" }}
                className="form-outline filter-btn"
              >
                <select
                  name="timing"
                  id="geography"
                  className="form-select dropdown-styles"
                  ref={filterGeoRef}
                  style={{ width: "150px" }}
                  onChange={() => {
                    filterDepartment(1);
                  }}
                  placeholder="Geography"
                >
                  <option key="key" value="">
                    Select
                  </option>
                  {geographyList.map((data, index) => (
                    <option key={index} value={data.geography}>
                      {data.geography}
                    </option>
                  ))}
                </select>
              </div>
              <div className="create-btn">
                <GradientBorderButton
                  text="Clear"
                  iconAdditionalClass="icon-width"
                  additionalClass="mt-0"
                  outerDivClass="height-34"
                  innerDivClass="height-30 gap-0"
                  clickHandler={handleClear}
                />
              </div>
              <div className="create-btn">
                <GradientBorderButton
                  icon={<IoMdAddCircle />}
                  text="Update"
                  iconAdditionalClass="icon-width"
                  additionalClass="mt-0"
                  outerDivClass="height-34"
                  innerDivClass="height-30 gap-0"
                  clickHandler={handleUpdatePopup}
                />
              </div>
              <div className="create-btn">
                <GradientBorderButton
                  icon={<IoMdAddCircle />}
                  text="Assign"
                  iconAdditionalClass="icon-width"
                  additionalClass="mt-0"
                  outerDivClass="height-34"
                  innerDivClass="height-30 gap-0"
                  clickHandler={handleAssignPopup}
                />
              </div>
              <div className="create-btn">
                <GradientBorderButton
                  icon={<IoMdAddCircle />}
                  text="Create"
                  iconAdditionalClass="icon-width"
                  additionalClass="mt-0"
                  outerDivClass="height-34"
                  innerDivClass="height-30 gap-0"
                  clickHandler={handleCreatePopup}
                />
              </div>
            </div>
          </div>
          {loading == false ? (
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">
                      <div className="d-flex justify-content-center">
                        Department
                      </div>
                    </th>
                    <th scope="col">
                      <div className="d-flex justify-content-center">
                        Geography
                      </div>
                    </th>
                    <th scope="col">
                      <div className="d-flex justify-content-center">
                        Status
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {departments.map((data, index) => (
                    <tr key={data.id}>
                      <td>
                        <div className="d-flex justify-content-center">
                          {data.department}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center">
                          {data.geography.map((shift, index) => {
                            if (shift.status) {
                              return index === data.geography.length - 1
                                ? shift.geography
                                : shift.geography + ", ";
                            }
                          })}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex justify-content-center switch-container">
                          <Switch
                            id={data.id}
                            isOn={data.status}
                            handleToggle={() => {
                              handleStatusPopup(data);
                            }}
                            colorOne="#0FB215"
                            colorTwo="#DB0000"
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p style={{ color: "#175572" }}>Loading...</p>
          )}
          {totalPages && pageNumbers.length > 1 ? (
            <div className="pagination mt-2">
              {currentPage !== 1 && totalPages > 5 && (
                <button onClick={handleDualPrevPage}>
                  <FaAngleDoubleLeft className="arrow-icon left-arrow " />
                </button>
              )}

              {currentPage !== 1 && (
                <button onClick={handlePrevPage}>
                  <FaAngleLeft className="arrow-icon left-arrow" />
                </button>
              )}

              {pageNumbers.length > 1 &&
                pageNumbers.map((page) => (
                  <button
                    key={page}
                    disabled={currentPage === page}
                    className={currentPage === page ? "active" : ""}
                    onClick={() => {
                      filterDepartment(page);
                      adjustPages(page);
                      paginate(page, departments);
                    }}
                  >
                    {page}
                  </button>
                ))}

              {currentPage !== totalPages && (
                <button onClick={handleNextPage}>
                  <FaAngleRight className="arrow-icon" />
                </button>
              )}

              {currentPage !== totalPages && totalPages > 5 && (
                <button onClick={handleDualNextPage}>
                  <FaAngleDoubleRight className="arrow-icon" />
                </button>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

export default Departments;
