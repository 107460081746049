import React, { useRef, useState, useEffect } from "react";

import GenerateBox from "../GenerateBox/GenerateBox";
import { useSearchParams } from "react-router-dom";
import axios from "../../config/axios";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Notification from "../Notification/Notification";
import { useSelector, useDispatch } from "react-redux";
import licenceValidator from "../../permission/validate";
import ImageUtil from "../../utilities/ImageUtil";
import { AiOutlineClose } from "react-icons/ai";
import GradientBorderButton from "../Widgets/GradientBorderButton";

const RecruiterSocial = () => {
  const [generateComponents, setGenerateComponents] = useState(false);
  const { user } = useSelector((state) => state.user);
  const desRef = useRef();
  const limRef = useRef();
  const emailRef = useRef();
  const locRef = useRef();
  const remRef = useRef();
  const tenRef = useRef();
  const companyRef = useRef();
  const [success, setSuceess] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const intentRef = useRef();
  const [error, setError] = useState("");
  const [answer, setAnswer] = useState("");
  const [loader, setloader] = useState(false);
  const [showAlert, setShowAlert] = useState(false);


  const [successNotific, setSuccessNotific] = useState("");
  const [errorNotific, setErrorNotific] = useState("");
  const intents = [
    "Formal",
    "Professional",
    "CTA based",
    "Inspirational",
    "Informative",
    "Witty",
    "Humourous",
    "Creative",
    "Informal",
    "Casual",
    "Enthusiastic",
    "Story based",
  ];
  const handleReset = () =>{
    setloader(false);
    setGenerateComponents(false);
    setAnswer("");

  }
  const handleSubmit = async () => {
   
    if(!/^[a-zA-Z\s]*$/g.test(desRef.current.value)){
      setShowAlert(true)
      setErrorNotific("Designation must be alphabets only")
      setTimeout(() => {
        setErrorNotific("")
        setShowAlert(false)
      }, 2000);
      return
    }
    
    if(desRef.current.value.length > 30){
      setShowAlert(true)
      setErrorNotific("Designation must be less than 30 characters")
      setTimeout(() => {
        setErrorNotific("")
        setShowAlert(false)
      }, 2000);
      return
    }
    // After submitting the second time job position, location, etc., we empty the preview box to address the issue of displaying previously submitted data.
    setImage("");
    
    if((!limRef.current.value || /^(5[0-9]|[6-9][0-9]|[1-9][0-9]{2,})$/.test(limRef.current.value)) && /^[a-zA-Z\s]*$/g.test(desRef.current.value) &&  /^[a-zA-Z, ]*$/.test(locRef.current.value)){


    setloader(true);
    setGenerateComponents(false);
    try {
      setError("");
      let prompt =
        "Write a" +
        intentRef.current.value +
        " detailed social media caption from a recruiter's perspective for the position of " +
        desRef.current.value +
        " with " +
        tenRef.current.value +
        " experience.";
      if (remRef.current.value) {
        prompt = prompt + remRef.current.value;
      }
      prompt = prompt + " for location " + locRef.current.value;
      if (companyRef.current.value) {
        prompt = prompt + " for a position at" + companyRef.current.value;
      } else {
        prompt = prompt + " for a position at ALOIS Solutions";
      }

      if (emailRef.current.value) {
        prompt =
          prompt + "  Also add cta email id to " + emailRef.current.value;
      } else {
        let user = JSON.parse(localStorage.getItem("user"));
        prompt = prompt + "  Also add cta email id to " + user.email;
      }
      prompt = prompt + "also add 15 related hashtags";

      const res = await axios.post("/utility/recruiterSocial", {
        prompt: prompt,
        limit:limRef.current.value,
      });
      setAnswer(res.data.answer);
      setGenerateComponents(true);
    } catch (err) {
      setError("Server Error!");
    }
    setloader(false);
  } else {
    if(limRef.current.value && !/^(5[0-9]|[6-9][0-9]|[1-9][0-9]{2,})$/.test(limRef.current.value)){
      setShowAlert(true)
      setErrorNotific("Limit must be greater than or equal to 50")
      setTimeout(() => {
        setErrorNotific("")
        setShowAlert(false)
      }, 2000);
    }
  
    if(!/^[a-zA-Z, ]*$/.test(locRef.current.value)){
      setShowAlert(true)
      setErrorNotific("Location must be alphabets only")
      setTimeout(() => {
        setErrorNotific("")
        setShowAlert(false)
      }, 2000);
    }
  }
  };
  useEffect(() => {
    let user = localStorage.getItem("user");
    let validator = new licenceValidator(user, "assist");
    setIsVisible(validator.isReadable("recruiter_social"));
  }, []);
  const [array, setArray] = useState([]);
  const [Images, setImages] = useState([]);
  const [ImageLoader, setImageLoader] = useState(false);
  const [image, setImage] = useState();
  // const [loader, setLoader] = useState(false)
  const [Div, setDiv] = useState(false);

  const x = [];
  const call = async () => {
    try {
      let department = user.department;
      if(user.email.includes("healthcare")){
        department = "Healthcare"
      } else if(user.email.includes("aloissolutions")){
        department = "Delivery"
      }
      const res = await axios.get(`/linkedin/showSMTemplates?department=${department}`, {});
      const results = res.data.results;
      const length = results.length;
      for (let i = 0; i < length; i++) {
        const path = res.data.results[i].filePath.replace("./src/tmp/", "/");
        setImages((images) => [
          ...images,
         path,
        ]);
        x.push(results[i].id);
          
      }
      const path = res.data.results[0].filePath.replace("./src/tmp/", "/");
      setImage(null);

      setArray(x);
    } catch (err) {
      // console.log(err);
    }
  };

  useEffect(() => {
    call();
    emailRef.current.value = user.email;
    // eslint-disable-next-line
  }, []);
  const selectImage = async (e) => {
    e.preventDefault();
    setImageLoader(true);
    let index = e.target.alt;
    let filePath = Images[index];
    let department = user.department;
    if(user.email.includes("healthcare")){
      department = "Healthcare"
    } else if(user.email.includes("aloissolutions")){
      department = "Delivery"
    }
    
    try {
      const res = await axios.post("/linkedin/putTextOnImage", {
        data: {
          imgPath: filePath,
          position: desRef.current.value,
          location: locRef.current.value,
          experience: tenRef.current.value,
          department:department,
          email:
            emailRef.current && emailRef.current.value
              ? emailRef.current.value
              : user.email,
        },
      });
      // let imgPath = res.data.value.path.replace("src/tmp/ip/", "");
      setImage(
        res.data.value
      );
    } catch (err) {
      setError("Server Error!");
    }
    setImageLoader(false);
  };

  const Download = async(e) => {
    // console.log(image);
    const response = await fetch(image, 
      // { referrerPolicy: "unsafe_url"} 
      );
    response.arrayBuffer().then(function (buffer) {
      const url = window.URL.createObjectURL(new Blob([buffer]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${desRef.current.value}.png`); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };
  return (
    <div className="recruiter-social p-5">
        {showAlert ? (
        <div className="alert-container">
          <div className="upper-section">
            <p style={{ marginBottom: "0px", marginTop: "5px" }}>ALOIS</p>
            <span  className="delete-btn" onClick={() => setShowAlert(!showAlert)}>
              <AiOutlineClose className="icon" style={{ fill: "black" }} />
            </span>
          </div>
          <hr
            style={{
              display: "block !important",
              height: "1px !important",
              border: "0 !important",
              borderTop: "1px solid gray !important",
            }}
          />
          <div className="lower-section pt-2">
            <p className="text-danger">{errorNotific}</p>
            <p className="text-success">{successNotific}</p>
          </div>
        </div>
      ) : null}
      <form >
        <div className="row">
          <div className="col">
            <div className="form-outline">
              <label className="form-label" htmlFor="form8Example1">
                Designation
              </label>
              <input
                ref={desRef}
                type="text"
                id="form8Example1"
                class="form-control"
                required
              />
            </div>
          </div>
          <div class="col">
            <div class="form-outline">
              <label class="form-label" for="form8Example2">
                Experience
              </label>
              <input
                ref={tenRef}
                type="text"
                id="form8Example1"
                class="form-control"
                maxLength="20"
                required
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-outline">
              <label class="form-label pt-3" for="form8Example1">
                Location
              </label>
              <input
                ref={locRef}
                type="text"
                id="form8Example1"
                class="form-control"
                required
              />
            </div>
          </div>
          <div className="col">
            <div className="form-outline">
              <label className="form-label pt-3" htmlFor="form8Example2">
                Email{" "}
                <span className="fw-normal">
                  <em>(Optional)</em>
                </span>
              </label>
              <input
                ref={emailRef}
                pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                type="email"
                id="form8Example1"
                className="form-control"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-outline">
              <label className="form-label pt-3" htmlFor="form8Example1">
                Company Name{" "}
                <span className="fw-normal">
                  <em>(Optional)</em>
                </span>
              </label>
              <input
                ref={companyRef}
                type="text"
                id="form8Example1"
                className="form-control"
              />
            </div>
          </div>
          <div className="col">
            <div className="form-outline">
              <label className="form-label pt-3" htmlFor="form8Example4">
                Intent
              </label>
              <div className="position-relative">
                <select
                  ref={intentRef}
                  name="intent"
                  id="intent"
                  className="form-select"
                  required
                >
                  {intents.map((intent, index) => {
                    return (
                      <option key={index} value={intent}>
                        {intent}
                      </option>
                    );
                  })}
                </select>
                <div className="arrow-down"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="form-outline">
              <label className="form-label pt-3" htmlFor="form8Example1">
                Word limit{" "}
                <span className="fw-normal">
                  <em>(Optional)</em>
                </span>
              </label>
              <input
                ref={limRef}
                type="number"
                id="form8Example1"
                className="form-control"
              />
            </div>
          </div>
     
        </div>
        <div className="row-xl-6">
          <div className="form-outline">
            <label className="form-label pt-3" htmlFor="form8Example1">
              Remark{" "}
              <span className="fw-normal">
                <em>(Optional)</em>
              </span>
            </label>
            <input
              ref={remRef}
              type="text"
              id="form8Example1"
              className="form-control"
            />
          </div>
        </div>
        {loader ? (
          <div className="mx-auto mt-3 p-1">
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            &nbsp; Loading...
          </div>
        ) : isVisible ? (
          <div className="d-flex justify-content-center w-100 mt-3">
          <GradientBorderButton clickHandler={handleSubmit} text={"Submit"} />
          </div>
        ) : null}

        {generateComponents && (
          <div>
            <div className="row">
              <div className="col">
                <GenerateBox
                  fileName={desRef.current.value}
                  text={answer}
                  showSocialIcons={true}
                  showJdIcons={false}
                  handleSubmit={handleSubmit}
                  handleReset={handleReset}
                />
              </div>
              <div className="col">
                <div>
                  <div
                    className="d-flex justify-content-center align-items-center border border-secondary mt-5 rounded px-2 py-2"
                    style={{ minHeight: "440px" }}
                  >
                    {ImageLoader ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      <>
                        {image ? (
                          <div>
                            <img
                              style={{ width: "350px" }}
                              src={image}
                              alt=""
                            />

                            <button
                              className="generate-btn mx-auto mt-3"
                              type="button"
                              onClick={Download}
                            >
                              {/* <a href={image} download> */}
                                {" "}
                                <i className="fas fa-arrow-down"> </i>Download
                              {/* </a> */}
                            </button>
                          </div>
                        ) : (
                          <p>Please select one of the below image.</p>
                        )}{" "}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex overflow-auto my-2 border border-secondary rounded px-2 py-2">
                {Images.map((i, index) => {
                  return (
                    <div>
                      <img
                        className="mx-2 border border-secondary rounded"
                        onClick={(e) => {
                          selectImage(e);
                        }}
                        style={{ width: "150px" }}
                        src={i}
                        alt={index}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </form>
      <Notification success={success} error={error} />
    </div>
  );
};

export default RecruiterSocial;
