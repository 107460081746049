import { useEffect, useRef, useState } from "react";
import { FaClock } from "react-icons/fa6";
import Switch from "../../components/Widgets/Switch";

import DatePicker from "react-datepicker";
import masterDBService from "../../services/masterDB.service";
import GradientBorderButton from "../../components/Widgets/GradientBorderButton";
import { AiOutlineClose } from "react-icons/ai";
import dstService from "../../services/dst.service";

function DayLightSaving() {
  const [shiftType, setShiftType] = useState("automation");
  const [isDSTEnabled, setIsDSTEnabled] = useState(false);
  const [portalNotification, setPortalNotification] = useState(false);
  const [emailNotification, setEmailNotification] = useState(false);
  const [selectedDstStartDate, setSelectedDstStartDate] = useState(false);
  const [selectedDstEndDate, setSelectedDstEndDate] = useState(false);
  const [geographyList, setGeographyList] = useState([]);
  const [assignedGeography, setAssignedGeography] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [successNotific, setSuccessNotific] = useState("");
  const [errorNotific, setErrorNotific] = useState("");
  const [existingDST, setExistingDST] = useState(null);
  const getAllGeographies = async () => {
    const result = await masterDBService.getAllGeographies();

    setGeographyList(result.results);
  };
  useEffect(() => {
    getAllGeographies();
  }, []);

  const handleSubmit = async () => {
    if (!assignedGeography) {
      setShowAlert(true);
      setErrorNotific("Please select a geography to continue!");
      setSuccessNotific("");
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
      return;
    }
    if (!selectedDstStartDate) {
      setShowAlert(true);
      setErrorNotific("DST Start Date can't be empty");
      setSuccessNotific("");
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
      return;
    }
    if (!selectedDstEndDate) {
      setShowAlert(true);
      setErrorNotific("DST End Date can't be empty");
      setSuccessNotific("");
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
      return;
    }
    let adjustedShiftsArray = [];
    const numRows = assignedGeography.shiftTiming.length;

    if (shiftType == "manual") {
      for (let i = 0; i < numRows; i++) {
        const startInput = document.getElementById(`manual-start-${i}`);
        const endInput = document.getElementById(`manual-end-${i}`);

        if (startInput.value === "" || endInput.value === "") {
          setShowAlert(true);
          setErrorNotific(
            "Adjusted shifttimings can't be empty in manual shift adjustment"
          );
          setSuccessNotific("");
          setTimeout(() => {
            setShowAlert(false);
          }, 3000);
          return;
        } else {
          const startTime = new Date(`1970-01-01T${startInput.value}:00`);
let endTime = new Date(`1970-01-01T${endInput.value}:00`);

// If end time is earlier than start time, assume it's on the next day
if (endTime < startTime) {
  endTime.setDate(endTime.getDate() + 1);
}

const durationInMilliseconds = endTime - startTime;
const durationInHours = durationInMilliseconds / (1000 * 60 * 60);

          if (durationInHours !== 9) {
            setShowAlert(true);
            setErrorNotific(
              `Row ${
                i + 1
              }: The duration between start time and end time must be exactly 9 hours`
            );
            setSuccessNotific("");
            setTimeout(() => {
              setShowAlert(false);
            }, 3000);
            return;
          }
          adjustedShiftsArray.push({
            id: assignedGeography.shiftTiming[i].id,
            previousShift: `${
              assignedGeography.shiftTiming[i].startTime +
              " - " +
              assignedGeography.shiftTiming[i].endTime
            }`,
            adjustedShift: `${startInput.value + " - " + endInput.value}`,
          });
        }
      }
    } else {
      for (let i = 0; i < numRows; i++) {
        const startTime = new Date(
          "01/01/2022 " + assignedGeography.shiftTiming[i].startTime
        );
        const endTime = new Date(
          "01/01/2022 " + assignedGeography.shiftTiming[i].endTime
        );

        // Add one hour to start time
        startTime.setHours(startTime.getHours() + 1);

        // Add one hour to end time
        endTime.setHours(endTime.getHours() + 1);

        // Format the times
        const formattedStartTime = startTime.toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "2-digit",
        });
        const formattedEndTime = endTime.toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "2-digit",
        });

        // Combine formatted times into a single string
        const formattedTimeString = `${formattedStartTime} - ${formattedEndTime}`;
        adjustedShiftsArray.push({
          id: assignedGeography.shiftTiming[i].id,
          previousShift: `${
            assignedGeography.shiftTiming[i].startTime +
            " - " +
            assignedGeography.shiftTiming[i].endTime
          }`,
          adjustedShift: formattedTimeString,
        });
      }
    }
    if (new Date(selectedDstStartDate) > new Date(selectedDstEndDate)) {
      setShowAlert(true);
      setErrorNotific("DST Start Date can't be greater than DST End Date");
      setSuccessNotific("");
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
      return;
    }
    let date = new Date(selectedDstStartDate);

    let day = String(date.getDate()).padStart(2, "0");
    let month = String(date.getMonth() + 1).padStart(2, "0");
    let year = date.getFullYear();

    const formattedDSTStartDate = `${day}-${month}-${year}`;

    date = new Date(selectedDstStartDate);

    day = String(date.getDate()).padStart(2, "0");
    month = String(date.getMonth() + 1).padStart(2, "0");
    year = date.getFullYear();
    const formattedDSTEndDate = `${day}-${month}-${year}`;

    if (existingDST) {
      let response = await dstService.updateDST(existingDST.id, {
        geography: assignedGeography.id,
        DSTStartDate: formattedDSTStartDate,
        DSTEndDate: formattedDSTEndDate,
        status: shiftType,
        adjustedShifts: JSON.stringify(adjustedShiftsArray),
      });
      setAssignedGeography("");
      setSelectedDstEndDate();
      setSelectedDstStartDate();
      setShiftType("automation");
      setShowAlert(true);
      setErrorNotific("");
      setSuccessNotific("Day light saving updated successfully");
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    } else {
      let response = await dstService.uploadDST({
        geography: assignedGeography.id,
        DSTStartDate: formattedDSTStartDate,
        DSTEndDate: formattedDSTEndDate,
        status: shiftType,
        adjustedShifts: JSON.stringify(adjustedShiftsArray),
      });
      setAssignedGeography("");
      setSelectedDstEndDate();
      setSelectedDstStartDate();
      setShiftType("automation");
      setShowAlert(true);
      setErrorNotific("");
      setSuccessNotific("Day light saving created successfully");
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };

  const handleOptionChange = (event) => {
    setShiftType(event.target.value);
  };

  useEffect(() => {
    async function getExistingDST() {
      let response = await dstService.getDST(assignedGeography.id);
      setExistingDST(response.data.results[0]);
      setShiftType(response.data.results[0].status);
      const parseDateString = (dateString) => {
        const [day, month, year] = dateString.split("-");
        return new Date(year, month - 1, day);
      };
      let startDate = parseDateString(response.data.results[0].DSTStartDate);

      setSelectedDstStartDate(startDate);
      let endDate = parseDateString(response.data.results[0].DSTEndDate);
      setSelectedDstEndDate(endDate);

      if (response.data.results[0].status === "manual") {
        const adjustedShifts = response.data.results[0].adjustedShifts;
        const formatTime = (timeString) => {
          // Remove AM/PM and convert to 24-hour format
          let [time, period] = timeString.split(" ");
          let [hours, minutes] = time.split(":");

          if (period === "PM" && hours !== "12") {
            hours = parseInt(hours, 10) + 12;
          } else if (period === "AM" && hours === "12") {
            hours = "00";
          }

          return `${hours.toString().padStart(2, "0")}:${minutes}`;
        };

        adjustedShifts.forEach((shift, i) => {
          const [adjustedStartTime, adjustedEndTime] =
            shift.adjustedShift.split(" - ");
          const startTime = new Date(
            `1970-01-01T${formatTime(adjustedStartTime)}:00`
          );
          const endTime = new Date(
            `1970-01-01T${formatTime(adjustedEndTime)}:00`
          );
          const startInput = document.getElementById(`manual-start-${i}`);
          const endInput = document.getElementById(`manual-end-${i}`);
          function formatTimeForInput(date) {
            const hours = String(date.getHours()).padStart(2, "0");
            const minutes = String(date.getMinutes()).padStart(2, "0");
            return `${hours}:${minutes}`;
          }
          if (startInput && endInput) {
            startInput.value = formatTimeForInput(startTime);
            endInput.value = formatTimeForInput(endTime);
          }
        });
      }
    }

    if (assignedGeography?.id) {
      getExistingDST();
    }
  }, [assignedGeography]);
  return (
    <div className="d-flex daylight-container flex-column">
      <div className="indicator-container ">
        <div className="d-flex text">
          <FaClock className="icon mx-2" /> DST Configuration
        </div>
      </div>
      {showAlert ? (
        <div className="alert-container">
          <div className="upper-section">
            <p style={{ marginBottom: "0px", marginTop: "5px" }}>ALOIS</p>
            <span
              className="delete-btn"
              onClick={() => setShowAlert(!showAlert)}
            >
              <AiOutlineClose className="icon" style={{ fill: "black" }} />
            </span>
          </div>
          <hr
            style={{
              display: "block !important",
              height: "1px !important",
              border: "0 !important",
              borderTop: "1px solid gray !important",
            }}
          />
          <div className="lower-section py-2">
            <p className="text-danger">{errorNotific}</p>
            <p className="text-success">{successNotific}</p>
          </div>
        </div>
      ) : null}
      <div className="daylight-outer mx-auto" style={{ marginTop: "1rem" }}>
        <div className="daylight-inner mt-3">
          <div className="date-container ">
            <label className="form-label d-block">Geography</label>
            <select
              name="timings"
              id="assign-geography"
              className="form-select dropdown-styles w-25"
              onChange={(e) => {
                setAssignedGeography(JSON.parse(e.target.value));
              }}
              value={JSON.stringify(assignedGeography)}
              placeholder="Shift timings"
            >
              <option key="key" value="">
                Select a geography
              </option>
              {geographyList.map((data, index) => (
                <option key={index} value={JSON.stringify(data)}>
                  {data.geography}
                </option>
              ))}
            </select>
          </div>
          <div className="duration-container ">
            <div className="duration-items d-flex flex-column">
              <label className="form-label">DST Start Date</label>
              <DatePicker
                className="form-control"
                selected={selectedDstStartDate}
                onChange={setSelectedDstStartDate}
                placeholderText="Select a date"
                dateFormat="dd/MM/yyyy"
                type="date"
                id="startDate"
                // onChange={handleConfirmationChange}
              />
            </div>
            <div className="duration-items d-flex flex-column">
              <label className="form-label">DST End Date</label>
              <DatePicker
                type="date"
                id="dateInput"
                selected={selectedDstEndDate}
                onChange={setSelectedDstEndDate}
                placeholderText="Select a date"
                dateFormat="dd/MM/yyyy"
              />
            </div>
          </div>
          <div className="adjustment-container">
            <label className="form-label">Shift Adjustment</label>
            <div className="adjustment-options">
              <div className="adjustment-option">
                <input
                  type="radio"
                  name="shiftType"
                  value="automation"
                  checked={shiftType === "automation"}
                  onChange={handleOptionChange}
                />{" "}
                Automation
              </div>
              <div className="adjustment-option">
                <input
                  type="radio"
                  name="shiftType"
                  value="manual"
                  checked={shiftType === "manual"}
                  onChange={handleOptionChange}
                />{" "}
                Manual
              </div>
            </div>
            {shiftType === "automation" ? (
              <div className="table-responsive">
                <table className="table m-0">
                  <thead>
                    <tr>
                      <th key="default-shift-timings">Default Shift timings</th>
                      <th key="adjusted-shift-timing">
                        Adjusted Shift timings
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {assignedGeography ? (
                      assignedGeography.shiftTiming.map((item) => {
                        const startTime = new Date(
                          "01/01/2022 " + item.startTime
                        );
                        const endTime = new Date("01/01/2022 " + item.endTime);

                        // Add one hour to start time
                        startTime.setHours(startTime.getHours() + 1);

                        // Add one hour to end time
                        endTime.setHours(endTime.getHours() + 1);

                        // Format the times
                        const formattedStartTime = startTime.toLocaleTimeString(
                          "en-US",
                          { hour: "numeric", minute: "2-digit" }
                        );
                        const formattedEndTime = endTime.toLocaleTimeString(
                          "en-US",
                          { hour: "numeric", minute: "2-digit" }
                        );

                        // Combine formatted times into a single string
                        const formattedTimeString = `${formattedStartTime} - ${formattedEndTime}`;
                        return (
                          <tr>
                            <td>{item.startTime + " - " + item.endTime}</td>
                            <td>{formattedTimeString}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="table-responsive">
                <table className="table m-0">
                  <thead>
                    <tr>
                      <th key="default-shift-timings">Default Shift timings</th>
                      <th key="adjusted-shift-timing">
                        Adjusted Shift timings
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {assignedGeography ? (
                      assignedGeography.shiftTiming.map((item, i) => {
                        return (
                          <tr>
                            <td>{item.startTime + " - " + item.endTime}</td>
                            <td>
                              {" "}
                              <input
                                id={`manual-start-${i}`}
                                type="time"
                                required
                              />
                              -
                              <input
                                id={`manual-end-${i}`}
                                type="time"
                                required
                              />
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              </div>
            )}
            <GradientBorderButton
              additionalClass="grad-btn"
              clickHandler={handleSubmit}
              text="Submit"
            />
          </div>
          {/* <div className="notification-container" style={{ color: isDSTEnabled ? '#000' : '#678498',}}>
                    <div className="notification-switch mt-2">
                        <label className="heading">Notification</label>
                        <Switch
                            isOn={isDSTEnabled}
                            handleToggle={handleDSTToggle}
                            colorOne="#FF914E"
                            colorTwo="#D1D1D6"
                        />
                    </div>
                    <div className="notification-type" style={{ border:isDSTEnabled? "1px solid #000":"1px solid #B5D3D1" }}>
                        <div className="notification-title">Select the Type Of Notifications for User</div>
                            <div className="notification-input">
                                <input
                                    type="checkbox"
                                    id="portalNotification"
                                    className="notification-checkbox"
                                    checked={portalNotification}
                                    onChange={handlePortalToggle}
                                    disabled={!isDSTEnabled}
                                />
                                <label htmlFor="portalNotification" className="form-label">Portal</label>
                            </div>
                            <div className="notification-input">
                                <input
                                    type="checkbox"
                                    id="emailNotification"
                                    className="notification-checkbox"
                                    checked={emailNotification}
                                    onChange={handleEmailToggle}
                                    disabled={!isDSTEnabled}
                                />
                                <label htmlFor="emailNotification" className="form-label">Email</label>
                            </div>
                    </div>
                </div> */}
        </div>
      </div>
    </div>
  );
}

export default DayLightSaving;
