import React, {
  forwardRef,
  useImperativeHandle,
  useEffect,
  useState,
  useRef,
} from "react";
import axios from "../../config/axios";
import * as FaIcons from "react-icons/fa";
import GradientBorderButton from "../../components/Widgets/GradientBorderButton";
import { FiUpload } from "react-icons/fi";
import { useDOMStatesContext } from "../../context/DOMStatesContext";
import { AiOutlineDelete } from "react-icons/ai";
import FormData from "form-data";
import ImageUtil from "../../utilities/ImageUtil";
import TokenService from "../../services/token.service";
// import axios from "../config/axios";
import alt_axios from "axios";
import { FaGreaterThan, FaLessThan } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import socialTemplateBankService from "../../services/socialTemplateBank.service";
import licenceValidator from "../../permission/validate";
import GradientBorderDropDown from "../../components/Widgets/GradientBorderDropdown";

const ImageSelector = forwardRef((props, ref) => {
  const depRef = useRef();
  const imgRef = useRef();
  const [Images, setImages] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(5);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(0);
  const visiblePages = 5;
  const [successNotific, setSuccessNotific] = useState("");
  const [errorNotific, setErrorNotific] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [inputLimitError, setInputLimitError] = useState("");
  const [startIndex, setStartIndex] = useState(0);
  const [SmaSuccess, setSmaSuccess] = useState("");
  const [SmaErr, setSmaErr] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [isdeletable, setIsDeletable] = useState(false);
  const { showUploadForm, toggleUploadForm } = useDOMStatesContext();
  const [image, setImage] = useState(Images[1]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState("select");
  let user = JSON.parse(localStorage.getItem("user"));
  let options = [
    { value: "select", name: "Select a department" },
    { value: "Healthcare", name: "Healthcare" },
    { value: "Delivery", name: "Delivery" },
  ];
  useEffect(() => {
    let user = localStorage.getItem("user");
    let validator = new licenceValidator(user, "template_bank");
    // setIsVisible(validator.isReadable("image"));
    setIsDeletable(validator.isDelete("image"));
    setImages([]);
    setIsVisible(
      (prevValue) => {
        // Perform any calculations or modifications based on the previous value
        const newValue = validator.isReadable("image");
        return newValue;
      },
      () => {
        // This callback will be executed after the state is updated
        // Call the function that relies on the updated state
        call(1);
      }
    );
    // call(1);
    firstImg();
  }, []);

  const call = async (pageNo, value) => {
    let department = "select";

    if (!isVisible) {
      if (user.email.includes("healthcare")) {
        department = "Healthcare";
      } else if (user.email.includes("aloissolutions")) {
        department = "Delivery";
      }
    } else {
      department = value;
    }

    const res = await socialTemplateBankService.getTemplates(
      pageNo,
      department
    );
    console.log(user);
    const results = res.data.results;
    let tempTotalPages = Math.max(1, Math.ceil(res.data.totalResults / 10));
    setTotalPages(tempTotalPages);
    let tempPageArr = [];
    for (let i = 1; i <= tempTotalPages; i++) {
      tempPageArr.push(i);
    }
    setPageNumbers(tempPageArr);
    setCurrentPage(pageNo);
    // adjustPages(pageNo);
    let results1 = results.filter;
    setImages(results);
    setSelectedImage(results[0]);
  };
  // department change graient button
  const handleOptionSelect = (value) => {
    setSelectedDepartment(value);
    call(1, value);
  };
  const getNewTemplateList = async (pageNo) => {
    let department = "select";
    if (!isVisible) {
      if (user.email.includes("healthcare")) {
        department = "Healthcare";
      } else if (user.email.includes("aloissolutions")) {
        department = "Delivery";
      }
    }
    const res = await socialTemplateBankService.getTemplates(
      pageNo,
      department
    );
    const results = res.data.results;

    setCurrentPage(pageNo);
    setImages(results);
    setSelectedImage(results[0]);
  };

  const deleteTemplate = async (data) => {
    const response = await alt_axios.post(
      `https://${process.env.REACT_APP_API_URL}/linkedin/RemoveSMTemplate`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
      }
    );
    call(currentPage, selectedDepartment);
    return response.data;
  };

  const handleTemplateDelete = async () => {
    // Ask for confirmation before proceeding with the deletion
    const shouldDelete = window.confirm(
      "Are you sure you want to delete this template?"
    );

    if (shouldDelete) {
      const formData = new FormData();
      formData.append("id", selectedImage.id);
      try {
        const result = await deleteTemplate(formData);
        const updatedImages = Images.filter(
          (item) => item.id !== selectedImage.id
        );
        setImages(updatedImages);
        setErrorNotific("");
        setSuccessNotific("Deleted Successfully");
      } catch (error) {
        setSuccessNotific("");
        setErrorNotific("Deleting Template failed");
      }
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    } else {
      // The user canceled the deletion, do nothing or handle it as needed
    }
  };
  const firstImg = () => {
    setImage(Images[0]);
  };

  const loadImg = () => {
    //  console.log("df");
  };

  useImperativeHandle(ref, () => ({
    showAlert() {
      loadImg();
    },
  }));

  const handlePrev = () => {
    const newIndex = startIndex - 4;
    setStartIndex(newIndex < 0 ? Images.length - 4 : newIndex);
  };

  const handleNext = () => {
    const newIndex = startIndex + 4;
    setStartIndex(newIndex >= Images.length ? 0 : newIndex);
  };

  const selectImage = (image) => {
    setImage(image);
    setSelectedImage(image);
  };

  const getNewImages = async (pageNo) => {
    const result = await deleteTemplate(pageNo);
  };
  const adjustPages = (currentPage) => {
    let tempStart = 1;
    let tempEnd = Math.min(totalPages, 5);
    if (currentPage > 3) {
      tempStart = Math.max(1, currentPage - Math.floor(visiblePages / 2));
      tempEnd = Math.min(tempStart + visiblePages - 1, totalPages);
      setStartPage(tempStart);
      setEndPage(tempEnd);
    }
    let tempPageNumbers = Array.from(
      { length: tempEnd - tempStart + 1 },
      (_, index) => tempStart + index
    );
    if (tempPageNumbers[0] !== 0) {
      setPageNumbers(tempPageNumbers);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      call(currentPage - 1, selectedDepartment);
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      call(currentPage + 1, selectedDepartment);
      setCurrentPage(currentPage + 1);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (imgRef.current.value === "") {
      imgRef.current.classList.add("is-invalid");
    } else {
      imgRef.current.classList.remove("is-invalid");

      if (inputLimitError.length === 0) {
        for (let i = 0; i < imgRef.current.files.length; ++i) {
          const formData = new FormData();
          formData.append("mems", imgRef.current.files[i]);
          formData.append("department", depRef.current.value);
          // console.log(imgRef.current.files);
          console.log("called");
          try {
            const res = await axios.post(
              "/linkedin/uploadTemplates",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );

            setErrorNotific("");
            setSuccessNotific("Uploaded Successfully");
          } catch (err) {
            setErrorNotific("Upload Failed");
            setSuccessNotific("");
            break;
          }
        }
        call(currentPage, selectedDepartment);
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
        toggleUploadForm();
        setInputLimitError("");
      }
    }
  };

  const handleInputChange = () => {
    if (imgRef.current.files.length > 10) {
      setInputLimitError("maximum 10 files can be uploaded at once.");
    } else setInputLimitError("");
  };
  useEffect(() => {
    let dashboardElement = document.querySelector(".dashboard");
    const body = document.body;

    if (dashboardElement && showUploadForm) {
      // Remove the 'transform' property
      // Remove the 'transform-origin' property
      // dashboardElement.style.transformOrigin = '';
    } else if (!showUploadForm && dashboardElement) {
    }
  }, [showUploadForm]);
  return (
    <div className="social-temp-bank ">
      {!showUploadForm ? (
        <div className="indicator-container pt-1 pb-3">
          <div className="d-flex text">
            <img
              src="/assets/social_template_bank_icon.svg"
              alt="image"
              className="icon mx-2"
            />{" "}
            Social Template Bank
          </div>
          <>
            <div className="d-flex align-items-center gap-3">
              {isVisible ? (
                <GradientBorderButton
                  text={"Upload"}
                  icon={<FiUpload />}
                  clickHandler={() => {
                    toggleUploadForm();
                    setInputLimitError("");
                  }}
                  additionalClass={"template-upload-btn"}
                  iconAdditionalClass={"icon-styles"}
                />
              ) : null}
              {isdeletable ? (
                <GradientBorderButton
                  text={"Delete"}
                  icon={<AiOutlineDelete />}
                  clickHandler={handleTemplateDelete}
                  additionalClass={"template-delete-btn mx-1"}
                  iconAdditionalClass={"icon-styles"}
                />
              ) : null}
              {isVisible ? (
                <GradientBorderDropDown
                  additionalClass={"template-delete-btn"}
                  changeHandler={handleOptionSelect}
                  options={options}
                />
              ) : (
                <></>
              )}
            </div>
          </>
        </div>
      ) : (
        <></>
      )}

      {!showUploadForm ? (
        <div className="d-flex " style={{ marginTop: "5.5rem" }}>
          <div className="gradient-card mx-auto " style={{ minHeight: "75vh" }}>
            <div className="card-bg mt-3">
              {selectedImage && (
                <div
                  className="d-flex justify-content-center mx-auto"
                  style={{ minHeight: "100%" }}
                >
                  <div className="template-bg d-flex justify-content-center p-3">
                    <img src={selectedImage.filePath} alt="" />
                  </div>
                </div>
              )}
              <div className="mt-3">
                <div className="carousel-item d-flex overflow-auto my-2 border border-secondary rounded px-2 py-2">
                  {isVisible && selectedDepartment == "select" ? (
                    <p>
                      Explore our social media templates! Choose a department to
                      proceed.
                    </p>
                  ) : (
                    <></>
                  )}
                  {Images.map((image, index) => (
                    <div key={index}>
                      <img
                        className="mx-2"
                        onClick={() => {
                          selectImage(image);
                        }}
                        style={{ width: "200px" }}
                        src={image.filePath}
                        alt={`Image ${index + 1}`}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {!showUploadForm ? (
        <div className="pagination py-4">
          <button
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            className={currentPage === 1 ? "active" : ""}
          >
            <FaLessThan
              className="arrow"
              style={{
                marginBottom: "4px",
                marginRight: "2px",
                padding: "1px",
              }}
            />
          </button>
          {pageNumbers.map((page) => (
            <button
              key={page}
              disabled={currentPage === page}
              className={currentPage === page ? "active" : ""}
              onClick={() => {
                call(page, selectedDepartment);
                adjustPages(page);
              }}
            >
              {page}
            </button>
          ))}
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className={currentPage === totalPages ? "active" : ""}
          >
            <FaGreaterThan
              className="arrow"
              style={{ marginBottom: "4px", marginLeft: "2px", padding: "1px" }}
            />
          </button>
        </div>
      ) : (
        <></>
      )}
      {showUploadForm ? (
        <div className="popup-container">
          <div className="popup">
            <div className="popup-header">
              <span className="fs-6 fw-bold">Upload New Images</span>
              <button className="close-button" onClick={toggleUploadForm}>
                &#10005;
              </button>
            </div>
            <hr style={{ borderTop: "3px dotted black" }} />
            <div className="popup-content">
              <form encType="multipart/form-data">
                <div className="form-fields-container">
                  <div className="row pt-3 " xl="12">
                    <div className="form-outline ">
                      <label className="form-label" htmlFor="department">
                        Department
                      </label>
                      <select
                        name="department"
                        className="form-select form-control "
                        ref={depRef}
                      >
                        <option value="Healthcare">ALOIS Healthcare</option>
                        <option value="Delivery">ALOIS IT Recruiters</option>
                      </select>
                      <div className="arrow-down"></div>
                    </div>
                  </div>
                  <div className="row mt-3 pb-3">
                    <div className="col">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="chooseImage">
                          Choose Image
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          ref={imgRef}
                          onChange={handleInputChange}
                          accept=".png,.jpeg,.jpg"
                          multiple
                        />
                        <span
                          className="text-danger"
                          style={{ position: "relative", bottom: "-.5rem" }}
                        >
                          {inputLimitError}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="upload-btn-container mt-2">
                  <GradientBorderButton
                    text="Upload"
                    clickHandler={handleSubmit}
                  />
                </div>
              </form>
            </div>
          </div>
          <div className="toasts-container position-absolute top-0 mt-3 mx-3">
            <div
              className="toast fade hide"
              data-autohide="true"
              id="toast-img-selector"
            >
              <div className="toast-header">
                <i className="far fa-bell text-muted me-2"></i>
                <strong className="me-auto">ALOIS</strong>
                <button
                  type="button"
                  id="toastclose_btn"
                  className="btn-close"
                  data-bs-dismiss="toast"
                ></button>
              </div>
              <div className="toast-body">
                <p className="text-success">{SmaSuccess}</p>
                <p className="text-danger">{SmaErr}</p>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {showAlert ? (
        <div className="alert-container">
          <div className="upper-section">
            <p>ALOIS</p>
            <span
              className="delete-btn"
              onClick={() => setShowAlert(!showAlert)}
            >
              <AiOutlineClose className="icon" />
            </span>
          </div>
          <hr />
          <div className="lower-section pt-2">
            <p className="text-danger">{errorNotific}</p>
            <p className="text-success">{successNotific}</p>
          </div>
        </div>
      ) : null}
    </div>
  );
});

export default ImageSelector;

{
  /* <div>
<div className="d-flex overflow-auto my-2 border border-secondary rounded px-2 py-2">
  {Images.map((i, index) => {
    return (
      <div>
        <img
          className="mx-2 border border-secondary rounded"
          onClick={(e) => {
            selectImage(e);
          }}
          style={{ width: "150px" }}
          src={i}
          alt={index}
        />
      </div>
    );
  })}
</div>
</div> */
}

{
  /* <div className="carousel-inner mx-auto w-100 px-5 m-0">
                <div className="carousel-item active">
                  <div className="d-flex">
                    {Images.slice(startIndex, startIndex + 4).map(
                      (image, index) => (
                        <div key={index}>
                          <img
                            className="mx-2"
                            onClick={() => {
                              selectImage(image);
                            }}
                            style={{ width: "200px" }}
                            src={ImageUtil.transform(image.filePath)}
                            alt={`Image ${index + 1}`}
                          />
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div> */
}

// <button
//   className="carousel-control-prev"
//   type="button"
//   data-bs-target="#carouselExampleControls"
//   data-bs-slide="prev"
//   onClick={handlePrev}
// >
//   <p style={{ color: "black" }}>
//     <FaIcons.FaArrowLeft />
//   </p>
//   <span className="visually-hidden">Previous</span>
// </button>
// <button
//   className="carousel-control-next"
//   type="button"
//   data-bs-target="#carouselExampleControls"
//   data-bs-slide="next"
//   onClick={handleNext}
// >
//   <p style={{ color: "black" }}>
//     <FaIcons.FaArrowRight />
//   </p>
//   <span className="visually-hidden">Next</span>
// </button>

//   <div
//   id="carouselExampleControls"
//   className="carousel w-100 d-flex justify-content-between"
//   data-bs-interval="false" // Disable automatic sliding
// >

// </div>
