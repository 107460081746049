import React, { useRef, useEffect, useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import ChartPluginDatalabels from "chartjs-plugin-datalabels";
import ClanCard from "../Widgets/ClanCard";
import clanMasterService from "../../services/clanMaster.service.js";
import "chart.js/auto";

function AdminClanGraph() {
  // cumalative points of each clan
  const [allClanPoints, setAllClanPoints] = useState({
    Knights: 0,
    Gladiators: 0,
    Immortals: 0,
    Samurai: 0,
  });
  // month wise points of each clan.
  const [allClanPointsList, setAllClanPointsList] = useState({
    Knights: 0,
    Gladiators: 0,
    Immortals: 0,
    Samurai: 0,
  });

  const [selectedClan, setSelectedClan] = useState({
    clanName: "Knights",
    points: [],
    color: "#175572",
  });
  const [positionsArray, setPositionsArray] = useState([1, 2, 3, 4]);

  const getTotalScore = (arr) => {
    const total = arr.results.reduce((total, item) => total + item.score, 0);
    return total;
  };

  const extractPointList = (arr) => {
    const list = [];
    arr.results.map((item) => {
      list.push(item.score);
    });
    return list;
  };

  const clanCardClick = (clanName, color) => {
    switch (clanName) {
      case "Knights":
        setSelectedClan({
          clanName: "Knights",
          points: allClanPointsList.Knights,
          color,
        });
        break;
      case "Immortals":
        setSelectedClan({
          clanName: "Immortals",
          points: allClanPointsList.Immortals,
          color,
        });
        break;
      case "Gladiators":
        setSelectedClan({
          clanName: "Gladiators",
          points: allClanPointsList.Gladiators,
          color,
        });
        break;
      case "Samurai":
        setSelectedClan({
          clanName: "Samurai",
          points: allClanPointsList.Samurai,
          color,
        });
        break;
    }
  };

  const getAllClanPoints = async () => {
    try {
      const knights = await clanMasterService.getClanPoints("Knights");
      const gladiators = await clanMasterService.getClanPoints("Gladiators");
      const immortals = await clanMasterService.getClanPoints("Immortals");
      const samurai = await clanMasterService.getClanPoints("Samurai");

      // console.log(knights);
      // // total score for each clan.

      const knightsScore = getTotalScore(knights);
      const gladiatorsScore = getTotalScore(gladiators);
      const immortalsScore = getTotalScore(immortals);
      const samuraiScore = getTotalScore(samurai);

      const knightsList = extractPointList(knights);
      const gladiatorsList = extractPointList(gladiators);
      const immortalsList = extractPointList(immortals);
      const samuraiList = extractPointList(samurai);

      const pointsArray = [
        knightsScore,
        gladiatorsScore,
        immortalsScore,
        samuraiScore,
      ];
      const clonedArr = [...pointsArray];

      const sortedArr = clonedArr.sort((a, b) => b - a);

      const positions = pointsArray.map((num) => sortedArr.indexOf(num) + 1);

      setPositionsArray(positions);
      // setting state for clan score.
      setAllClanPoints((prev) => {
        return {
          ...prev,
          Knights: knightsScore,
          Gladiators: gladiatorsScore,
          Immortals: immortalsScore,
          Samurai: samuraiScore,
        };
      });

      // yield the clan info with maximum points.
      const maxScore = Math.max(...pointsArray);

      const clanNames = ["Knights", "Gladiators", "Immortals", "Samurai"];
      const clanPointsList = [
        knightsList,
        gladiatorsList,
        immortalsList,
        samuraiList,
      ];
      const colorList = ["#175572", "orange", "black", "red"];
      const maxScoreIndex = pointsArray.indexOf(maxScore);

      setSelectedClan({
        clanName: clanNames[maxScoreIndex],
        points: clanPointsList[maxScoreIndex],
        color: colorList[maxScoreIndex],
      });

      setAllClanPointsList((prev) => {
        return {
          ...prev,
          Knights: knightsList,
          Gladiators: gladiatorsList,
          Immortals: immortalsList,
          Samurai: samuraiList,
        };
      });
      // if (chartRef.current) {
      //   chartRef.current.chartInstance.update();
      // }
    } catch (error) {
      // console.log(error);
    }
  };
  useEffect(() => {
    getAllClanPoints();
  }, []);

  const BarGraph = () => {
    const chartRef = useRef(null);

    const data = {
      labels: ["Immortals", "Knights", "Gladiators", "Samurai"],
      datasets: [
        {
          label: "Points",
          data: [
            allClanPoints.Immortals,
            allClanPoints.Knights,
            allClanPoints.Gladiators,
            allClanPoints.Samurai,
          ],
          backgroundColor: ["#2d3436", "#175572", "#ff9f1a", "#ff3838"],
          borderWidth: 1,
          barThickness: 40,
          logos: [
            "/assets/Gladiators.png",
            "/assets/Knights.png",
            "/assets/Gladiators.png",
            "/assets/Samurai.png",
          ],
        },
      ],
    };

    const options = {
      scales: {
        y: {
          beginAtZero: true,
          max:
            Math.max(
              allClanPoints.Immortals,
              allClanPoints.Knights,
              allClanPoints.Gladiators,
              allClanPoints.Samurai
            ) + 2500,
          display: false,
          grid: {
            display: false,
          },
        },
        x: {
          grid: {
            display: false,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          display: true,
          anchor: "end",
          align: "top",
          color: "#000",
          font: {
            weight: "bold",
          },
          formatter: function (value) {
            return value.toString();
          },
          display: function (context) {
            return context.dataset.data[context.dataIndex] > 0;
          },
          clamp: true,
          listeners: {
            render: function (context) {
              const { ctx, dataset, dataIndex, chart } = context;
              const value = dataset.data[dataIndex];
              const x = context.x;
              const y = context.y;
              const logoUrl = dataset.logos[dataIndex];

              const logoImage = new Image();
              logoImage.src = logoUrl;
              logoImage.onload = function () {
                const logoSize = 20;
                const logoX = x - logoSize / 2;
                const logoY = y - 45;

                ctx.drawImage(logoImage, logoX, logoY, logoSize, logoSize);

                ctx.textAlign = "center";
                ctx.font = "bold 12px Arial";
                ctx.fillStyle = "#000";
                ctx.fillText(value.toString(), x, y - 30);

                chart.update();
              };
            },
          },
        },
      },
    };

    const plugins = [ChartPluginDatalabels];

    return (
      <Bar data={data} options={options} plugins={plugins} ref={chartRef} />
    );
  };

  // component for line chart

  const LineChart = () => {
    const data = {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: [
        {
          label: selectedClan.clanName,
          data: selectedClan.points,
          fill: false,
          borderColor: selectedClan.color,
          backgroundColor: selectedClan.color,
        },
      ],
    };

    const options = {
      scales: {
        y: {
          beginAtZero: true,
          max: Math.max(...selectedClan.points) + 500,
          grid: {
            display: false,
          },
          display: true,
        },
        x: {
          grid: {
            display: false,
          },
        },
      },
      plugins: {
        datalabels: {
          display: true,
          anchor: "end",
          align: "top",
          color: "#000",
          font: {
            weight: "bold",
          },
          formatter: function (value) {
            return value.toString();
          },
          display: function (context) {
            return context.dataset.data[context.dataIndex] > 0;
          },
          clamp: true,
        },
      },
      elements: {
        point: {
          backgroundColor: selectedClan.color, // Set the color of the data points
          borderWidth: 2, // Set the border width of the data points
          borderColor: "#fff", // Set the border color of the data points
        },
      },
    };
    const plugins = [ChartPluginDatalabels];
    return <Line data={data} options={options} plugins={plugins} />;
  };

  return (
    <div className="d-flex flex-column" style={{ width: "100%" }}>
      <div
        className="d-flex row graphs"
        style={{ width: "100%", gap: ".5rem" }}
      >
        <div className="clan-graph-container col-6">
          <LineChart />
        </div>
        <div className="cards">
        

          <div className="clan-upper row text-center" >
            <div
              className="clan-card col-3"
              id="immortals"
              onClick={() => {
                clanCardClick("Immortals", "black");
              }}
            >
              <div className="clan-img text-center">
              <img src="/assets/Immortals.png" alt="" />
              </div>
              <div className="clan-text col">
                <div className="header">{allClanPoints.Immortals}</div>
              
                <div className="subheader">
                  Points
                </div>
              </div>
            
            </div>
            <div
              className="clan-card col-3"
              id="knights"
              onClick={() => {
                clanCardClick("Knights", "#175572");
              }}
            >
              <div className="clan-img text-center">
                <img src="/assets/Knights.png" alt="" />
              </div>
              <div className="clan-text col">
                <div className="header">{allClanPoints.Knights}</div>
              
                <div className="subheader">
                  Points
                </div>
              </div>
            
            </div>
            <div
              className="clan-card col-3"
              id="gladiators"
              onClick={() => {
                clanCardClick("Gladiators", "orange");
              }}
            >
              <div className="clan-img text-center">
              <img src="/assets/Gladiators.png" alt="" />
              </div>
              <div className="clan-text col">
                <div className="header">{allClanPoints.Gladiators}</div>
              
                <div className="subheader">
                  Points
                </div>
              </div>
            
            </div>
            <div
              className="clan-card col-3"
              id="samurai"
              onClick={() => {
                clanCardClick("Samurai", "red");
              }}
            >
              <div className="clan-img text-center">
              <img src="/assets/Samurai.png" alt="" />
              </div>
              <div className="clan-text col">
                <div className="header">{allClanPoints.Samurai}</div>
              
                <div className="subheader">
                  Points
                </div>
              </div>
            
            </div>
          </div>

          <div
            className="lower mt-3"
            style={{ display: "flex", gap: "10px" }}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default AdminClanGraph;
