import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import Papa from "papaparse";
import { AiOutlineClose } from "react-icons/ai";
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaAngleLeft,
  FaAngleRight,
  FaEdit,
  FaTrashAlt,
  FaUserAlt,
  FaSearch,
  FaRegCalendarAlt,
} from "react-icons/fa";
import { FiDownload } from "react-icons/fi";
import { HiMiniAdjustmentsVertical } from "react-icons/hi2";
import licenceValidator from "../../permission/validate";
import "react-tooltip/dist/react-tooltip.css";
import userRegistrationService from "../../services/userRegistration.service";
import scheduledUsersService from "../../services/scheduledUsers.service";
import UserRegistrationPopup from "./UserRegistrationPopup";
import GradientBorderButton from "../Widgets/GradientBorderButton";
import masterDBService from "../../services/masterDB.service";

function ManageUser() {
  const visiblePages = 5;
  const usersPerPage = 10;
  const fixedColumns = ["First Name", "Last Name"];
  const dynamicColumns = [
    "Employee ID",
    "Department",
    "Shift",
    "Email",
    "Shift Timing",
  ];
  const defaultColumns = ["Department", "Shift Timing"];
  const columnKeyMapping = {
    "First Name": "firstName",
    "Last Name": "lastName",
    "Employee ID": "empId",
    Department: "department",
    Shift: "shift",
    Email: "email",
    "Shift Timing": "shiftTiming",
    DOB: "dateOfBirth",
    DOJ: "dateOfJoining",
  };

  const [userList, setUserList] = useState([]);
  const [successNotific, setSuccessNotific] = useState("");
  const [errorNotific, setErrorNotific] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [initialFilteredUserList, setInitialFilteredUserList] = useState([]);
  const [filteredUserList, setFilteredUserList] = useState([]);
  const [unpagedFilteredUserList, setUnpagedFilteredUserList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [departments, setDepartments] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [shiftTimings, setShiftTimings] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const [isdeletable, setIsDeletable] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [schedulePopup, setSchedulePopup] = useState(false);
  const [schedule, setSchedule] = useState();
  const [usersCountByDepartment, setUsersCountByDepartment] = useState({});
  const [dynamicTableColumns, setDynamicTableColumns] =
    useState(defaultColumns);
  const [showDynamicDropdown, setShowDynamicDropdown] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filteredShiftTimings, setFilteredShiftTimings] = useState([]);
  const [scheduledDeleteUsers, setScheduledDeleteUsers] = useState([]);
  const [searchInputFocused, setSearchInputFocused] = useState(false);
  const departmentRef = useRef(null);
  const shiftTimingRef = useRef(null);
  const shiftRef = useRef(null);
  const timeRef = useRef(null);
  const dateRef = useRef(null);
  const userRef = useRef(null);
  const dynamicDropdownRef = useRef(null);
  const initialFilteredUserListCalled = useRef(false);
  const tableRef = useRef(null);

  const handleDeleteSchedule = async (e) => {
    e.preventDefault();

    const confirmation = window.confirm(
      "Are you sure you want to delete this schedule?"
    );

    if (!confirmation) {
      return;
    }

    try {
      await scheduledUsersService.deleteSchedule(selectedUserId);

      getSchedules();

      setShowAlert(true);
      setSuccessNotific("Schedule deleted successfully!");
      setErrorNotific("");
      setTimeout(() => {
        setShowAlert(false);
      }, 2000);
    } catch (err) {
      setShowAlert(true);
      setSuccessNotific("");
      setErrorNotific("Error in deleting schedule!");
      setTimeout(() => {
        setShowAlert(false);
      }, 2000);
    }

    setSchedulePopup(false);
  };

  const dropdown = async () => {
    try {
      const data = await masterDBService.getAllDepartments();
      setDepartments(data.results);

      const results = await masterDBService.getAllGeographies();
      setShifts(results.results);
      const result1 = await masterDBService.getAllShiftTimings();
      setShiftTimings(result1.results);
    } catch (error) {
      console.error("Error Fetching data ", error);
    }
  };

  const handleDeparmentChange = async (e) => {
    const { value } = e.target;

    if (value === "default") {
      const uniqueShifts = [...new Set(userList.map((item) => item.shift))];
      const result1 = await masterDBService.getAllShiftTimings();
      const results = await masterDBService.getAllGeographies();

      setShifts(results.results);

      shiftRef.current.value = "default";

      setShiftTimings(result1.results);
      shiftTimingRef.current.value = "default";
    }

    if (departments.find((item) => item.id == value)) {
      let shiftsArray = [];

      shiftsArray =
        departments.find((department) => department.id === value)?.geography ||
        [];

      setShifts(shiftsArray);
      shiftRef.current.value = "default";

      let shiftTimingsArray = [];
      const allShiftTimings = shiftsArray.flatMap((obj) => obj.shiftTiming);
      shiftTimingsArray = allShiftTimings;
      setShiftTimings(shiftTimingsArray);
      let filteredShifts = departments.flatMap((obj) => obj.geography);
      let filterShiftTiming = filteredShifts.flatMap((obj) => obj.shiftTiming);
      const uniqueShiftTimings = [...new Set(filterShiftTiming)];

      setFilteredShiftTimings(uniqueShiftTimings);
      shiftTimingRef.current.value = "default";
    }

    initialFilteredUserListCalled.current = false;
    filterUsers();
  };

  const handleRegionChange = async (e) => {
    const { value } = e.target;

    if (value == "default") {
      setShiftTimings(filteredShiftTimings);
    } else {
      let shiftTimingsArray = [];
      shiftTimingsArray =
        shifts.find((shift) => shift.id === value)?.shiftTiming || [];

      const uniqueShiftTimings = [...new Set(shiftTimingsArray)];
      setShiftTimings(uniqueShiftTimings);
    }

    shiftTimingRef.current.value = "default";
    filterUsers();
  };

  const filterUsers = (data) => {
    setSelectedRows([]);

    let updatedUserList = data || [...userList];

    if (userRef.current?.value) {
      const searchTerm = userRef.current.value
        .toLowerCase()
        .replace(/\s+/g, " ")
        .trim();

      // Use case: If user tries to search with full name
      if (searchTerm.includes(" ")) {
        const [firstName, lastName] = searchTerm.split(" ");

        if (searchTerm.split(" ").length <= 2) {
          updatedUserList = updatedUserList.filter(
            (item) =>
              ((item.firstName &&
                item.firstName.toLowerCase().includes(firstName)) ||
                (item.lastName &&
                  item.lastName.toLowerCase().includes(lastName))) &&
              item.firstName &&
              item.firstName.toLowerCase().includes(firstName) &&
              item.lastName &&
              item.lastName.toLowerCase().includes(lastName)
          );
        } else {
          updatedUserList = [];
        }
      } else {
        updatedUserList = updatedUserList.filter(
          (item) =>
            (item.firstName &&
              item.firstName.toLowerCase().includes(searchTerm)) ||
            (item.lastName &&
              item.lastName.toLowerCase().includes(searchTerm)) ||
            (item.email && item.email.toLowerCase().includes(searchTerm))
        );
      }
    }

    if (departmentRef.current?.value !== "default") {
      updatedUserList = updatedUserList.filter(
        (item) =>
          item.department &&
          item.department.toLowerCase() ==
            departmentRef.current.value.toLowerCase()
      );
    }

    if (shiftRef.current?.value !== "default") {
      updatedUserList = updatedUserList.filter(
        (item) =>
          item.shift &&
          item.shift.toLowerCase() == shiftRef.current.value.toLowerCase()
      );
    }

    if (shiftTimingRef.current?.value !== "default") {
      updatedUserList = updatedUserList.filter(
        (item) =>
          item.shiftTiming &&
          item.shiftTiming.toLowerCase() ==
            shiftTimingRef.current.value.toLowerCase()
      );
    }

    setFilteredUserList(updatedUserList);
    setUnpagedFilteredUserList(updatedUserList);

    // We need user list according to the department we have selected and independent of shift change.
    if (!initialFilteredUserListCalled.current) {
      setInitialFilteredUserList(updatedUserList);
      initialFilteredUserListCalled.current = true;
    }

    const tempTotalPages = Math.max(
      1,
      Math.ceil(updatedUserList.length / usersPerPage)
    );
    setTotalPages(tempTotalPages);

    setCurrentPage(1);
    paginate(1, updatedUserList);
    adjustPages(1, tempTotalPages);
  };

  const paginate = (currPage, data = []) => {
    // Scroll to the top of the table whenever page number is changed
    if (tableRef.current) {
      tableRef.current.scrollTop = 0;
    }

    const start = (currPage - 1) * usersPerPage;
    const end = start + usersPerPage;

    if (data.length > 0) {
      setFilteredUserList(data.slice(start, end));
    } else {
      !userRef.current.value &&
        !shiftRef.current.value &&
        setFilteredUserList(userList.slice(start, end));
    }
  };

  const getUsers = async (pageNo) => {
    const data = await userRegistrationService.getUsers();
    const tempTotalPages = Math.max(
      1,
      Math.ceil(data.totalResults / usersPerPage)
    );
    setTotalPages(tempTotalPages);

    setCurrentPage(pageNo);
    paginate(pageNo, data.results);

    setUserList(data.results);
    setInitialFilteredUserList(data.results);
    setUnpagedFilteredUserList(data.results);

    initialFilteredUserListCalled.current = false;
    filterUsers(data.results);
  };

  const handleDelete = async (userData) => {
    if (selectedRows.length === 0) {
      setShowAlert(true);
      setSuccessNotific("");
      setErrorNotific("No user is selected to delete");
      setTimeout(() => {
        setShowAlert(false);
      }, [2000]);
    } else {
      const userIdsArray = userData.map((item) => item.id);
      const shouldDelete = window.confirm(
        "Are you sure you want to delete this user?"
      );

      if (shouldDelete) {
        try {
          setSelectedRows([]);

          await userRegistrationService.deleteMultipleUsers({
            userIdsArray,
          });

          getUsers(currentPage);

          setShowAlert(true);
          setSuccessNotific(
            `User${selectedRows.length > 1 ? "s" : ""} deleted successfully!`
          );
          setErrorNotific("");
          setTimeout(() => {
            setShowAlert(false);
          }, [2000]);
        } catch (error) {
          setShowAlert(true);
          setSuccessNotific("");
          setErrorNotific(
            `Error in deleting user${selectedRows.length > 1 ? "s" : ""}!`
          );
          setTimeout(() => {
            setShowAlert(false);
          }, 2000);
        }
      }
    }
  };

  const adjustPages = (currentPage, tempTotalPages) => {
    let finalTotalPages = tempTotalPages || totalPages;
    let tempStart = 1;
    let tempEnd = Math.min(5, finalTotalPages);

    if (finalTotalPages > 5) {
      if (currentPage > 3 && currentPage < finalTotalPages - 1) {
        tempStart = Math.max(1, currentPage - Math.floor(visiblePages / 2));
        tempEnd = Math.min(tempStart + visiblePages - 1, finalTotalPages);
      } else if (currentPage >= finalTotalPages - 1) {
        // If it is the last or last second page, then show the last 5 page numbers
        tempStart = Math.max(1, finalTotalPages - visiblePages + 1);
        tempEnd = finalTotalPages;
      }
    } else {
      tempStart = 1;
      tempEnd = finalTotalPages;
    }

    let tempPageNumbers = Array.from(
      { length: tempEnd - tempStart + 1 },
      (_, index) => tempStart + index
    );

    if (tempPageNumbers[0] !== 0) {
      setPageNumbers(tempPageNumbers);
    }

    setCurrentPage(currentPage);
  };

  const getSchedules = async () => {
    const schedules = await scheduledUsersService.getSchedules();
    setScheduledDeleteUsers(schedules.results);
  };

  const getSchedule = async (userId) => {
    const schedule = await scheduledUsersService.getSchedule(userId);

    if (schedule.results.length) {
      const data = schedule.results[0];
      const date = new Date(data.date);

      setSchedule(schedule.results[0]);

      dateRef.current.value = date.toISOString().split("T")[0];
      timeRef.current.value = data.time;
    } else {
      setSchedule(null);
    }
  };

  const handlePrevPage = () => {
    const setPageNumber = currentPage - 1;
    paginate(setPageNumber, initialFilteredUserList);
    setCurrentPage(setPageNumber);
    adjustPages(setPageNumber);
  };

  const handleDualPrevPage = () => {
    const setPageNumber = currentPage - 5;
    setPageNumber <= 0 && (setPageNumber = 1);
    paginate(setPageNumber, initialFilteredUserList);
    setCurrentPage(setPageNumber);
    adjustPages(setPageNumber);
  };

  const handleNextPage = () => {
    const setPageNumber = currentPage + 1;
    paginate(setPageNumber, initialFilteredUserList);
    setCurrentPage(setPageNumber);
    adjustPages(setPageNumber);
  };

  const handleDualNextPage = () => {
    const setPageNumber = currentPage + 5;
    setPageNumber > totalPages && (setPageNumber = totalPages);
    paginate(setPageNumber, initialFilteredUserList);
    setCurrentPage(setPageNumber);
    adjustPages(setPageNumber);
  };

  const handleScheduledDelete = async (e) => {
    e.preventDefault();

    await scheduledUsersService.createSchedule({
      userId: selectedUserId,
      date: dateRef.current.value,
      time: timeRef.current.value,
    });

    getSchedules();

    setShowAlert(true);
    setSuccessNotific("User Delete Scheduled Succesfully!");
    setErrorNotific("");
    setTimeout(() => {
      setShowAlert(false);
    }, 2000);

    setSchedulePopup(false);
  };

  const dynamicDropdownCheckbox = (item) => {
    const updatedColumns = dynamicTableColumns.includes(item)
      ? dynamicTableColumns.filter((selectedColumn) => selectedColumn !== item)
      : [...dynamicTableColumns, item];

    setDynamicTableColumns(updatedColumns);
  };

  const closeDynamicDropdown = (event) => {
    if (
      dynamicDropdownRef.current &&
      !dynamicDropdownRef.current.contains(event.target)
    ) {
      setShowDynamicDropdown(false);
    }
  };

  const selectAllRows = () => {
    setShowAlert(false);

    if (!filteredUserList.length) {
      setShowAlert(true);
      setSuccessNotific("");
      setErrorNotific("No user is available to select");
      setTimeout(() => {
        setShowAlert(false);
      }, [2000]);
    }

    const formatDate = (dateStr) => {
      if (!dateStr) return '';
      const date = new Date(dateStr);
      if (isNaN(date.getTime())) return dateStr; // Return original string if not a valid date
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    };
    const formattedUserList = unpagedFilteredUserList.map((user) => ({
      ...user,
      dateOfJoining: formatDate(user.dateOfJoining),
      dateOfBirth: formatDate(user.dateOfBirth),
      confirmationDate: formatDate(user.confirmationDate)
    }));

  const isItemSelected = !formattedUserList.every((item) =>
    selectedRows.some((selectedItem) => selectedItem.id === item.id)
  );

  setSelectedRows(isItemSelected ? formattedUserList : []);
  };

  const handleBodyCheckbox = (item) => {
    setShowAlert(false);

    const isItemSelected = selectedRows.some(
      (selectedItem) => selectedItem.id === item.id
    );

    const updatedSelectedRows = isItemSelected
      ? selectedRows.filter((selectedItem) => selectedItem.id !== item.id)
      : [...selectedRows, item];
      const formatDate = (dateStr) => {
        if (!dateStr) return '';
        const date = new Date(dateStr);
        if (isNaN(date.getTime())) return dateStr; // Return original string if not a valid date
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
      };
      const formattedUserList = updatedSelectedRows.map((user) => ({
        ...user,
        dateOfJoining: formatDate(user.dateOfJoining),
        dateOfBirth: formatDate(user.dateOfBirth),
        confirmationDate: formatDate(user.confirmationDate)
      }));
      debugger
    setSelectedRows(formattedUserList);
  };

  const handleExport = () => {
    if (selectedRows.length === 0) {
      setShowAlert(true);
      setSuccessNotific("");
      setErrorNotific("No user is selected to export the data");
      setTimeout(() => {
        setShowAlert(false);
      }, [2000]);
    } else {
      const usersData = selectedRows.map((row) => {
        const rowData = {};

        [...fixedColumns, ...dynamicTableColumns, "DOB", "DOJ"].forEach(
          (column) => {
            rowData[column] = row[columnKeyMapping[column]];
          }
        );

        return rowData;
      });
      usersData.map((item) => {
        if (item.Department) {
          let department = departments.find(
            (department) => department.id == item.Department
          );
          item.Department = department ? department.department : "-";
        }
        return item;
      });
      
      usersData.map((item) => {
        if (item["Shift Timing"]) {
          let shiftTiming = shiftTimings.find(
            (shiftTiming) => shiftTiming.id == item["Shift Timing"]
          );
          item["Shift Timing"] = shiftTiming
            ? shiftTiming.startTime + " - " + shiftTiming.endTime
            : "-";
        }
        return item;
      });
      usersData.map((item) => {
        if (item["Shift"]) {
          let shift = shifts.find((shift) => shift.id == item["Shift"]);
          item["Shift"] = shift ? shift.geography : "-";
        }
        return item;
      });

      const csvData = Papa.unparse(usersData);
      const filename = `Exported_${
        selectedRows.length
      }_Users_With_${dynamicTableColumns.join("_")}.csv`;

      const blob = new Blob([csvData], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    }
  };

  const handleEmailWrap = (email) => {
    const atIndex = email.indexOf("@");
    if (atIndex !== -1) {
      const firstPart = email.slice(0, atIndex);
      const secondPart = email.slice(atIndex);
      return (
        <>
          {firstPart}
          <br />
          {secondPart}
        </>
      );
    }
    return email;
  };
  const handleDepartment = (deparmentId) => {
    let department = departments.find(
      (department) => department.id == deparmentId
    );
    return department ? department.department : "-";
  };
  const breakWordWithDash = (shiftTimingId) => {
    let shift = shiftTimings.find((shift) => shift.id == shiftTimingId);
    let text = shift?.startTime + " - " + shift?.endTime;
    if (text.includes("undefined")) {
      return "-";
    }
    return text?.split("-").map((word, index, array) => (
      <div key={index}>
        {word ? word : ""}
        {index !== array.length - 1 && "-"}
        {index !== array.length - 1 && <br />}
      </div>
    ));
  };
  const handleShift = (shiftId) => {
    let shift = shifts.find((shift) => shift.id == shiftId);
    let text = shift?.geography;
    return text ? text : "-";
  };
  useEffect(() => {
    if (userList.length !== 0) {
      const counts = {};

      userList.forEach((user) => {
        const department = user.department;
        counts[department] = (counts[department] || 0) + 1;
      });

      setUsersCountByDepartment(counts);
    }
  }, [userList]);

  useEffect(() => {
    let user = localStorage.getItem("user");
    let validator = new licenceValidator(user, "user_registration");
    setIsVisible(validator.isReadable("manage_user"));
    setIsDeletable(validator.isDelete("manage_user"));

    dropdown();
    getUsers(1);
    adjustPages();
    getSchedules();

    document.addEventListener("click", closeDynamicDropdown);
    return () => {
      document.removeEventListener("click", closeDynamicDropdown);
    };
  }, []);
  let popups = `${schedulePopup}${showDetails}`;
  useEffect(() => {
    let dashboardElement = document.querySelector(".dashboard");
    const body = document.body;

    if (dashboardElement && popups.includes("true")) {
      // Remove the 'transform' property
      // Remove the 'transform-origin' property
      // dashboardElement.style.transformOrigin = '';
    } else if (!popups.includes("true") && dashboardElement) {
    }
  }, [popups]);
  return (
    <div className="manage-user-table">
      {showAlert && (
        <div className="alert-container">
          <div className="upper-section">
            <p>ALOIS</p>
            <span
              className="delete-btn"
              onClick={() => setShowAlert(!showAlert)}
            >
              <AiOutlineClose className="icon" />
            </span>
          </div>

          <hr />

          <div className="lower-section py-2">
            <p className="text-danger">{errorNotific}</p>
            <p className="text-success">{successNotific}</p>
          </div>
        </div>
      )}

      {!popups.includes("true") && (
        <div className="upper-section">
          <div className="d-flex">
            <div className="input-group mb-3">
              <button
                className={`btn btn-outline-secondary search-btn ${
                  searchInputFocused && "search-input-focused"
                }`}
                type="button"
                id="button-addon1"
                onClick={() => userRef.current.focus()}
              >
                <FaSearch className="mb-1" />
              </button>

              <input
                type="text"
                className="form-control search-input-user"
                placeholder="Search"
                aria-label="Example text with button addon"
                aria-describedby="button-addon1"
                ref={userRef}
                onChange={() => filterUsers()}
                onFocus={() => setSearchInputFocused(true)}
                onBlur={() => setSearchInputFocused(false)}
              />
            </div>
          </div>

          <div className="d-flex justify-content-end gap-3">
            <div className="d-flex me-2">
              <div className="form-outline">
                <select
                  id="department"
                  className="form-select dropdown-styles"
                  ref={departmentRef}
                  onChange={handleDeparmentChange}
                >
                  <option value="default" className="p-5">
                    All Departments
                  </option>
                  {departments.map(
                    (item, index) => (
                      <option value={item.id} key={index}>
                        {item.department}
                      </option>
                    ),
                    []
                  )}
                </select>
                <div className="arrow-down"></div>
              </div>
            </div>

            <div className="d-flex me-2">
              <div className="form-outline">
                <select
                  id="region"
                  className="form-select dropdown-styles"
                  ref={shiftRef}
                  onChange={handleRegionChange}
                >
                  <option value="default">All Shifts</option>
                  {shifts.map(
                    (item, index) => (
                      <option value={item.id} key={index}>
                        {item.geography}
                      </option>
                    ),
                    []
                  )}
                </select>
                <div className="arrow-down"></div>
              </div>
            </div>

            <div className="d-flex me-2">
              <div className="form-outline">
                <select
                  id="shift"
                  className="form-select dropdown-styles"
                  ref={shiftTimingRef}
                  onChange={() => filterUsers()}
                >
                  <option value="default">All Shift timings</option>
                  {shiftTimings.map(
                    (item, index) => (
                      <option value={item.id} key={index}>
                        {item.startTime + " - " + item.endTime}
                      </option>
                    ),
                    []
                  )}
                </select>
                <div className="arrow-down"></div>
              </div>
            </div>

            <div ref={dynamicDropdownRef}>
              <div
                className="cursor-pointer"
                onClick={() => setShowDynamicDropdown(!showDynamicDropdown)}
              >
                <HiMiniAdjustmentsVertical
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Filters"
                  className={`table-column-filter ${
                    showDynamicDropdown && "filter-active"
                  }`}
                />
              </div>

              {showDynamicDropdown && (
                <div className="dynamic-dropdown">
                  {dynamicColumns.map((item, index) => (
                    <div key={index}>
                      <input
                        type="checkbox"
                        id={item}
                        checked={dynamicTableColumns.includes(item)}
                        onChange={() => dynamicDropdownCheckbox(item)}
                      />
                      <label htmlFor={item}> &nbsp; {item}</label>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {!popups.includes("true") && (
        <div className="action-row">
          <div className="d-flex">
            <div
              className="action-item-button"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Download"
              onClick={handleExport}
            >
              <FiDownload />
            </div>

            {isdeletable && (
              <td
                className="action-item-button"
                onClick={() => handleDelete(selectedRows)}
                data-toggle="tooltip"
                data-placement="bottom"
                title="Delete"
              >
                <FaTrashAlt />
              </td>
            )}
          </div>

          <div className="user-count">
            Selected User Count: {selectedRows.length}
          </div>
        </div>
      )}

      {schedulePopup && (
        <div className="popup-container">
          <div className="popup">
            <div className="popup-header">
              <span className="fs-6 fw-bold">Schedule User Delete</span>

              <button
                className="close-button"
                onClick={() => setSchedulePopup(!schedulePopup)}
              >
                &#10005;
              </button>
            </div>

            <hr style={{ borderTop: "3px dotted black" }} />

            <div className="popup-content">
              <form encType="multipart/form-data">
                <div className="form-fields-container">
                  <div className="row pb-4">
                    <div className="col-xl-6">
                      <div className="form-outline d-flex flex-column">
                        <label
                          className="form-label"
                          htmlFor="department"
                          style={{ marginTop: "10px" }}
                        >
                          Date:
                        </label>

                        {!schedule ? (
                          <input
                            min={new Date().toISOString().split("T")[0]}
                            ref={dateRef}
                            type="date"
                            required
                          />
                        ) : (
                          <input
                            min={new Date().toISOString().split("T")[0]}
                            ref={dateRef}
                            type="date"
                            disabled
                          />
                        )}
                      </div>
                    </div>

                    <div className="col-xl-6">
                      <div className="form-outline d-flex flex-column">
                        <label
                          className="form-label mx-2"
                          htmlFor="department"
                          style={{ marginTop: "10px" }}
                        >
                          Time:
                        </label>

                        {!schedule ? (
                          <input
                            ref={timeRef}
                            type="time"
                            name="time"
                            id="timeInput"
                            required
                          />
                        ) : (
                          <input
                            ref={timeRef}
                            type="time"
                            name="time"
                            id="timeInput"
                            disabled
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="upload-btn-container">
                  {!schedule ? (
                    <GradientBorderButton
                      text="Schedule"
                      clickHandler={handleScheduledDelete}
                    />
                  ) : (
                    <div className="row">
                      <div className="col">
                        <GradientBorderButton
                          text="Unschedule"
                          clickHandler={handleDeleteSchedule}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      {!popups.includes("true") && (
        <div className="table-responsive" ref={tableRef}>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">
                  <div className="d-flex justify-content-center">
                    <input
                      type="checkbox"
                      checked={
                        unpagedFilteredUserList.length &&
                        selectedRows.length === unpagedFilteredUserList.length
                      }
                      onChange={selectAllRows}
                    />
                  </div>
                </th>

                {fixedColumns.map((item, index) => (
                  <th key={index} scope="col" className="align-middle">
                    <span title={String(item).toUpperCase()}>{item}</span>
                  </th>
                ))}

                {dynamicTableColumns.map((item, index) => (
                  <th key={index} scope="col" className="align-middle">
                    <span title={String(item).toUpperCase()}>{item}</span>
                  </th>
                ))}

                <th className="action-button-header"></th>
                <th className="action-button-header"></th>
                <th className="action-button-header"></th>
              </tr>
            </thead>

            <tbody>
              {filteredUserList.length > 0 &&
                filteredUserList.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <div className="d-flex justify-content-center">
                        <input
                          type="checkbox"
                          checked={selectedRows.some(
                            (selectedItem) => selectedItem.id === item.id
                          )}
                          onChange={() => handleBodyCheckbox(item)}
                        />
                      </div>
                    </td>

                    {fixedColumns.map((column, index) => (
                      <td key={index}>
                        <span title={item[columnKeyMapping[column]]}>
                          {item[columnKeyMapping[column]]}
                        </span>
                      </td>
                    ))}

                    {dynamicTableColumns.map((column, index) => (
                      <td key={index}>
                        {dynamicTableColumns.includes(column) &&
                          (column === "Shift" ? (
                            <span title={item[columnKeyMapping[column]]}>
                              {handleShift(item[columnKeyMapping[column]])}
                            </span>
                          ) : column === "Shift Timing" ? (
                            <span title={item[columnKeyMapping[column]]}>
                              {breakWordWithDash(
                                item[columnKeyMapping[column]]
                              )}
                            </span>
                          ) : (
                            <span title={item[columnKeyMapping[column]]}>
                              {dynamicTableColumns.includes(column) &&
                                (column === "Email"
                                  ? handleEmailWrap(
                                      item[columnKeyMapping[column]]
                                    )
                                  : column === "Department"
                                  ? handleDepartment(
                                      item[columnKeyMapping[column]]
                                    )
                                  : item[columnKeyMapping[column]])}
                            </span>
                          ))}
                      </td>
                    ))}

                    {isVisible && (
                      <td
                        className={`action-button-header ${
                          scheduledDeleteUsers.some(
                            (schedule) => schedule.userId === item.id
                          )
                            ? "scheduled"
                            : ""
                        }`}
                        onClick={() => {
                          setSchedulePopup(!schedulePopup);
                          setSelectedUserId(item.id);
                          getSchedule(item.id);
                        }}
                      >
                        <FaRegCalendarAlt
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Schedule User"
                          className="calender-icon"
                        />
                      </td>
                    )}

                    {isVisible && (
                      <td
                        className="action-button-header"
                        onClick={() => {
                          sessionStorage.setItem("user", JSON.stringify(item));
                        }}
                      >
                        <NavLink
                          to={"/admin/user-registration/single-user/update"}
                          style={{ fontSize: "0.9rem" }}
                        >
                          <FaEdit
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Edit User"
                            style={{ color: "#175572" }}
                          />
                        </NavLink>
                      </td>
                    )}

                    <td
                      className="action-button-header"
                      onClick={() => {
                        setSelectedDetails(item);
                        setShowDetails(!showDetails);
                      }}
                    >
                      <FaUserAlt
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="View User"
                        className="user-icon"
                        style={{ color: "#175572" }}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}

      {pageNumbers.length > 1 && !popups.includes("true") ? (
        <div className="pagination my-2">
          {currentPage !== 1 && totalPages > 5 && (
            <button onClick={handleDualPrevPage}>
              <FaAngleDoubleLeft className="arrow-icon left-arrow " />
            </button>
          )}

          {currentPage !== 1 && (
            <button onClick={handlePrevPage}>
              <FaAngleLeft className="arrow-icon left-arrow " />
            </button>
          )}

          {pageNumbers.map((page, index) => (
            <button
              key={index}
              disabled={currentPage === page}
              className={currentPage === page ? "active" : ""}
              onClick={() => {
                paginate(page, initialFilteredUserList);
                adjustPages(page);
              }}
            >
              {page}
            </button>
          ))}

          {currentPage !== totalPages && (
            <button onClick={handleNextPage}>
              <FaAngleRight className="arrow-icon" />
            </button>
          )}

          {currentPage !== totalPages && totalPages > 5 && (
            <button onClick={handleDualNextPage}>
              <FaAngleDoubleRight className="arrow-icon" />
            </button>
          )}
        </div>
      ) : (
        <></>
      )}

      {showDetails && (
        <UserRegistrationPopup
          toggleBtn={showDetails}
          toggleFunction={setShowDetails}
        >
          <div className="d-flex flex-column">
            <div className="d-flex" style={{ justifyContent: "center" }}>
              <div className="profile-picture-upload">
                <label htmlFor="profilePicture">
                  {selectedDetails.profilePic ? (
                    <div
                      className="preview"
                      style={
                        selectedDetails.profilePic
                          ? {
                              backgroundImage: `url(${selectedDetails.profilePic})`,
                              backgroundSize: "cover",
                              backgroundPosition: "top",
                              backgroundRepeat: "no-repeat",
                            }
                          : null
                      }
                    ></div>
                  ) : (
                    "NA"
                  )}
                </label>
              </div>
            </div>
            <div className="d-flex">
              <p className="labels">Last Name:</p>
              <p className="values">
                {selectedDetails.lastName
                  ? selectedDetails.lastName.charAt(0).toUpperCase() +
                    selectedDetails.lastName.slice(1)
                  : "NA"}
              </p>
            </div>

            <div className="d-flex">
              <p className="labels">Designation:</p>
              <p className="values">
                {selectedDetails.designation
                  ? selectedDetails.designation.charAt(0).toUpperCase() +
                    selectedDetails.designation.slice(1)
                  : "NA"}
              </p>
            </div>

            <div className="d-flex">
              <p className="labels">Employee ID:</p>
              <p className="values">
                {selectedDetails.empId ? selectedDetails.empId : "NA"}
              </p>
            </div>

            <div className="d-flex">
              <p className="labels">Date of Birth:</p>
              <p className="values">
                {selectedDetails.dateOfBirth
                  ? selectedDetails.dateOfBirth.includes("T")
                    ? selectedDetails.dateOfBirth.substring(
                        0,
                        selectedDetails.dateOfBirth.indexOf("T")
                      )
                    : selectedDetails.dateOfBirth
                  : "NA"}
              </p>
            </div>

            <div className="d-flex">
              <p className="labels">Gender:</p>
              <p className="values">
                {selectedDetails.gender
                  ? selectedDetails.gender.charAt(0).toUpperCase() +
                    selectedDetails.gender.slice(1)
                  : "NA"}
              </p>
            </div>

            <div className="d-flex">
              <p className="labels">Blood Group:</p>
              <p className="values">
                {selectedDetails.bloodGroup
                  ? selectedDetails.bloodGroup.charAt(0).toUpperCase() +
                    selectedDetails.bloodGroup.slice(1)
                  : "NA"}
              </p>
            </div>

            <div className="d-flex">
              <p className="labels">Nationality:</p>
              <p className="values">
                {selectedDetails.nationality
                  ? selectedDetails.nationality.charAt(0).toUpperCase() +
                    selectedDetails.nationality.slice(1)
                  : "NA"}
              </p>
            </div>

            <div className="d-flex">
              <p className="labels">Phone Number:</p>
              <p className="values">
                {selectedDetails.phoneNumber
                  ? selectedDetails.phoneNumber
                  : "NA"}
              </p>
            </div>

            <div className="d-flex">
              <p className="labels">Address:</p>
              <p className="values">
                {selectedDetails.address ? selectedDetails.address : "NA"}
              </p>
            </div>

            <div className="d-flex">
              <p className="labels">Date of Joining:</p>
              <p className="values">
                {selectedDetails.dateOfJoining
                  ? selectedDetails.dateOfJoining.includes("T")
                    ? selectedDetails.dateOfJoining.substring(
                        0,
                        selectedDetails.dateOfJoining.indexOf("T")
                      )
                    : selectedDetails.dateOfJoining
                  : "NA"}
              </p>
            </div>

            <div className="d-flex">
              <p className="labels">Email:</p>
              <p className="values">
                {selectedDetails.email ? selectedDetails.email : "NA"}
              </p>
            </div>

            <div className="d-flex">
              <p className="labels">Department:</p>
              <p className="values">
                {departments.find(
                  (department) => department.id == selectedDetails.department
                )
                  ? departments.find(
                      (department) =>
                        department.id == selectedDetails.department
                    ).department
                  : "NA"}
              </p>
            </div>

            <div className="d-flex">
              <p className="labels">Shift timings:</p>
              <p className="values">
                {shiftTimings.find(
                  (shift) => shift.id === selectedDetails.shiftTiming
                )
                  ? shiftTimings.find(
                      (shift) => shift.id === selectedDetails.shiftTiming
                    ).startTime +
                    " - " +
                    shiftTimings.find(
                      (shift) => shift.id === selectedDetails.shiftTiming
                    ).endTime
                  : "Shift timing not found"}
              </p>
            </div>

            <div className="d-flex">
              <p className="labels">Shift:</p>
              <p className="values">
                {shifts.find((shift) => shift.id === selectedDetails.shift)
                  ? shifts?.find((shift) => shift.id === selectedDetails.shift)
                      .geography
                  : "Geography not found"}
              </p>
            </div>

            <div className="d-flex">
              <p className="labels">Confirmation Date:</p>
              <p className="values">
                {selectedDetails.confirmationDate
                  ? selectedDetails.confirmationDate.includes("T")
                    ? selectedDetails.confirmationDate.substring(
                        0,
                        selectedDetails.confirmationDate.indexOf("T")
                      )
                    : selectedDetails.confirmationDate
                  : "NA"}
              </p>
            </div>

            <div className="d-flex">
              <p className="labels">Employment Type:</p>
              <p className="values">
                {selectedDetails.employmentType
                  ? selectedDetails.employmentType
                  : "NA"}
              </p>
            </div>

            <div className="d-flex">
              <p className="labels">Report To:</p>
              <p className="values">
                {selectedDetails.reportTo ? selectedDetails.reportTo : "NA"}
              </p>
            </div>
          </div>
        </UserRegistrationPopup>
      )}
    </div>
  );
}

export default ManageUser;
