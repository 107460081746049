import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { useRef } from "react";
import axios from "../../config/axios";
import Notification from "../Notification/Notification";
import Result from "../JD-CV_comparison_result/Result";
// import ReactSpeedometer from "react-d3-speedometer";
import FormData from "form-data";
import { Toast } from "bootstrap";
import licenceValidator from "../../permission/validate";
import { AiOutlineClose } from "react-icons/ai";
import GradientBorderButton from "../Widgets/GradientBorderButton";

const RelevantCandidateFinder = () => {
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [queryBox, setQueryBox] = useState(false);
  const [similarity, setSimilarity] = useState([]);
  const [success, setSuccess] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [successNotific, setSuccessNotific] = useState("");
  const [errorNotific, setErrorNotific] = useState("");

  const jdRef = useRef(null);
  const cvRef = useRef(null);
  const accuracyThreshold = useRef(null);

  const checkCompatiblity = async () => {
   
    const jd = jdRef.current.files;
    const cvs = cvRef.current.files;
    const accuracy = (accuracyThreshold.current.value === null) ? 0.0 : (accuracyThreshold.current.value / 100);
    setQueryBox(false);

    // if (cvRef.current.files.length >= 7) {
    //   setSuccessNotific("");
    //   setErrorNotific("You cannot add more than 10 CVs.");

    if (cvRef.current.files.length >= 11) {
      setSuccessNotific("");
      setErrorNotific("You cannot add more than 10 CVs.");
     
      setShowAlert(true);


    //   setShowAlert(true);

    } else if (cvRef.current.files.length < 2) {
      setSuccessNotific("");
      setErrorNotific("You cannot add less than 2 CVs.");

      setShowAlert(true);


    } else {
      setLoader(true);
      const formData = new FormData();
      const jds = jdRef.current.files;
      const resumes = cvRef.current.files;
      for (let i = 0; i < jds.length; i++) {
        formData.append("jds", jds[i]);
      }
      for (let i = 0; i < resumes.length; i++) {
        formData.append("resumes", resumes[i]);
      }
      formData.append("selectedTab", "jobdescription");
      formData.append("accuracy", accuracy);
      try {
        const res = await axios.post("/utility/CVJDCompatibility", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        // console.log('AI ENGINE OUPUT', res.data.data);
        if (res) {
          const data = res.data.data;
          // console.log(data)
          if (data[0].file_type == 'jd') {
            setSuccessNotific("");
            setErrorNotific("Your JD will be more effective if you add some content to it.");
            setShowAlert(true);
          }
          else {
            setSimilarity(data);
            setQueryBox(true);
            // jdRef.current.value = null;
            // cvRef.current.value = null;
          }
        }
      } catch (err) {
        setLoader(false);
        setErrorNotific("Server Error!");
        // const toast1 = document.getElementById("toast-1");
        // const toastSMA = new Toast(toast1);
        // toastSMA.show();
      }
    }
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
    setLoader(false);
  };
  useEffect(() => {
    let user = localStorage.getItem("user");
    let validator = new licenceValidator(user, "assist");
    setIsVisible(validator.isReadable("relevant_candidate_finder"));
  }, []);
  const handleDownload = (e) => {

    fetch(`${e}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2NDgyYzQ0YzZkYTU2NTZjYWI3N2UyMmIiLCJpYXQiOjE2ODY3MjA1NjcsImV4cCI6MTY4NjczODU2NywidHlwZSI6ImFjY2VzcyJ9.lqYFcSY_Q6eM6KHlljxTKizMhLZChg47UWPuRQocIVU",
      },
    })
      .then((response) => response.blob())
      .then((data) => window.open(URL.createObjectURL(data)))
      .catch((err) => {
        setError("Server Error !");
        // console.log(err);
        // const toast1 = document.getElementById('toast-1');
        // const toast = new Toast(toast1);
        // toast.show()
      });
  };
  let dashboardElement = document.querySelector(".dashboard");

  useEffect(()=>{
    
  
    if (dashboardElement && queryBox) {
            // If yes, set height to viewport height
       
        } else {
       
    
        }
    },[queryBox])
  const DisplaySimilarity = () => {
    return (
      <div className="outerbox d-flex flex-column flex-wrap justify-content-center align-items-start">
        <h5 className="mb-4 fw-bold" style={{ color: '#678498' }}>
       <u># Matched Files in descending order</u>
        </h5>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th >#</th>
                <th >File</th>
                <th >Accuracy</th>
              </tr>
            </thead>

            <tbody>
              {similarity
                .filter(item => item.accuracy >= (accuracyThreshold.current.value))
                .map((item, idx) => (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td>{item.fileName}</td>
                    <td>{Math.floor(item.accuracy)}%</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {similarity[0].skipped_files.length > 0 ? (
          <div className="mt-5 w-100">
            <h5 className="mb-4 fw-bold" style={{ color: '#678498' }}>
              <u># Skiped Files</u>
            </h5>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>File</th>
                    <th>Remark</th>
                  </tr>
                </thead>

                <tbody>
                  {similarity
                    .filter(item => item.accuracy < (accuracyThreshold.current.value))
                    .map((item, idx) => (
                      <tr key={idx}>
                        <td>{idx + 1}</td>
                        <td>{item.fileName}</td>
                        <td>{`Lower accuracy :${Math.floor(item.accuracy)}%`}</td>
                      </tr>
                    ))}
                  {similarity[0].skipped_files.map((item, idx) => (
                    <tr key={idx}>
                      <td>{idx + 1}</td>
                      <td>{item.substring(item.lastIndexOf('/') + 1,)}</td>
                      <td>Blanked File</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <div className="relevent-candidate p-4">
      {showAlert ? (
        <div className="alert-container">
          <div className="upper-section">
            <p>ALOIS</p>
            <span className="delete-btn" onClick={() => setShowAlert(!showAlert)}>
              <AiOutlineClose className="icon-1" />
            </span>
          </div>
          <hr />
          <div className="lower-section pt-2">
            <p className="text-danger">{errorNotific}</p>
            <p className="text-success">{successNotific}</p>
          </div>
        </div>
      ) : null}
      <form id="form">
        <div className="row">
          <div className="col">
            <div className="form-outline">
              <label className="form-label" htmlFor="customFile">
                Upload job description
              </label>
              <input
                ref={jdRef}
                accept=".pdf"
                type="file"
                className="form-control"
                id="customFile"
                required
              />
            </div>
          </div>
          <div className="col">
            <div className="form-outline">
              <label className="form-label" htmlFor="customFile">
                Upload CVs
              </label>
              <input
                ref={cvRef}
                accept=".pdf"
                type="file"
                className="form-control"
                id="customFile"
                multiple
                required
              />
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-4">
            <div className="form-outline">
              <label className="form-label" htmlFor="accuracyMatch">
                How precise it should be? <span className="fw-normal"> <em>(Optional) </em></span>
              </label>
              <input
                placeholder="Example: 85%"
                ref={accuracyThreshold}
                type="number"
                className="form-control"
                id="accuracyMatch"
              />
            </div>
          </div>
        </div>
        {loader ? (
          <div className="mx-auto mt-3 p-1">
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            &nbsp; Loading...
          </div>
        ) : isVisible ? (
          <div className="d-flex justify-content-center w-100 mt-3">
          <GradientBorderButton clickHandler={checkCompatiblity} text={"Check Compatibility"} />
          </div>
        ) : null}
        <br></br>
        {queryBox && (
          <div>
            <DisplaySimilarity />
          </div>
        )}
      </form>
    </div>
  );
};

export default RelevantCandidateFinder;
