import React, { useEffect, useRef, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import axios from "../../config/axios";
import Notification from "../Notification/Notification";
import licenceValidator from "../../permission/validate";
import GenerateBox from "../GenerateBox/GenerateBox";
import { AiOutlineClose } from "react-icons/ai";
import GradientBorderButton from "../Widgets/GradientBorderButton";

const RecruiterOutreach = () => {
  const writeRef = useRef();
  const intentRef = useRef();
  const prefixRef = useRef();
  const promptRef = useRef();
  const [loader, setLoader] = useState(false);
  const [div, setDiv] = useState(false);
  const [output, setOutput] = useState("");
  const [mail, setMail] = useState(true);
  const [success, setSuceess] = useState("");
  const [error, setError] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [generateComponents, setGenerateComponents] = useState(false);
  const [showAlert, setShowAlert] = useState(false);


  const [successNotific, setSuccessNotific] = useState("");
  const [errorNotific, setErrorNotific] = useState("");
  const limRef = useRef();

  const promptPrefixes = ["email to", "LinkedIn Message for"];
  const intents = [
    "Formal",
    "Professional",
    "CTA based",
    "Inspirational",
    "Informative",
    "Witty",
    "Humourous",
    "Creative",
    "Informal",
    "Casual",
    "Enthusiastic",
    "Story based",
  ];
  const handleReset = () =>{
    setLoader(false);
    setGenerateComponents(false);
    setOutput("");
      setDiv(false)
      setError("");
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    if(!limRef.current.value || /^(5[0-9]|[6-9][0-9]|[1-9][0-9]{2,})$/.test(limRef.current.value)){
      setGenerateComponents(false);
      setLoader(true);
      try {
        setDiv(false)
        setOutput("")
        const res = await axios.post("/utility/contentGenerator", {
          prompt: `${writeRef.current.value} ${intentRef.current.value} ${prefixRef.current.value} ${promptRef.current.value} `,
          limit:limRef.current.value
        });
        // console.log(res.data)
        setOutput(res.data.answer);
        setDiv(true);
        setGenerateComponents(true);
        setError("");
      } catch (err) {
        setError("Server Error !");
        setDiv(false);
      }
      setLoader(false);
    } else {
      setShowAlert(true)
      setErrorNotific("Limit must be greater than equal to 50")
      setTimeout(() => {
        setErrorNotific("")
        setShowAlert(false)
      }, 2000);
    }
   
  };

  const sendMail = () => {
    // console.log(output);
    window.open(
      `mailto:Enter your email here?subject=${output.subject}&body=${output.answer}`
    );
  };

  const handleChange = (e) => {
    if (e.target.value === "email to") {
      setMail(true);
    } else {
      setMail(false);
    }
  };
  useEffect(() => {
    let user = localStorage.getItem("user");
    let validator = new licenceValidator(user, "assist");
    setIsVisible(validator.isReadable("recruiter_outreach"));
  }, []);
  let dashboardElement = document.querySelector(".dashboard");

  useEffect(()=>{
    
  
    if (dashboardElement && generateComponents) {
            // If yes, set height to viewport height
       
        } else {
       
    
        }
    },[generateComponents])
  return (
    <div className="recruiter-outreach p-4">
             {showAlert ? (
        <div className="alert-container">
          <div className="upper-section">
            <p style={{ marginBottom: "0px", marginTop: "5px" }}>ALOIS</p>
            <span  className="delete-btn" onClick={() => setShowAlert(!showAlert)}>
              <AiOutlineClose className="icon" style={{ fill: "black" }} />
            </span>
          </div>
          <hr
            style={{
              display: "block !important",
              height: "1px !important",
              border: "0 !important",
              borderTop: "1px solid gray !important",
            }}
          />
          <div className="lower-section pt-2">
            <p className="text-danger">{errorNotific}</p>
            <p className="text-success">{successNotific}</p>
          </div>
        </div>
      ) : null}
      <form >
        <div className="row dropdowns">
          <div className="col">
            <div className="form-outline">
              <label className="form-label" htmlFor="form8Example3">
                Write a/an
              </label>
              <div class="position-relative">
                <select ref={writeRef} className="form-select" required>
                  <option value="Write a">Write a</option>
                  <option value="Write an">Write an</option>
                </select>
                <div className="arrow-down"></div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="form-outline">
              <label className="form-label" htmlFor="form8Example4">
                Intent
              </label>
              <div className="position-relative">
                <select
                  ref={intentRef}
                  name="intent"
                  id="intent"
                  className="form-select"
                  required
                >
                  {intents.map((intent, index) => {
                    return (
                      <option key={index} value={intent}>
                        {intent}
                      </option>
                    );
                  })}
                </select>
                <div className="arrow-down"></div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="form-outline">
              <label className="form-label" htmlFor="form8Example5">
                Content type
              </label>
              <div className="position-relative">
                <select
                  ref={prefixRef}
                  name="prefix"
                  id="prefix"
                  onChange={(e) => handleChange(e)}
                  className="form-select"
                  required
                >
                  {promptPrefixes.map((prefix, index) => {
                    return (
                      <option key={index} value={prefix}>
                        {prefix}
                      </option>
                    );
                  })}
                </select>
                <div className="arrow-down"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <div className="form-outline">
              <label className="form-label pt-3" htmlFor="form8Example1">
                Word limit{" "}
                <span className="fw-normal">
                  <em>(Optional)</em>
                </span>
              </label>
              <input
                ref={limRef}
                type="number"
                id="form8Example1"
                className="form-control"
                
              />
            </div>
          </div>
     
        </div>
        <div className="row">
          <div className="col">
        <div className="form-outline mb-4 ">
          <label className="form-label pt-3" htmlFor="form6Example3">
            Purpose of the information you want to generate
          </label>
          <input
            ref={promptRef}
            type="text"
            id="form6Example3"
            class="form-control"
            required
          />
        </div>
        </div>
        </div>
        {loader ? (
          <div className="mx-auto mt-3 p-1">
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            &nbsp; Loading...
          </div>
        ) : isVisible ? (
          <div className="d-flex justify-content-center w-100 mt-3">
          <GradientBorderButton clickHandler={handleSubmit} text={"Submit"} />
          </div>
          
        ) : null}
        
      </form>
      {div && (
        <div className="h-100">
          {/* <textarea
            className="mt-4 form-control"
            style={{ height: "fit-content" }}
            rows="15"
          >
            {output}
          </textarea> */}
          {generateComponents && (
          <div>
            <GenerateBox 
              text={output}
              showJdIcons={true}
              handleSubmit={handleSubmit}
              handleReset={handleReset}
              // fileName={}
            />
          </div>

        )}
          <br />
          
        </div>
      )}
      <Notification success={success} error={error} />
    </div>
  );
};

export default RecruiterOutreach;
