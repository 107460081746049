import { useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { MdUploadFile } from "react-icons/md";
import jobDescriptionService from "../../services/jobDescription.service";

const Jdupload = () => {
  const [success, setSuccess] = useState("");
  const [showAlert, setShowAlert] = useState("");
  const [inputData, setInputData] = useState({
    domain: "",
  });

  const jdRef = useRef();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputData({ ...inputData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append("domain", inputData["domain"]);

    let files = jdRef.current.files;
    for (let i = 0; i < jdRef.current.files.length; i++) {
      formData.append("jobDescriptions", files[i]);
    }

    try {
      const response = await jobDescriptionService.createJobDescription(
        formData
      );

      jdRef.current.value = [];

      setSuccess("Job Description Entered Successfully!");
      setInputData({ jobDescription: "" });

      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 2000);
    } catch (error) {
      // console.log("Error In Input Details: ", error);
    }
  };

  return (
    <div className="d-flex jdupload-container flex-column clan-score-admin">
      <div className="indicator-container ">
        <div>
          <MdUploadFile className="icon" />
        </div>

        <div className="text">JD Upload</div>
      </div>

      <div className="jdupload-outer mx-auto">
        <div className="jdupload-inner mt-3">
          {showAlert ? (
            <div className="alert-container">
              <div className="upper-section">
                <p style={{ marginBottom: "0px", marginTop: "5px" }}>ALOIS</p>

                <span
                  className="delete-btn"
                  onClick={() => setShowAlert(!showAlert)}
                >
                  <AiOutlineClose className="icon" style={{ fill: "black" }} />
                </span>
              </div>

              <hr
                style={{
                  display: "block !important",
                  height: "1px !important",
                  border: "0 !important",
                  borderTop: "1px solid gray !important",
                }}
              />

              <div className="lower-section pt-2">
                <p className="text-success">{success}</p>
              </div>
            </div>
          ) : null}

          <div>
            <form className="form-group p-4" onSubmit={handleSubmit}>
              <label className="form-label" htmlFor="">
                Domain:
              </label>

              <div>
                <select
                  // ref={clanRef}
                  id="department"
                  className="form-select"
                  value={inputData.domain}
                  // onChange={handleInputChange}
                  name="domain"
                  style={{ marginBottom: "10px", borderRadius: "5px" }}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  required
                >
                  <option value="" className="p-5">
                    Select an option
                  </option>
                  <option value="NonIT">NonIT</option>
                  <option value="IT">IT</option>
                  <option value="Light Industry (LI)">
                    Light Industry (LI)
                  </option>
                  <option value="Engineering">Engineering</option>
                  <option value="Pharma">Pharma</option>
                  <option value="HealthCare">HealthCare</option>
                  <option value="Telecom">Telecom</option>
                  <option value="Service Industry (SI)">
                    Service Industry (SI)
                  </option>
                </select>
              </div>

              <div className="d-flex flex-column justify-content-center ">
                <label className="form-label" htmlFor="">
                  Upload job descriptions:
                </label>

                <input
                  // ref={cvRef}
                  accept=".pdf"
                  className="form-control mb-4"
                  id="jdFile"
                  ref={jdRef}
                  multiple
                  required
                />
              </div>

              <button
                type="submit"
                className="generate-btn  mx-auto mt-3 p-1"
                style={{
                  width: "20%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "2rem",
                }}
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Jdupload;
