// {
//     "licence":[
//       {
//         "moduleName":"UserManagement",
//         "isAccessible": true,
//         "permissions":[
//           {
//             "subModuleName":"mod1",
//             "create":true,
//             "read":true,
//             "update":true,
//             "delete":false
//           },
//           {
//             "subModuleName":"mod1",
//             "create":true,
//             "read":true,
//             "update":true,
//             "delete":false
//           }
//         ]
//       }
//     ]
//   }

class licenceValidator {
  constructor(userData, mainModuleName) {
    this.userData = JSON.parse(userData);
    this.mainModuleName = mainModuleName;
    try {
      this.mainModule = this.findModuleByName(
        this.userData.license,
        mainModuleName
      );
    } catch (err) {
      // localStorage.clear()
      // window.location.replace("/auth/login")
    }
  }

  findModuleByName(modules, moduleName) {
    for (const module of modules) {
      if (
        module.moduleName === moduleName ||
        module.subModuleName === moduleName
      ) {
        return module;
      }
      if (module.permissions && module.permissions.length > 0) {
        const foundModule = this.findModuleByName(
          module.permissions,
          moduleName
        );
        if (foundModule) {
          return foundModule;
        }
      }
    }
    return null;
  }

  isAccessible() {
    try {
      return this.mainModule.isAccessible;
    } catch (err) {
      // localStorage.clear()
      // window.location.replace("/auth/login")
    }
  }

  isVisible(subModule) {
    try {
      if (this.isAccessible()) {
        const mySubModule = this.mainModule.permissions.find(
          (el) => el.subModuleName === subModule
        );
        return mySubModule.read;
      }
      return false;
    } catch (err) {
      // localStorage.clear()
      // window.location.replace("/auth/login")
    }
  }

  isReadable(subModule) {
    try {
      if (this.mainModule.isAccessible) {
        const mySubModule = this.mainModule.permissions.find(
          (el) => el.subModuleName === subModule
        );
        return mySubModule.create || mySubModule.update;
      }
      return false;
    } catch (err) {
      // localStorage.clear()
      // window.location.replace("/auth/login")
    }
  }
  isDelete(subModule) {
    try {
      if (this.isAccessible()) {
        const mySubModule = this.mainModule.permissions.find(
          (el) => el.subModuleName === subModule
        );
        return mySubModule.delete;
      }
      return false;
    } catch (err) {
      // localStorage.clear()
      // window.location.replace("/auth/login")
    }
  }
}

export default licenceValidator;
