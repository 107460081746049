import React from "react";
import { useState, useRef, useEffect } from "react";

import {
  AiOutlineClose,
  AiOutlinePicture,
} from "react-icons/ai";
import { FaArrowDown, FaArrowUp } from "react-icons/fa6";
import { LuMessageSquare } from "react-icons/lu";
import { PiChatsBold } from "react-icons/pi";
import GradientBorderButton from "../../components/Widgets/GradientBorderButton";
import { FaBirthdayCake, FaRegThumbsUp, FaThumbsUp, FaTrashAlt } from "react-icons/fa";
import { MdVideoLibrary } from "react-icons/md";
import {
  IoIosPaperPlane,
  IoIosArrowForward,
} from "react-icons/io";
import { RiBarChart2Fill, RiLayoutGridLine } from "react-icons/ri";
import { CgNotes } from "react-icons/cg";
import ClanIcon from "../../assets/clan-icon.svg";
import ClanWhiteIcon from "../../assets/clanScore_white.svg";
import WorkAnniversaryWhiteIcon from "../../assets/WorkAnniversary_white.svg";
import Plus from "../../assets/plus.svg";
import Trash from "../../assets/Trash.svg";

import aloisFeedService from "../../services/aloisFeed.service";

import minioService from "../../services/minio.service";
import { Col } from "react-bootstrap";
import { Row } from "reactstrap";
import dashboardService from "../../services/dashboard.service";
import { ImDownload3 } from "react-icons/im";
import { NavLink } from "react-router-dom";
import clanMasterService from "../../services/clanMaster.service";
import userRegistrationService from "../../services/userRegistration.service";
import buzzService from "../../services/buzz.service";
import { setActivePanel } from "../../features/userSlice";
import { useDispatch } from "react-redux";
import licenceValidator from "../../permission/validate";
function Dashboard() {
  // const [likeCount, setLikeCount] = useState(0);
  const dispatch = useDispatch();
  const [feedVisible, setFeedVisible] = useState(false);
  const [visibleTabs, setVisibleTabs] = useState([])
  const [birthdayUsers, setBirthdayUsers] = useState([]);
  const [anniversaryUsers, setAnniversaryUsers] = useState([]);
  const [showPreviewPopup, setShowPreviewPopup] = useState(false);
  const [allFeed, setAllfeed] = useState([])
  const [clanPositions, setClanPositions] = useState({
    wentAhead: [],
    wentBehind: [],
    stayedSame: []
  })

  const [noOfPollOptions, setNoOfPollOptions] = useState(2);
  const imageInputRef = useRef(null);
  const videoInputRef = useRef(null);
  const [activeForm, setActiveForm] = useState("message");
  const [highestScore, setHighestScore] = useState(0)
  const tabs = [
    { type: "feed", icon: <RiLayoutGridLine /> },
    { type: "photo", icon: <AiOutlinePicture /> },
    { type: "video", icon: <MdVideoLibrary /> },
    { type: "poll", icon: <RiBarChart2Fill /> },
    { type: "notice", icon: <CgNotes /> },
    { type: "birthday", icon: <FaBirthdayCake /> },
    {
      type: "anniversary",
      icon: <img src="../../assets/WorkAnniversary.svg" />,
      image: <img src={WorkAnniversaryWhiteIcon} />,
    },
    {
      type: "message",
      icon: <PiChatsBold />,
    },
    {
      type: "clan",
      icon: <img src={ClanIcon} />,
      image: <img src={ClanWhiteIcon} />,
    },
  ];
  const [selectedFeed, setSelectedFeed] = useState(null);
  const [activeTab, setActiveTab] = useState(tabs[0].type);
  const messageRef = useRef();
  const noticeRef = useRef();
  const questionRef = useRef();
  const photoCaptionRef = useRef();
  const videoCaptionRef = useRef();
  const linkRef = useRef();
  // Define state for the poll creation form
  const [noticeList, setNoticeList] = useState([]);
  const [options, setOptions] = useState([]);
  const [userVotes, setUserVotes] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [startDate, setStartDate] = useState(
    new Date().toLocaleDateString("en-CA")
  );
  
  const [clans, setClans] = useState([
    {
      icon: "/assets/Immortals.png",
      clanName: "IMMORTALS",
      color: "#000000",
      score: 0,
      total: 0,
    },
    {
      icon: "/assets/Samurai.png",
      clanName: "SAMURAI",
      color: "#ff3838",
      score: 0,
      total: 0,

    },
    {
      icon: "/assets/Knights.png",
      clanName: "KNIGHTS",
      color: "#175572",
      score: 0,
      total: 0,

    },
    {
      icon: "/assets/Gladiators.png",
      clanName: "GLADIATORS",
      color: "#FDAE27",
      score: 0,
      total: 0,

    },
  ]);
  const [latestBuzz, setLatestBuzz] = useState([]);
 
  const deleteNotice = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this notice?"
    );
    if (confirmed) {
      try {
        const result = await dashboardService.deleteNotice({ id: id });
        handlePopUpMessage("Notice deleted successfully", "", 2000)
      } catch (error) {
        console.log(error);
      }
    }
    await getFeed()
  };
  const handleDeleteComment = async (comment) => {
    let feedData = allFeed[selectedFeed];

    let comments = feedData.comments;

    let index = comments.indexOf(comment);
    comments = comments.filter(
      (comment, index1) => comments.indexOf(comment) != index
    );
    feedData.comments = JSON.stringify(comments);

    await aloisFeedService.updateALOISFeed(feedData);
    feedData.comments = comments;

    handlePopUpMessage("Comment deleted successfully!", "", 2000);
    async function getFeed() {
      const newFeed1 = [...allFeed];

      // Update the element at the specified index
      newFeed1[selectedFeed] = feedData;
      // Update the state with the modified array
   
      setAllfeed(newFeed1)
    }

    await getFeed();
  };
  const handleDeleteFeed = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this feed?"
    );
      if(!confirmed){
        return
      }
    await aloisFeedService.deleteALOISFeed(id);


    handlePopUpMessage("Feed deleted successfully!", "", 2000);
   

    await getFeed();
  };
  const handleAddComment = async (index) => {
    if (document.getElementById(`comment-${index}`).value != "") {
      let feedData = allFeed[index];

      let comments = feedData.comments;
      let newComment = {
        userId: userData.id,
        username: userData.firstName + " " + userData.lastName,
        comment: document.getElementById(`comment-${index}`).value,
        createdDate: new Date(),
      };
      comments = [...comments, newComment];
      //  likedBy.push(user.id)
      let feedId = feedData.id;
      let newFeed = allFeed[index];
      newFeed.comments = JSON.stringify(comments);
      delete newFeed.__v
      
      await aloisFeedService.updateALOISFeed(newFeed);
      async function getFeed() {
        newFeed.comments = comments;

        const newFeed1 = [...allFeed];
        // Update the element at the specified index
        newFeed1[index] = newFeed;

        setAllfeed(newFeed1)
      }

      await getFeed();
      document.getElementById(`comment-${index}`).value = "";
      handlePopUpMessage("Commented successfully!", "", 2000);
    } else {
      handlePopUpMessage("", "Please type something to add a comment", 2000);
    }
  };
  const handleLikeDislike = async (index,id) => {
    let result = await aloisFeedService.getAllEvents();

    let feed1 = result.results.find(item => {return item._id == id})
    if (!feed1.likedBy.includes(userData.id)) {
      let likedBy = [...feed1.likedBy, userData.id];
      feed1.likedBy = JSON.stringify(likedBy);
    } else {
      let likedBy = feed1.likedBy.filter((like) => like != userData.id);
      feed1.likedBy = JSON.stringify(likedBy);
    }

    await aloisFeedService.updateALOISFeed(feed1);
    feed1.likedBy = JSON.parse(feed1.likedBy);
    async function getFeed() {
      const newFeed = [...allFeed];
      // Update the element at the specified index
      newFeed[index] = feed1;

      // Update the state with the modified array
     
      setAllfeed(newFeed)
    }

    await getFeed();
  };
  const handleOptionChange = async (newOption, index, id) => {
    let result = await aloisFeedService.getAllEvents();
    let item1 = result.results.find(item => {return item._id == id})
    
    const updatedPollOptions = item1.pollOptions.map((option) => {
      if (option.value === newOption.value) {
        // If the user has already selected this option, do not add their ID to the selectedBy array
        if (option.selectedBy && option.selectedBy.includes(userData.id)) {
          return option;
        }

        const updatedOption = { ...option };
        if (!updatedOption.selectedBy) {
          updatedOption.selectedBy = [];
        }
        updatedOption.selectedBy.push(userData.id);
        return updatedOption;
      } else {
        // Remove the user's ID from the selectedBy array of other options
        const updatedOption = { ...option };
        if (updatedOption.selectedBy) {
          updatedOption.selectedBy = updatedOption.selectedBy.filter(
            (id) => id !== userData.id
          );
        }
        return updatedOption;
      }
    });

    let updatedFeed = {
      ...item1,
      pollOptions: JSON.stringify(updatedPollOptions),
      id
    };
    let response = aloisFeedService.updateALOISFeed(updatedFeed);
    updatedFeed.pollOptions = updatedPollOptions;
    async function getFeed() {
      const newFeed = [...allFeed];
      // Update the element at the specified index
      newFeed[index] = updatedFeed;

      // Update the state with the modified array
      
      setAllfeed(newFeed)
    }

    await getFeed();
  };
  const [endDate, setEndDate] = useState(
    new Date().toLocaleDateString("en-CA")
  );
  const handleNoticeEndDateChange = (value) => {
    let tempDate = new Date().toLocaleDateString("en-CA");
    if (!value) {
      handlePopUpMessage("", "End date cannot be empty", 2000);
      return;
    }
    if (startDate > value) {
      handlePopUpMessage("", "End date cannot be before start date", 2000);
      return;
    } else if (value < tempDate) {
      handlePopUpMessage("", "End date cannot be a past date", 2000);
      return;
    } else {
    }
    if (startDate > value) {
      handlePopUpMessage("", "Start date cannot be after end date", 2000);
      return;
    } else {
    }
    setEndDate(value);
  };
  const handleNoticeStartDateChange = (value) => {
    let tempDate = new Date().toLocaleDateString("en-CA");
    if (!value) {
      handlePopUpMessage("", "Start date cannot be empty", 2000);
      return;
    }
    if (value < tempDate) {
      handlePopUpMessage("", "Start date can't be previous date", 2000);
      return;
    } else if (value > endDate) {
      handlePopUpMessage("", "Start date cannot be after end date", 2000);
      return;
    } else {
    }
    if (value > endDate) {
      handlePopUpMessage("", "End date cannot be before start date", 2000);
      return;
    } else {
    }
    setStartDate(value);
  };
  const [successNotific, setSuccessNotific] = useState("");
  const [errorNotific, setErrorNotific] = useState("");
  const [feed, setFeed] = useState([]);


  const handlePopUpMessage = (sucess, error, time) => {
    setShowAlert(true);
    setSuccessNotific(sucess);
    setErrorNotific(error);
    setTimeout(() => {
      setShowAlert(false);
    }, 2000);
  };
  const areAllElementsNotEmpty = (arr) => {
    arr.map((ele) => console.log(typeof ele.value));

    return arr.every(
      (element) =>
        (typeof element.value === "string" && element.value.trim() !== "") ||
        typeof element.value === "object"
    );
  };
  const handlePreviewTemplate = (data, index) => {
    setShowAlert(false);
    setShowPreviewPopup(true);
    
    setSelectedFeed(index);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("type", activeForm);
debugger
    if (activeForm == "photo") {
      if (imageInputRef.current.files?.length) {
        let response = await minioService.UploadSingleFile(
          imageInputRef.current.files[0],
          "feed/photo"
        );

        formData.append("url", response.data.url);
        if (activeForm == "photo" && photoCaptionRef.current.value) {
          formData.append("message", photoCaptionRef.current.value);
        }
      } else {
        handlePopUpMessage("", "Add image to continue.", 2000);
        return;
      }
    }
    if (activeForm == "video") {
      if (videoInputRef.current.files?.length) {
        let response = await minioService.UploadSingleFile(
          videoInputRef.current.files[0],
          "feed/video"
        );

        formData.append("url", response.data.url);
        if (activeForm == "video" && videoCaptionRef.current.value) {
          formData.append("message", videoCaptionRef.current.value);
        }
      } else {
        handlePopUpMessage("", "Add video file to continue.", 2000);
        return;
      }
    }
    if (activeForm == "message") {
      if (!messageRef.current.value.trim()) {
        handlePopUpMessage("", "Message can't be empty", 2000);
        return;
      }
      formData.append("message", messageRef.current.value);
    }
    if (activeForm == "poll") {
      let pollOptionInputs = document.querySelectorAll(".poll-option-input");
      let pollOptions = [];

      for (let input of pollOptionInputs) {
        if (!input.value) {
          handlePopUpMessage("", "Poll Option can't be empty", 2000);
          return;
        }
        pollOptions.push({ value: input.value, selectedBy: [] });
      }
      formData.append("pollOptions", JSON.stringify(pollOptions));

      if (!questionRef.current.value.trim()) {
        handlePopUpMessage("", "Poll Question can't be empty", 2000);
        return;
      }
      formData.append("message", questionRef.current.value);
    }
    if (activeForm == "notice") {
      if (!noticeRef.current.value.trim()) {
        handlePopUpMessage("", "Message for notice can't be empty", 2000);
        return;
      }
      handleNoticeStartDateChange(startDate);
      const setTimeToCurrent = (date) => {
        const now = new Date();
        date.setHours(now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds());
        return date;
    };
    let adjustedStartDate = new Date(startDate);
    const today = new Date().toLocaleDateString("en-CA");
    
    if (startDate === today) {
        adjustedStartDate = setTimeToCurrent(new Date(startDate));
    }
    const setTimeToEndOfDay = (date) => {
      date.setHours(24, 0, 0, 0);
      return date;
  };
  let adjustedEndDate = new Date(endDate);
  adjustedEndDate = setTimeToEndOfDay(new Date(endDate));
      handleNoticeEndDateChange(endDate);
      
      const result = await dashboardService.createNotice({
        noticeTitle: noticeRef.current.value,
        link: linkRef.current.value,
        startDate: adjustedStartDate,
        endDate: adjustedEndDate,
      });
      handlePopUpMessage("Notice created successfully!", "", 2000);

      // setShowAlert(true);
      // setSuccessNotific("Notice created successfully");
      // setErrorNotific("");
      // setTimeout(() => {
      //   setShowAlert(false);
      // }, 2000);
      setStartDate(new Date().toLocaleDateString("en-CA"));
      setEndDate(new Date().toLocaleDateString("en-CA"));
      linkRef.current.value = "";
      noticeRef.current.value = "";
     
    } else {
      let response = await aloisFeedService.createALOISFeed(formData);
      handlePopUpMessage("Feed created successfully!", "", 2000);


    }


    setOptions([]);
    imageInputRef.current.files = null;
    videoInputRef.current.files = null;

    const imageNameDiv = document.querySelector(".image-name");
    imageNameDiv.textContent = "No file selected";
    const videoNameDiv = document.querySelector(".video-name");
    videoNameDiv.textContent = "No file selected";
    photoCaptionRef.current.value = "";
    videoCaptionRef.current.value = "";
    questionRef.current.value = "";

    let pollOptionInputs = document.querySelectorAll(".poll-option-input");

    for (let input of pollOptionInputs) {
      input.value = "";
    }
    let date = new Date()
    let end = new Date()
    end.setDate(date.getDate() + 1)
    let result1 = await dashboardService.getLatestNoticeBoard(date.toLocaleDateString("en-CA"), end.toLocaleDateString("en-CA"));
    let result2 = await aloisFeedService.getAllEvents();
    result1 = result1.map(notice => {notice.createdAt = notice.startDate; return notice})


    setAllfeed([...result1, ...result2.results]);
  
    // setShowAlert(true);
    // setSuccessNotific("Feed created successfully");
    // setErrorNotific("");
    // setTimeout(() => {
    //   setShowAlert(false);
    // }, 2000);
    messageRef.current.value = "";
    noticeRef.current.value = "";
  };

  const deletePolls = () => {
    const pollOptions = document.querySelector(".poll-options");

    var childElements = pollOptions.querySelectorAll("*"); // Change 'div' to the desired child element type
    var lastChild = childElements[childElements.length - 1];
    pollOptions.removeChild(lastChild);

    setNoOfPollOptions(noOfPollOptions - 1);
  };
  const addPoll = () => {
    const pollOptions = document.querySelector(".poll-options");

    const newPollOption = document.createElement("input");
    newPollOption.type = "text";

    // Get the number of child elements

    newPollOption.placeholder = `Option ${noOfPollOptions + 1}`;
    setNoOfPollOptions(noOfPollOptions + 1);
    newPollOption.classList.add("poll-option-input");

    pollOptions.appendChild(newPollOption);
  };
  async function getFeed() {
    let date = new Date()
    let end = new Date()
    end.setDate(date.getDate() + 1)
    let result1 = await dashboardService.getLatestNoticeBoard(date.toLocaleDateString("en-CA"), end.toLocaleDateString("en-CA"));
  let result2 = await aloisFeedService.getAllEvents();
    result1 = result1.map(notice => {notice.createdAt = notice.startDate; return notice})

  setAllfeed([...result1, ...result2.results])

  }
  useEffect(() => {
    let user = localStorage.getItem("user");

    let validator = new licenceValidator(user, "home");
    setFeedVisible(validator.isReadable("alois_feed"));
    let vTabs = ["message", "images", "videos", "poll", "notice_board"]
    vTabs = vTabs.filter(t => validator.isReadable(t))
    
    setVisibleTabs(vTabs)
    getFeed();
   
  

    const monthToNumber = {
      January: 1,
      February: 2,
      March: 3,
      April: 4,
      May: 5,
      June: 6,
      July: 7,
      August: 8,
      September: 9,
      October: 10,
      November: 11,
      December: 12,
    };
    function getLatestScore(scoresArray) {
      // Initialize variables to hold the latest year and month
      let latestYear = 0;
      let latestMonthIndex = -1;
      
      // Iterate through the array to find the latest year and month combination
      scoresArray.forEach((score) => {
        const currentMonthIndex = monthToNumber[score.month];
        if (
          score.year > latestYear ||
          (score.year === latestYear && currentMonthIndex > latestMonthIndex)
        ) {
          latestYear = score.year;
          latestMonthIndex = currentMonthIndex;
        }
      });

      // Find the latest score object based on the latest year and month
      const latestScore = scoresArray.find(
        (score) =>
          score.year === latestYear &&
          monthToNumber[score.month] === latestMonthIndex
      );

      return latestScore;
    }
    function getPreviousMonthScore(scoresArray) {
      // Calculate previous month and year
      let latestYear = 0;
      let latestMonthIndex = -1;
      let totalScore = 0;
      // Iterate through the array to find the latest year and month combination
      scoresArray.forEach((score) => {
        const currentMonthIndex = monthToNumber[score.month];
        if (
          score.year > latestYear ||
          (score.year === latestYear && currentMonthIndex > latestMonthIndex)
        ) {
          latestYear = score.year;
          latestMonthIndex = currentMonthIndex;
        }
      });
      scoresArray.forEach((score) => {
        if (score.year === latestYear && monthToNumber[score.month] < latestMonthIndex) {
          totalScore += score.score;
        }
      });
    
      return totalScore;
    }
    async function getClanPositions(clans) {
      const result = {
        wentAhead: [],
        wentBehind: [],
        stayedSame: [],
      };
    
      const previousRankings = [];
      const currentRankings = [];
    
      for (const clan of clans) {
        let teamName = clan.clanName.toLowerCase();
        teamName = teamName.charAt(0).toUpperCase() + teamName.slice(1);
        const response = await clanMasterService.getClanPoints(teamName);
        
        const latestScoreObj = getTotalScoreOfCurrentYear(response.results);
      
        const previousScoreObj = getPreviousMonthScore(response.results);
    
        if (!previousScoreObj) {
          continue; // Skip if there is no previous score to compare with
        }
    
        currentRankings.push({ clanName: clan.clanName, score: latestScoreObj });
        previousRankings.push({ clanName: clan.clanName, score: previousScoreObj });
      }
    
      currentRankings.sort((a, b) => b.score - a.score);
      previousRankings.sort((a, b) => b.score - a.score);
    
      const currentRanks = {};
      const previousRanks = {};
    
      currentRankings.forEach((clan, index) => {
        currentRanks[clan.clanName] = index + 1;
      });
    
      previousRankings.forEach((clan, index) => {
        previousRanks[clan.clanName] = index + 1;
      });
    
      for (const clan of clans) {
        const previousRank = previousRanks[clan.clanName];
        const currentRank = currentRanks[clan.clanName];
    
        if (currentRank < previousRank) {
          result.wentAhead.push(clan.clanName);
        } else if (currentRank > previousRank) {
          result.wentBehind.push(clan.clanName);
        } else {
          result.stayedSame.push(clan.clanName);
        }
      }
    
      return result;
    }
    function getTotalScoreOfCurrentYear(scoresArray) {
      // Get the current year
      const currentYear = new Date().getFullYear();
      
      // Initialize variable to hold the total score
      let totalScore = 0;
    
      // Iterate through the array to sum up scores of the current year
      scoresArray.forEach((score) => {
        if (score.year === currentYear) {
          totalScore += score.score;
        }
      });
    
      return totalScore;
    }
    function getTotalScoreOfCurrentYearPreviousMonth(scoresArray) {
      // Get the current year
      const currentYear = new Date().getFullYear();
      
      // Initialize variable to hold the total score
      let totalScore = 0;
    
      // Iterate through the array to sum up scores of the current year
      scoresArray.forEach((score) => {
        if (score.year === currentYear) {
          totalScore += score.score;
        }
      });
    
      return totalScore;
    }
    let newClan = clans;
    async function getClanPoints(teamName) {
      teamName = teamName.toLowerCase()
      teamName = teamName.charAt(0).toUpperCase() + teamName.slice(1);
      const response = await clanMasterService.getClanPoints(teamName);
      const latestScore = getLatestScore(response.results);
      const total = getTotalScoreOfCurrentYear(response.results);
      // Find the clan object with the matching teamName
      const clanIndex = clans.findIndex((clan) => {let teamName1 = clan.clanName.toLowerCase();
        return teamName1.charAt(0).toUpperCase() + teamName1.slice(1)  === teamName});

      if (clanIndex !== -1) {
        // If the clan exists in the array
        // Update the clan object with the latest score
        newClan[clanIndex].score = latestScore.score;
        newClan[clanIndex].total = total;

      } else {
        console.log(`Clan '${teamName}' not found in the clans array.`);
      }
    }

    // Iterate through the clans array and call getClanPoints for each clan
    async function updateClansWithLatestScores() {
      const promises = clans.map(clan => getClanPoints(clan.clanName));
      await Promise.all(promises);
      setClans(newClan);
      const positions = await getClanPositions(clans);
    setClanPositions(positions)

    
      const highestScore1 = Math.max(...newClan.map(item => item.total));
      setHighestScore(highestScore1)
    }

    // Call the function to update clans with latest scores
    updateClansWithLatestScores();
    
    
   async function getBirthDayAndAnniversaryUsers(){
    let response = await userRegistrationService.getUsers();
    
    let users = response.results
    const currentDate = new Date();
const currentMonth = currentDate.getMonth() + 1; // Month is zero-based, so add 1 to get the actual month
const currentYear = currentDate.getFullYear();
const currentDay = currentDate.getDate();

// Filter users whose date of birth is in the current month
const usersThisMonth = users.filter(user => {
    // Split the dateOfBirth string into day, month, and year parts
try {
  let dateOfBirth = new Date(user.dateOfBirth)

    // Check if the month and year match the current month and year
    return Number(dateOfBirth.getMonth() + 1) === currentMonth && Number(dateOfBirth.getFullYear()) < currentYear && Number(dateOfBirth.getDate()) > currentDay ;
} catch (err) {
  console.log(err, user.dateOfBirth)
}
});
setBirthdayUsers(usersThisMonth)

const usersAnniversaryThisMonth = users.filter(user => {
  // Split the dateOfBirth string into day, month, and year parts
try {
  let dateOfJoining = new Date(user.dateOfJoining)

    // Check if the month and year match the current month and year
    return Number(dateOfJoining.getMonth() + 1) === currentMonth && Number(dateOfJoining.getFullYear()) < currentYear && Number(dateOfJoining.getDate()) > currentDay ;
} catch (err) {
console.log(err, user.dateOfJoining)
}
});

let anniversaryUsers1 = usersAnniversaryThisMonth.map((user)=>{
  try {
    let dateOfJoining = new Date(user.dateOfJoining)
  // Check if the month and year match the current month and year
  user.years = currentYear - dateOfJoining.getFullYear();
  return user
} catch (err) {
console.log(err, user.dateOfJoining)
}
})

setAnniversaryUsers(anniversaryUsers1)
// usersThisMonth contains the users whose date of birth is in the current month
   }
   getBirthDayAndAnniversaryUsers();
   getLatestBuzz()
  }, []);
  useEffect(()=> {
   
    if(visibleTabs.includes("videos") && feedVisible){
      const videoInput = document.getElementById("video-input");
      const videoNameDiv = document.querySelector(".video-name");
      videoNameDiv.textContent = "No file selected";
      videoInput.addEventListener("change", () => {
        const selectedFile = videoInput.files[0];
        videoNameDiv.textContent = selectedFile.name;
      });
     }
     if(visibleTabs.includes("images") && feedVisible){
      const imageInput = document.getElementById("image-input");
  
      
      const imageNameDiv = document.querySelector(".image-name");
      imageNameDiv.textContent = "No file selected";
      imageInput.addEventListener("change", () => {
        const selectedFile = imageInput.files[0];
        imageNameDiv.textContent = selectedFile.name;
      });
     }
  },[visibleTabs])
  const tabBtns = document.querySelectorAll(".tab-btn");

useEffect(()=>{
  if(feedVisible && tabBtns.length){
    const tabContents = document.querySelectorAll(".tab-content");
    const tabBtnIcons = document.querySelectorAll(".tab-btn .icon");

  
    tabBtns.forEach((btn, index) => {
      btn.addEventListener("click", () => {
        // Remove active class from all buttons and contents
        tabBtns.forEach((b) => b.classList.remove("active"));
        tabContents.forEach((c) => c.classList.remove("active"));
        tabBtnIcons.forEach((i) => i.classList.remove("active"));

        // Add active class to the clicked button, corresponding content, and icon
        btn.classList.add("active");
        tabContents[index].classList.add("active");
        tabBtnIcons[index].classList.add("active");
      });
    });
  }
},[tabBtns])
  let userData = JSON.parse(localStorage.user);

  const getLatestBuzz = async () => {
    try {
      const data = await buzzService.getLatestBuzz();
      
      if (data.length > 2)
        setLatestBuzz([data[0], data[1], data[2]]);
      if (data.length === 2)
        setLatestBuzz([data[0], data[1]]);
      if (data.length === 1) setLatestBuzz([data[0]]);
      
    } catch (error) {}
  };
  const handleSideBarChange = () =>{
    dispatch(setActivePanel("user"));
    localStorage.setItem("activePanel","user");
  }
  return (
    <div className="dashboard-container-outer" style={{ marginTop: "0rem" }}>
      {showAlert ? (
        <div className="alert-container">
          <div className="upper-section">
            <p style={{ marginBottom: "0px", marginTop: "5px" }}>ALOIS</p>
            <span
              className="delete-btn"
              onClick={() => setShowAlert(!showAlert)}
            >
              <AiOutlineClose className="icon" style={{ fill: "black" }} />
            </span>
          </div>
          <hr
            style={{
              display: "block !important",
              height: "1px !important",
              border: "0 !important",
              borderTop: "1px solid gray !important",
            }}
          />
          <div className="lower-section pt-2">
            <p className="text-danger">{errorNotific}</p>
            <p className="text-success">{successNotific}</p>
          </div>
        </div>
      ) : null}
 {showPreviewPopup && (
        <div className="preview-popup-container">
          <div className="popup">
            <div className="popup-header">
              <div>Template Preview</div>

              <div>
             

                <button
                  className="close-button"
                  onClick={() => {setShowPreviewPopup(false); setSelectedFeed(null)}}
                >
                  &#10005;
                </button>
              </div>
            </div>

            <hr className="hr-border" />

            <div className="popup-content preview-icons d-flex justify-content-center">
            

              <img
                src={allFeed[selectedFeed].url}
                alt={`template_${allFeed[selectedFeed].id}`}
              />

         
            </div>
          </div>
        </div>
      )}
      {selectedFeed != null && !showPreviewPopup ? (
        <div className="comment-popup-container">
          <div className="popup">
            <div className="popup-header">
              Comments
              <button
                className="close-button"
                onClick={() => setSelectedFeed(null)}
              >
                &#10005;
              </button>
            </div>
            <div className="popup-content preview-icons">
              <div className="d-flex justify-content-center align-items-center gap-5 top-container">
                <div className="comments">
                  {allFeed[selectedFeed].comments?.map((comment, index) => (
                    <div
                      className="comment d-flex align-items-center"
                      key={index}
                    >
                      <img
                        src="/assets/profile.jpg"
                        alt="Profile"
                        className="mx-2 me-3"
                        height="15"
                      />
                      <div style={{ width: "90%" }}>
                        <p
                          className="p-0 m-0 "
                          style={{ fontWeight: "500", fontSize: "20px" }}
                        >
                          {comment.username}
                        </p>
                        <p className="p-0 m-0 " style={{ fontSize: "15px" }}>
                          {comment.comment}
                        </p>
                      </div>
                      {comment.userId == userData.id ? (
                        <FaTrashAlt
                          onClick={() => {
                            handleDeleteComment(comment);
                          }}
                          className="cursor-pointer "
                          style={{width:"20px", height:"20px"}}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="dashboard-inner">
        <div className="alois-feed">
          
          <div className="feed">
          <div className="clans">
    {clans.sort((a, b) => b.total - a.total).map((item) => (
      <div key={item.clanName} className="clan-card p-3 bg-white mb-2">
        <div className="d-flex align-items-center flex-column">
        <p
              className="mb-0"
              style={{
                fontSize: "30px",
                color: item.color,
                fontWeight: "550",
              }}
            >
              {item.clanName}
            </p>
        <div className="d-flex align-items-center">
          <img
            src={item.icon}
            height="70px"
            width="70px"
            style={{ borderRadius: "10px" }}
          />
          <div className="clan-total p-1">
           
            <div className="d-flex justify-content-around w-100 gap-2">
              <p className="mb-0">Latest :</p>
              <p className="mb-0" style={{ fontSize: "18px", color: "green" }}>
                {item.score}
              </p>
            </div>
            <div className="d-flex justify-content-around w-100 gap-2">
              <p className="mb-0">Total :</p>
              <p className="mb-0" style={{ fontSize: "18px", color: "green" }}>
                {item.total}{" "}
                {clanPositions?.wentAhead?.includes(item.clanName) ? (
                  <FaArrowUp style={{ marginBottom: "5px" }} />
                ) : clanPositions?.wentBehind?.includes(item.clanName) ? (
                  <FaArrowDown style={{ marginBottom: "5px", color: "red" }} />
                ) : (
                  <></>
                )}
              </p>
            </div>
          </div>
        </div>
        </div>
      </div>
    ))}
  </div>
       {feedVisible  &&   <div className="feed-container">
              <div className="feed-header">
                <div className="tab-buttons">
                {visibleTabs.includes("message") &&  <button
                    onClick={() => setActiveForm("message")}
                    className={visibleTabs.indexOf("message") == 0 ?`active tab-btn` :"tab-btn"}
                  >
                    <PiChatsBold className={visibleTabs.indexOf("message") == 0 ?`active icon` :"icon"} />
                  </button>}
                  {visibleTabs.includes("images") && <button
                    onClick={() => setActiveForm("photo")}
                    className={visibleTabs.indexOf("images") == 0 ?`active tab-btn` :"tab-btn"}
                  >
                    <AiOutlinePicture className={visibleTabs.indexOf("images") == 0 ?`active icon` :"icon"} />
                  </button>}
                 {visibleTabs.includes("videos") &&  <button
                    onClick={() => setActiveForm("video")}
                    className={visibleTabs.indexOf("videos") == 0 ?`active tab-btn` :"tab-btn"}

                  >
                    <MdVideoLibrary className={visibleTabs.indexOf("videos") == 0 ?`active icon` :"icon"} />
                  </button>}
                  {visibleTabs.includes("poll") &&   <button
                    onClick={() => setActiveForm("poll")}
                    className={visibleTabs.indexOf("poll") == 0 ?`active tab-btn` :"tab-btn"}

                  >
                    <RiBarChart2Fill className={visibleTabs.indexOf("poll") == 0 ?`active icon` :"icon"} />
                  </button>}
                  {visibleTabs.includes("notice_board") &&   <button
                    onClick={() => setActiveForm("notice")}
                    className={visibleTabs.indexOf("notice_board") == 0 ?`active tab-btn` :"tab-btn"}

                  >
                    <CgNotes className={visibleTabs.indexOf("notice_board") == 0 ?`active icon` :"icon"} />
                  </button>}
                </div>
              </div>
              <div className="feed-content">
              {visibleTabs.includes("message") &&     <div className={visibleTabs.indexOf("message") == 0 ?`active tab-content` :"tab-content"}>
                  <div className="input-container">
                    <input
                      type="text"
                      placeholder="What's on your mind?"
                      className="input-field"
                      ref={messageRef}
                    />

                    <button className="submit-btn">
                      <IoIosPaperPlane
                        onClick={handleSubmit}
                        className="icon"
                      />
                    </button>
                  </div>
                </div>}
               {visibleTabs.includes("images") &&  <div className={visibleTabs.indexOf("images") == 0 ?`active tab-content` :"tab-content"}>
                  <div className="image-container">
                    <label for="image-input" className="file-label">
                      <i className="fas fa-upload"></i>
                      Select a file
                    </label>
                    <input
                      type="file"
                      ref={imageInputRef}
                      id="image-input"
                      className="image-input"
                      accept="image/*"
                    />
                    <div className="image-name ms-2"></div>
                  </div>
                  <div className="input-container">
                    <input
                      type="text"
                      placeholder="What's on your mind?"
                      ref={photoCaptionRef}
                      className="input-field"
                    />

                    <button className="submit-btn">
                      <IoIosPaperPlane
                        onClick={handleSubmit}
                        className="icon"
                      />
                    </button>
                  </div>
                </div>}
                {visibleTabs.includes("videos") &&    <div className={visibleTabs.indexOf("videos") == 0 ?`active tab-content` :"tab-content"}>
                  <div className="image-container">
                    <label for="video-input" className="file-label">
                      Select a video
                    </label>
                    <input
                      type="file"
                      id="video-input"
                      ref={videoInputRef}
                      className="video-input"
                      accept=".mp4"
                    />
                    <div className="video-name ms-2"></div>
                  </div>
                  <div className="input-container">
                    <input
                      type="text"
                      placeholder="What's on your mind?"
                      className="input-field"
                      ref={videoCaptionRef}
                    />
                    <button className="submit-btn">
                      <IoIosPaperPlane
                        onClick={handleSubmit}
                        className="icon"
                      />
                    </button>
                  </div>
                </div>}
                {visibleTabs.includes("poll") &&   <div className={visibleTabs.indexOf("poll") == 0 ?`active tab-content` :"tab-content"}>
                  <input
                    type="text"
                    placeholder="Poll Question"
                    ref={questionRef}
                    className="poll-question-input"
                  />
                  <div className="poll-options d-flex">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        placeholder="Option 1"
                        className="poll-option-input"
                      />
                      <img
                        src={Plus}
                        className="add-poll-option"
                        onClick={addPoll}
                      />
                      {noOfPollOptions > 2 ? (
                        <img
                          src={Trash}
                          onClick={deletePolls}
                          className="delete-poll-option"
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                    <input
                      type="text"
                      placeholder="Option 2"
                      className="poll-option-input"
                    />
                  </div>
                  <div className="w-100 d-flex justify-content-center">
                    <GradientBorderButton
                      text="Post"
                      clickHandler={handleSubmit}
                    />
                  </div>
                </div>}
                {visibleTabs.includes("notice_board") &&  <div className={visibleTabs.indexOf("notice_board") == 0 ?`active tab-content` :"tab-content"}>
                  <input
                    type="text"
                    placeholder="Link (Optional)"
                    ref={linkRef}
                    style={{ height: "45px", borderRadius: "10px" }}
                    className="link w-100"
                  />
                  <div className="row mt-3 pb-3">
                    <div className="col">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="date">
                          Start date*
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          name="date"
                          id="date"
                          value={startDate}
                          onChange={(e) =>
                            handleNoticeStartDateChange(e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="date">
                          End date*
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          name="date"
                          id="date"
                          value={endDate}
                          onChange={(e) =>
                            handleNoticeEndDateChange(e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-container">
                    <input
                      type="text"
                      placeholder="What's on your mind?"
                      className="input-field"
                      ref={noticeRef}
                    />

                    {/* <button className="submit-btn">
                      <IoIosPaperPlane onClick={handleSubmit}  className="icon" />
                    </button> */}
                  </div>
                  <div className="w-100 d-flex justify-content-center mt-2">
                    <GradientBorderButton
                      text="Post"
                      clickHandler={handleSubmit}
                    />
                  </div>
                </div>}
              </div>
            </div>}
            <div className="d-flex gap-2 mt-2 dashboard-upper-section justify-content-between flex-column">
              <div className="dashboard-response-header gap-3">
                {tabs.map((tab) => {
                  return (
                    <div
                      onClick={() => {
                        setActiveTab(tab.type);
                      }}
                      className={`icon-tab ${
                        activeTab == tab?.type ? "active" : ""
                      }`}
                    >
                      {activeTab == tab.type
                        ? tab.image
                          ? tab.image
                          : tab.icon
                        : tab.icon}
                    </div>
                  );
                })}
              </div>
              <div className="dashboard-response">
                {activeTab == "clan" ? (
                  clans.map((item) => {
                    let color = item.color;
                    return (
                      <div className="dashboard-card shadow p-3 bg-white mb-3">
                        <div className="d-flex">
                          <img
                            src={item.icon}
                            height="50px"
                            width="50px"
                            style={{ borderRadius: "10px" }}
                          />
                          <p
                            className="mb-0 ms-3"
                            style={{
                              fontSize: "20px",
                              color,
                              fontWeight: "550",
                            }}
                          >
                            {item.clanName}
                          </p>
                          <p
                            className="mb-0 ms-3"
                            style={{ fontSize: "15px", color: "gray" }}
                          >
                            {item.score} points added to  {item.clanName}
                          </p>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <></>
                )}
            
            {allFeed.sort((a, b) => {
    return new Date(b.createdAt) - new Date(a.createdAt)
}).map((item, index) => {
    const dateString = item.createdAt;
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}`;

    // Check if the active tab matches the feed type or if it's the "feed" tab
    if ((activeTab == "feed" || activeTab == item.type) && item.type) {
        // Check if the feed type is "birthday"
        if (item.type === "birthday" || item.type === "anniversary") {
            const today = new Date();
            const publishDate = new Date(item.publishDate);
            
            // Check if today's date matches the publish date
            if (
                today.getDate() === publishDate.getDate() &&
                today.getMonth() === publishDate.getMonth() &&
                today.getFullYear() === publishDate.getFullYear()
            ) {
                // Render the birthday card if it's the publish date
                return (
                    <div className="dashboard-card shadow p-3 bg-white mb-3">
                        <div className="d-flex align-items-center">
                              <img className="menu-img online" src={`/assets/profile.jpg`} alt="Profile" height="50" />
                            <div className="d-block">
                                <p className="text">Assist</p>
                                <p className="date" style={{ fontSize: "13px" }}>
                                    {formattedDate}
                                </p>
                            </div>
                            {feedVisible ?      <FaTrashAlt  onClick={()=>{handleDeleteFeed(item._id)}} className="cursor-pointer"/> :<></>}
                        </div>
                        <div>
                            <div className="post-image-container">
                                <img src={item.url} onClick={() => { handlePreviewTemplate(item, index) }} />
                            </div>
                            <p className="message">{item.message}</p>
                        </div>
                        <Row className="d-flex justify-content-start align-items-start interactions gap-3 ms-1">
                            <Col xl="1" className="d-flex element" style={{ width: "fit-content" }}>
                                {item.likedBy.includes(userData.id) ? (
                                    <button
                                        onClick={() => {
                                            handleLikeDislike(index, item._id);
                                        }}
                                        className="active"
                                        style={{ color: "navy" }}
                                    >
                                        <FaThumbsUp className="active" />
                                    </button>
                                ) : (
                                    <button
                                        className=""
                                        onClick={() => {
                                            handleLikeDislike(index, item._id);
                                        }}
                                    >
                                        <FaRegThumbsUp className="icon" />
                                    </button>
                                )}
                                <p className="likes"> {item.likedBy.length}</p>
                            </Col>
                            <Col xl="1" className="d-flex element" style={{ width: "fit-content" }}>
                                <button
                                    style={{ marginBottom: "-4px" }}
                                    onClick={() => {
                                        setSelectedFeed(index);
                                    }}
                                >
                                    <LuMessageSquare className="icon" />
                                </button>
                                <p className="likes"> {item.comments.length}</p>
                            </Col>
                        </Row>
                        <div className="comment-input-container w-100">
                            <input
                                type="text"
                                placeholder="Write your comment.."
                                className="input-field"
                                id={`comment-${index}`}
                            />
                            <button
                                className="submit-btn"
                                onClick={() => {
                                    handleAddComment(index);
                                }}
                            >
                                <IoIosPaperPlane className="icon" />
                            </button>
                        </div>
                    </div>
                );
            } else {
                // Hide the birthday card if it's not the publish date
                return null;
            }
        } else {
            // Render other feed types (photo, video, poll)
            return (
              <div className="dashboard-card shadow p-3 bg-white mb-3">
              <div className="d-flex align-items-center">
              <img className="menu-img online" src={`/assets/profile.jpg`} alt="Profile" height="50" />
                  <div className="d-block">
                      <p className="text">Assist</p>
                      <p className="date" style={{ fontSize: "13px" }}>
                          {formattedDate}
                      </p>
                  </div>
                  {feedVisible ?        <FaTrashAlt className="cursor-pointer" onClick={()=>{handleDeleteFeed(item._id)}} height="20px" width="20px" />:<></>}


              </div>
              <div>
             {item.type === "photo" &&
 (<div className="post-image-container">
 <img src={item.url} onClick={() => { handlePreviewTemplate(item, index) }} />
</div>)
              }
                    {item.type == "video" && (
                            <div className="post-image-container">
                              <video
                                src={item.url}
                                width="750"
                                height="500"
                                onContextMenu={(event) => event.preventDefault()}
                                disablePictureInPicture
                                controls controlslist="nodownload noplaybackrate"
                              ></video>
                            </div>
                          )}
                  <p className="message">{item.message}</p>
                  {item.type == "poll" ? (
                            <div className="voting-options">
                              {item.pollOptions.map((option) => {
                                const totalVotes = item.pollOptions.reduce(
                                  (total, opt) =>
                                    total +
                                    (opt.selectedBy
                                      ? opt.selectedBy.length
                                      : 0),
                                  0
                                );
                                const optionPercentage =
                                totalVotes > 0
                                    ? ((option.selectedBy ? option.selectedBy.length : 0) / totalVotes) * 100
                                    : 0;
                            
                            const roundedPercentage = optionPercentage.toFixed(2);

                                return (
                                  <div
                                    key={option.value + "-option"}
                                    className="voting-option"
                                  >
                                    <input
                                      type="radio"
                                      id={option.value}
                                    
                                      name={`poll-vote-${item._id}`}
                                      value={option.value}
                                      checked={option.selectedBy.includes(userData.id)}
                                      
                                      onChange={() =>
                                        handleOptionChange(option, index, item._id)
                                      }
                                    />
                                    <label htmlFor={option.value}>
                                      <p style={{ width: "fit-content" }}>
                                        {option.value}
                                      </p>
                                      <div className="d-flex">
                                        <div className="poll-bar">
                                          <div
                                            className="poll-progress"
                                            style={{
                                              width: `${
                                                roundedPercentage + "%"
                                              }`,
                                            }}
                                          ></div>
                                        </div>
                                        <span className="poll-percentage">
                                          {roundedPercentage + "%"}
                                        </span>
                                      </div>
                                    </label>
                                  </div>
                                );
                              })}
                            </div>
                          ) : (
                            <></>
                          )}
              </div>
              <Row className="d-flex justify-content-start align-items-start interactions gap-3 ms-1">
                  <Col xl="1" className="d-flex element" style={{ width: "fit-content" }}>
                      {item.likedBy.includes(userData.id) ? (
                          <button
                              onClick={() => {
                                  handleLikeDislike(index, item._id);
                              }}
                              className="active"
                              style={{ color: "navy" }}
                          >
                              <FaThumbsUp className="active" />
                          </button>
                      ) : (
                          <button
                              className=""
                              onClick={() => {
                                  handleLikeDislike(index, item._id);
                              }}
                          >
                              <FaRegThumbsUp className="icon" />
                          </button>
                      )}
                      <p className="likes"> {item.likedBy.length}</p>
                  </Col>
                  <Col xl="1" className="d-flex element" style={{ width: "fit-content" }}>
                      <button
                          style={{ marginBottom: "-4px" }}
                          onClick={() => {
                              setSelectedFeed(index);
                          }}
                      >
                          <LuMessageSquare className="icon" />
                      </button>
                      <p className="likes"> {item.comments.length}</p>
                  </Col>
              </Row>
              <div className="comment-input-container w-100">
                  <input
                      type="text"
                      placeholder="Write your comment.."
                      className="input-field"
                      id={`comment-${index}`}
                  />
                  <button
                      className="submit-btn"
                      onClick={() => {
                          handleAddComment(index);
                      }}
                  >
                      <IoIosPaperPlane className="icon" />
                  </button>
              </div>
          </div>
            );
        }
    } else if ((activeTab == "feed" || activeTab == "notice") && !item.type ) {
        // Render notice feed
        if (item.status) {
            if (item.link.length > 2) {
                return (
                    <div className="dashboard-card shadow p-3 bg-white mb-3">
                        <div className="d-flex align-items-center">
                              <img className="menu-img online" src={`/assets/profile.jpg`} alt="Profile" height="50" />
                            <div className="d-block">
                                <p className="text">HR</p>
                                <p className="date" style={{ fontSize: "13px" }}>
                                    {formattedDate}
                                </p>
                            </div>
                            {feedVisible ?  <FaTrashAlt className="cursor-pointer" onClick={()=>{deleteNotice(item.id)}} height="20px" width="20px" /> :<></>}

                        </div>
                        <div>
                            <p className="message">
                                <NavLink
                                    to={"https://" + item.link}
                                    target="_blank"
                                    className="d-flex notice-board-link"
                                    key={index}
                                    style={{
                                        color: "black",
                                        textDecoration: "none",
                                    }}
                                >
                                    {item.noticeTitle}
                                </NavLink>
                            </p>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className="dashboard-card shadow p-3 bg-white mb-3">
                        <div className="d-flex align-items-center">
                              <img className="menu-img online" src={`/assets/profile.jpg`} alt="Profile" height="50" />
                            <div className="d-block">
                                <p className="text">HR</p>
                                <p className="date" style={{ fontSize: "13px" }}>
                                    {formattedDate}
                                </p>
                            </div>
                          {feedVisible ?  <FaTrashAlt className="cursor-pointer" onClick={()=>{deleteNotice(item.id)}} height="20px" width="20px" /> :<></>}

                        </div>
                        <div>
                            <p> {item.noticeTitle} </p>
                        </div>
                    </div>
                );
            }
        } else {
            return null;
        }
    }
})}
              </div>
            </div>
          </div>
          <div className="upcoming-sidebar gap-3">
            <div className="upcoming-birthday">
              <div className="header d-flex align-items-center justify-content-between">
              <p className="ms-1">
                  Upcoming Birthdays 
                

                </p>
                <FaBirthdayCake style={{ height:"30px", width:"30px" }} className="me-1" />
                {/* <span>
                  <IoIosArrowForward />
                </span> */}
              </div>
              <div className="list align-items-center">
              {birthdayUsers.length ? birthdayUsers
  .sort((a, b) => new Date(a.dateOfBirth).getDate() - new Date(b.dateOfBirth).getDate())
  .map((user) => {
    let dateOfBirth = new Date(user.dateOfBirth);
    let month = dateOfBirth.getMonth() + 1;
    let day = dateOfBirth.getDate();
    const monthMap = {
      1: 'Jan',
      2: 'Feb',
      3: 'Mar',
      4: 'Apr',
      5: 'May',
      6: 'Jun',
      7: 'Jul',
      8: 'Aug',
      9: 'Sep',
      10: 'Oct',
      11: 'Nov',
      12: 'Dec'
    };
    return (
      <div className="birthday-card" key={user.id}>
        <img 
          className="photo" 
          src={user.profilePic ? user.profileImage : `/assets/profile.jpg`} 
          alt="Profile" 
          height="50" 
        />
        <div className="line"></div>
        <div className="name">{user.firstName + " " + user.lastName}</div>
        <div className="line"></div>
        <div className="date">{monthMap[Number(month)] + "' " + day}</div>
      </div>
    );
  })
  : <p style={{marginTop: "40%", textAlign: "center"}}>No birthdays in the next 30 days</p>
}

                
               
              </div>
            </div>
            <div className="upcoming-birthday my-3">
              <div className="header py-2 d-flex align-items-center justify-content-between" style={{minHeight:"fit-content"}}>
                <p className="ms-1">
                  Upcoming Anniversaries{" "}
                 
                </p>
                <img src={WorkAnniversaryWhiteIcon}  height="30px" width="30px" className="me-1" />
                {/* <span>
                  <IoIosArrowForward />
                </span> */}
              </div>
              <div className="list">
              {anniversaryUsers.length ? anniversaryUsers
  .sort((a, b) => new Date(a.dateOfBirth).getDate() - new Date(b.dateOfBirth).getDate()).map((user)=>{
                  if(user.dateOfJoining){
                    let dateOfJoining = new Date(user.dateOfJoining)
                    let month = dateOfJoining.getMonth() + 1
                    let day = dateOfJoining.getDate() 
                    
                    const monthMap = {
                      1: 'Jan',
                      2: 'Feb',
                      3: 'Mar',
                      4: 'Apr',
                      5: 'May',
                      6: 'June',
                      7: 'Jul',
                      8: 'Aug',
                      9: 'Sep',
                      10: 'Oct',
                      11: 'Nov',
                      12: 'Dec'
                  };
                  return (
                    <div className="birthday-card" style={{height:"fit-content"}}>
                    <img className="photo" src={user.profilePic ? user.profileImage:`/assets/profile.jpg`} alt="Profile" height="50" />

                    <div className="line"></div>
                    <div className="name" style={{width:"45%"}}>{user.firstName + " " + user.lastName}

                    <div className="years">({user.years} year)</div></div>
                    <div className="line"></div>
                    <div className="date">{monthMap[Number(month)]+"' "  + day}</div>

  
  
                  </div>
                  )
                  } else {
                    
                  }
                   
                }): <p style={{marginTop:"40%", textAlign:"center"}}>No anniversaries in the next 30 days</p>}
              
          
              </div>
            </div>
            <div className="upcoming-birthday">
              <div className="header">
                <p>Buzz</p>
                <span className="d-flex align-items-center">
                  <IoIosArrowForward style={{ height:"30px", width:"30px" }} />
                </span>
              </div>
              <div className="d-flex flex-column mt-2 px-1 ">
          {latestBuzz.length > 0
            ? latestBuzz.map((item) => {
                if (item) {
                  return (
                    <NavLink
                      to={"/buzz"}
                      className="latest-buzz-card-container my-2"
                      onClick={handleSideBarChange}
                    >
                      {item?.featureImage ? (
                        <img
                          src={item.featureImage}
                          alt="buzz-image"
                          className="buzz-image"
                        />
                      ) : (
                        <></>
                      )}
                      <div className="d-flex flex-column justify-content-center w-50 text-center">
                        <p
                          className="buzz-text pe-2"
                          style={{ textTransform: "capitalize" }}
                        >
                          {item.month}
                        </p>
                        <p
                          className="buzz-text pe-2"
                          style={{ textTransform: "capitalize" }}
                        >
                          {item.year}
                        </p>
                      </div>
                    </NavLink>
                  );
                } else {
                  return <></>;
                }
              })
            : null}
          {/* <BuzzCard /> */}
          {/* <BuzzCard /> */}
        </div>
            </div>
          </div>
        </div>
      </div>

  
    </div>
  );
}
export default Dashboard;
