import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import store from "./redux/store";
import { Provider } from "react-redux";
import { BrowserRouter, useRoutes, useLocation } from "react-router-dom";
// import App from './app';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/js/bootstrap.bundle";
import "./scss/styles.scss";
import Approute from "./config/app-route.jsx";
import licenceValidator from "./permission/validate";

function App() {
  let user = localStorage.getItem("user");

  let newAppRoute = [{}];
  newAppRoute[0]["element"] = Approute[0]["element"];
  newAppRoute[0]["path"] = Approute[0]["path"];
  if (user) {
    newAppRoute[0]["children"] = Approute[0].children.map((route) => {
      if (route.children) {
        let newRoutes = {};
        newRoutes["path"] = route.path;
        newRoutes["element"] = route.element;
        newRoutes["children"] = [];
        for (let child of route?.children) {
          if (route.type == "Private") {
            let validator = new licenceValidator(user, route.titleSlug);

            try {
              try {
                if (child.isInSideBar) {
                  if (validator.isAccessible()) {
                    newRoutes["children"].push(child);
                  }
                } else {
                  let childValidator = new licenceValidator(
                    user,
                    child.titleSlug
                  );

                  if (
                    validator.isVisible(child.titleSlug) ||
                    child.type == "Public" ||
                    childValidator.isAccessible()
                  ) {
                    newRoutes["children"].push(child);
                  } else if (!validator.mainModule) {
                    // newRoutes["children"].push(child)
                  }
                }
              } catch {}
            } catch (err) {}
          } else if (route.type == "Partial") {
            let validator = new licenceValidator(user, route.titleSlug);

            try {
              if (child.isInSideBar) {
                let childValidator = new licenceValidator(
                  user,
                  child.titleSlug
                );

                if (
                  validator.isVisible(child.titleSlug) ||
                  child.type == "Public" ||
                  childValidator.isAccessible()
                ) {
                  newRoutes["children"].push(child);
                }
              } else {
                if (
                  validator.isVisible(child.titleSlug) ||
                  child.type == "Public"
                ) {
                  newRoutes["children"].push(child);
                }
              }
            } catch {}
          } else if (route.type == "Public") {
            newRoutes["children"].push(child);
          }
        }

        return newRoutes;
      } else {
        return route;
      }
    });
  }

  let element = useRoutes(newAppRoute);

  let location = useLocation();
  React.useEffect(() => {
    var elm = document.querySelector(".app");
  }, [location]);

  // For force SSL, uncomment when pushing to production branch.
  // useEffect(() => {
  //   if (window.location.protocol === "http:") {
  //     window.location.replace(
  //       `http://${window.location.host}${window.location.pathname}`
  //     );
  //   }
  // }, []);

  return element;
}
ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("app")
);
